import { MIN_SEARCH_LENGTH } from 'helpers/constants/search';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import { debounce, preventEmptySpaceSearch } from 'utils/utils';

const Search: React.FC<{
  onSearch: (value: string) => void;
  searchQuery: string;
  className?: string;
}> = ({ onSearch, searchQuery, className }) => {
  const { register } = useForm<{ searchValue: string }>({
    defaultValues: {
      searchValue: searchQuery,
    },
  });

  const debouncedOnSearch = debounce(onSearch, 700);

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    if (value.length > MIN_SEARCH_LENGTH || value.length === 0) {
      debouncedOnSearch(event.target.value);
    }
  };

  return (
    <div
      className={`flex items-center border rounded-md w-full md:w-3/4 lg:w-1/2 shadow-sm mb-4 bg-gray-100 ${className}`}
    >
      <input
        type="search"
        {...register('searchValue')}
        onChange={handleSearchValueChange}
        placeholder="Type at least 3 letters to search"
        className="flex-1 py-2 px-3 border-none rounded-l-md focus:outline-blue-congress placeholder:text-sm"
        onKeyDown={preventEmptySpaceSearch}
      />
      <div className="flex items-center justify-center w-10">
        <FaSearch />
      </div>
    </div>
  );
};

export default memo(Search);
