import React from 'react';
import { cn } from 'utils/utils';

const GradientButton: React.FC<GradientButtonProps> = ({
  type = 'button',
  buttonText,
  className,
  isLoading,
  title,
}) => {
  return (
    <button
      type={type}
      className={cn(className, 'bg-primary-gradient', {
        'opacity-70 cursor-not-allowed': isLoading,
      })}
      disabled={isLoading}
      title={title}
    >
      {buttonText}
      {isLoading && (
        <span className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white ml-3"></span>
      )}
    </button>
  );
};

export default GradientButton;
