import { ColDef, ValueGetterParams } from 'ag-grid-community';
import moment from 'moment';

export const USERS_LIST_HEADERS: ColDef<USERS_LIST>[] = [
  { headerName: 'First Name', field: 'first_name', resizable: false },
  { headerName: 'Last Name', field: 'last_name', resizable: false },
  { headerName: 'Email', field: 'email', resizable: false, width: 250 },
  { headerName: 'Role', field: 'role', resizable: false, width: 170 },
  {
    resizable: false,
    field: 'created_at',
    headerName: 'Created At',
    valueGetter: (args: ValueGetterParams) =>
      moment(args.data.created_at).format('lll'),
  },

  {
    field: 'actions',
    resizable: false,
    headerName: 'Actions',
    cellStyle: { border: 'none' },
    cellRenderer: 'actionsListRenderer',
  },
];
