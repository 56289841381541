import { ReactNode, createContext, memo, useContext, useState } from 'react';

interface MainContextValues {
  pageHeaderName: string | null;
  setPageHeaderName: React.Dispatch<React.SetStateAction<string | null>>;
}

const MainContext = createContext<MainContextValues | null>(null);

export const MainProvider: React.FC<{
  children: ReactNode;
}> = memo(({ children }) => {
  const [pageHeaderName, setPageHeaderName] = useState<string | null>(null);

  return (
    <MainContext.Provider value={{ pageHeaderName, setPageHeaderName }}>
      {children}
    </MainContext.Provider>
  );
});

export const useMainContext = (): MainContextValues => {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error('useMainContext must be used within a MainProvider');
  }
  return context;
};
