import { AxiosInstance } from 'axios';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const addPriority = (
  axiosInstance: AxiosInstance,
  priorityFormData: TicketPrioritySLAForm,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.post(
        API_END_POINTS.priority,
        priorityFormData,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const getPriorities = (
  axiosInstance: AxiosInstance,
  page: number = 1,
  per_page: number = 10,
) => {
  return async () => {
    const data = async () => {
      const params: { [key: string]: string | number } = { page, per_page };
      return await axiosInstance.get(API_END_POINTS.priority, { params });
    };
    const result = await data();
    return result.data;
  };
};

export const deletePriority = (axiosInstance: AxiosInstance, id: number) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.delete(`${API_END_POINTS.priority}/${id}`);
    };
    const result = await data();
    return result.data;
  };
};

export const updatePriority = (
  id: number,
  axiosInstance: AxiosInstance,
  priorityFormData: TicketPrioritySLAForm,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `${API_END_POINTS.priority}/${id}`,
        priorityFormData,
      );
    };
    const result = await data();
    return result.data;
  };
};
