import toast from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import {
  fetchCategory,
  fetchPrioritiesIdsAndNames,
} from 'actions/tickets/tickets.actions';
import {
  fetchGroups,
  fetchUsersIdsAndNames,
} from 'actions/users/users.actions';
import { schema } from './schema';
import { cn, generateTicketReport } from 'utils/utils';
import { notifyError } from 'utils/ToastMessage';
import { STATUS } from 'helpers/constants/tickets';
import Label from 'components/elements/Label/Label';
import Input from 'components/elements/Input/Input';
import Button from 'components/elements/Button/Button';
import { useMainContext } from 'helpers/providers/MainContext';
import { downloadReport } from 'actions/tickets/reports.actions';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import SelectInput from 'components/elements/SelectInput/SelectInput';

const Reports: React.FC = () => {
  const methods = useForm<ReportsForm>({
    defaultValues: {
      to_date: '',
      status: [],
      from_date: '',
      category_ids: [],
      priority_ids: [],
      assigned_user_ids: [],
      assigned_group_ids: [],
    },
    resolver: zodResolver(schema),
  });
  const {
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;
  const dispatch = useAppDispatch();
  const fromDate = watch('from_date');
  const MainContext = useMainContext();
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groups, setGroupList] = useState<SelectOption[] | null>(null);
  const [priorities, setPriorities] = useState<SelectOption[] | null>(null);
  const [categories, setCategories] = useState<SelectOption[] | null>(null);
  const [assigneeList, setAssigneeList] = useState<SelectOption[] | null>(null);

  useEffect(() => {
    fetchUserData();
    fetchGroupData();
    fetchPriorities();
    fetchCategoryData();
    MainContext.setPageHeaderName('Reports');
  }, []);

  const fetchCategoryData = () => {
    dispatch(fetchCategory(axiosInstance))
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchGroupData = () => {
    dispatch(fetchGroups(axiosInstance))
      .then((response) => {
        setGroupList(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchPriorities = () => {
    dispatch(fetchPrioritiesIdsAndNames(axiosInstance))
      .then((response) => {
        setPriorities(response);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchUserData = () => {
    dispatch(fetchUsersIdsAndNames(axiosInstance))
      .then((response) => {
        setAssigneeList(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const submitHandler = (formData: ReportsForm) => {
    setIsLoading(true);
    dispatch(downloadReport(axiosInstance, formData))
      .then((response) => {
        if (response.length === 0) {
          toast('No reports found', {
            style: {
              backgroundColor: '#FFE5B4',
            },
          });
        } else {
          generateTicketReport(response);
        }
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="user-form">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => submitHandler(data))}>
          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label name="from_date" label="From Date *" className="text-xs" />
              <Input
                type="date"
                name="from_date"
                placeholder="Enter from date"
                className="new-ticket-input"
                error={errors.from_date?.message}
              />
            </div>
            <div className="input-group-wrapper mt-2">
              <Label name="to_date" label="To Date *" className="text-xs" />
              <Input
                type="date"
                name="to_date"
                min={fromDate}
                placeholder="Enter to date"
                className="new-ticket-input"
                error={errors.to_date?.message}
                max={new Date().toISOString().split('T')[0]}
              />
            </div>
          </div>

          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label
                name="category_ids"
                label="Category"
                className={cn('text-xs')}
              />
              <SelectInput
                isMulti={true}
                name="category_ids"
                options={categories ?? []}
              />
            </div>
            <div className="input-group-wrapper mt-2">
              <Label
                name="priority_ids"
                label="Priority"
                className={cn('text-xs')}
              />
              <SelectInput
                isMulti={true}
                name="priority_ids"
                options={priorities ?? []}
              />
            </div>
          </div>

          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label
                name="assigned_user_ids"
                label="Assigned User"
                className={cn('text-xs')}
              />
              <SelectInput
                isMulti={true}
                name="assigned_user_ids"
                options={assigneeList ?? []}
              />
            </div>
            <div className="input-group-wrapper mt-2">
              <Label
                name="assigned_group_ids"
                label="Assigned Group"
                className={cn('text-xs')}
              />
              <SelectInput
                isMulti={true}
                options={groups ?? []}
                name="assigned_group_ids"
              />
            </div>
          </div>

          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label name="status" label="Status" className={cn('text-xs')} />
              <SelectInput name="status" isMulti={true} options={STATUS} />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-center gap-4 sm:justify-end mt-11">
              <Button
                type="reset"
                buttonName="Cancel"
                className="cancel-button"
                onClick={() => {
                  methods.reset();
                }}
                title="Cancel"
              />
              <Button
                type="submit"
                title="Submit"
                buttonName="Download"
                isLoading={isLoading}
                className="submit-button"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Reports;
