import React from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { useAppSelector } from 'state-management/hooks/stateHooks';
import { RootState } from 'state-management/store';

const Auth: React.FC = () => {
  const token = useAppSelector((state: RootState) => state.auth.accessToken);
  const location = useLocation();
  return token ? (
    <Outlet />
  ) : (
    <Navigate to={'/login'} state={{ from: location }} replace />
  );
};

export default Auth;
