import React, { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'utils/utils';

const Input: React.FC<InputProps> = ({
  name,
  type,
  className,
  error,
  required,
  placeholder,
  contentAfter,
  ...rest
}) => {
  const { register } = useFormContext();
  return (
    <>
      <div className={cn({ 'flex justify-between': contentAfter }, className)}>
        <input
          {...rest}
          {...register(name as string)}
          type={type}
          id={name}
          required={required}
          placeholder={placeholder}
          className={cn(
            'focus:outline-none focus:shadow-outline p-3 border-none w-full',
          )}
        />
        {contentAfter && contentAfter}
      </div>
      {error && (
        <span className="text-red-carnation text-xs mt-1 font-lato-light">
          {error}
        </span>
      )}
    </>
  );
};

export default memo(Input);
