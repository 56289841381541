export enum TICKET_TYPE {
  MY_TICKET = 'my_tickets',
  ASSIGNED_TICKET = 'assigned_tickets',
}

export enum TICKET_STATUS {
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  CLOSED = 'Closed',
}

export enum ASSIGNEE_TYPE {
  USER = 'User',
  GROUP = 'Group',
}
