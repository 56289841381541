import { AxiosInstance } from 'axios';

export const postComment = (
  axiosInstance: AxiosInstance,
  ticketId: number,
  message: string,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.post(`/v1/tickets/${ticketId}/comments`, {
        message,
      });
    };
    const result = await data();
    return result.data;
  };
};

export const deleteComment = (
  axiosInstance: AxiosInstance,
  commentId: number,
  ticketId: number,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.delete(
        `/v1/tickets/${ticketId}/comments/${commentId}`,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const updateComment = (
  axiosInstance: AxiosInstance,
  commentId: number,
  message: string,
  ticketId: number,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `/v1/tickets/${ticketId}/comments/${commentId}`,
        {
          message,
        },
      );
    };
    const result = await data();
    return result.data;
  };
};
