import { AxiosInstance } from 'axios';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const fetchAssignedTicketDetails = (
  axiosInstance: AxiosInstance,
  ticketId: string,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(
        `${API_END_POINTS.fetch_assigned_ticket_details}${ticketId}`,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const fetchMyTicketDetails = (
  axiosInstance: AxiosInstance,
  ticketId: string,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(
        `${API_END_POINTS.fetch_my_ticket_details}${ticketId}`,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const fetchCategory = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.fetch_category);
    };
    const result = await data();
    return result;
  };
};

export const updateAssignedTicket = (
  axiosInstance: AxiosInstance,
  ticketId: string,
  updatedTicketData: any,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `/v1/tickets/${ticketId}/assigned`,
        updatedTicketData,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const updateMyTicket = (
  axiosInstance: AxiosInstance,
  ticketId: string,
  updatedTicketData: any,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `${API_END_POINTS.updateMyTicket}${ticketId}`,
        updatedTicketData,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const addTickets = (
  axiosInstance: AxiosInstance,
  ticketPayload: FormData,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.post(
        API_END_POINTS.add_tickets,
        ticketPayload,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const fetchMyTickets = (
  axiosInstance: AxiosInstance,
  myTicketParams: TicketParams,
) => {
  return async () => {
    const data = async () => {
      const params: { [key: string]: string | number } = {
        page: myTicketParams.page,
        per_page: myTicketParams.per_page,
      };
      if (myTicketParams.q) params.q = myTicketParams.q;
      if (myTicketParams.filterFormValues) {
        if (myTicketParams.filterFormValues.ticket_id)
          params.ticket_id = myTicketParams.filterFormValues.ticket_id;
        if (myTicketParams.filterFormValues.subject)
          params.subject = myTicketParams.filterFormValues.subject;
        if (myTicketParams.filterFormValues.assignee)
          params.assignee = myTicketParams.filterFormValues.assignee;
        if (myTicketParams.filterFormValues.due_date)
          params.due_date = myTicketParams.filterFormValues.due_date;
        if (myTicketParams.filterFormValues.status)
          params.status = myTicketParams.filterFormValues.status;
        if (myTicketParams.filterFormValues.assignee_type)
          params.assignee_type = myTicketParams.filterFormValues.assignee_type;
      }
      return await axiosInstance.get(API_END_POINTS.fetch_my_tickets, {
        params,
      });
    };
    const result = await data();
    return result.data;
  };
};

export const deleteTicket = (
  axiosInstance: AxiosInstance,
  ticketId: number,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.delete(
        `${API_END_POINTS.deleteTicket}${ticketId}`,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const fetchPrioritiesIdsAndNames = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(`${API_END_POINTS.fetch_priorities}`);
    };
    const result = await data();
    return result.data;
  };
};
