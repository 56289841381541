import { z } from 'zod';
import { REQUIRED_MESSAGES, ERROR_MESSAGES } from 'helpers/constants/messages';
import { REGEX_PATTERNS } from 'helpers/constants/regexPattern';

export const schema = z.object({
  first_name: z
    .string()
    .trim()
    .min(1, { message: REQUIRED_MESSAGES.FIRST_NAME })
    .refine((value) => REGEX_PATTERNS.name.test(value), {
      message: ERROR_MESSAGES.FIRST_NAME,
    }),
  last_name: z
    .string()
    .trim()
    .min(1, { message: REQUIRED_MESSAGES.LAST_NAME })
    .refine((value) => REGEX_PATTERNS.name.test(value), {
      message: ERROR_MESSAGES.LAST_NAME,
    }),
  role_id: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .nullable()
    .refine((data) => data !== null, {
      message: REQUIRED_MESSAGES.ROLE,
    }),
  email: z
    .string()
    .trim()
    .min(1, { message: REQUIRED_MESSAGES.EMAIL })
    .refine((value) => REGEX_PATTERNS.email.test(value), {
      message: ERROR_MESSAGES.INVALID_EMAIL,
    }),
  group_ids: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .array()
    .nullable()
    .optional(),
  supervisor_id: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .nullable()
    .optional()
    .refine((data) => data !== null, {
      message: REQUIRED_MESSAGES.SUPERVISOR,
    }),
});
