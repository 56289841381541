import { nanoid } from 'nanoid';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import usersIcon from 'assets/icons/users.svg';
import { ROLE } from 'types/enums/sidebarEnums';
import ticketIcon from 'assets/icons/ticket.svg';
import reportsIcon from 'assets/icons/reports.svg';
import settingsIcon from 'assets/icons/settings.svg';
import dashboardIcon from 'assets/icons/dashboard.svg';

const dashboardId = nanoid();
const ticketsId = nanoid();
const usersId = nanoid();
const settingsId = nanoid();
const reportsId = nanoid();

const initialState: SidebarState = {
  isSidebarOpen: false,
  sidebarItems: [
    {
      id: dashboardId,
      label: 'Dashboard',
      path: '/',
      icon: dashboardIcon,
      showSubItemsCount: 0,
      hasSubItems: false,
      isSubSidebarItemsOpen: false,
      allowedUserRoles: [ROLE.SUPER_ADMIN, ROLE.AGENT, ROLE.SUPERVISOR],
    },
    {
      id: ticketsId,
      label: 'Tickets',
      path: '/ticket/my_tickets',
      icon: ticketIcon,
      hasSubItems: true,
      showSubItemsCount: 2,
      isSubSidebarItemsOpen: false,
      allowedUserRoles: [ROLE.SUPER_ADMIN, ROLE.AGENT, ROLE.SUPERVISOR],
      subItems: [
        {
          id: nanoid(),
          parentId: ticketsId,
          label: 'My Tickets',
          path: '/ticket/my_tickets',
          show: true,
          showSubItemsCount: 0,
          hasSubItems: false,
          isSubSidebarItemsOpen: false,
          allowedUserRoles: [ROLE.AGENT],
        },
        {
          id: nanoid(),
          parentId: ticketsId,
          label: 'All Tickets',
          path: '/ticket/my_tickets',
          show: true,
          showSubItemsCount: 0,
          hasSubItems: false,
          isSubSidebarItemsOpen: false,
          allowedUserRoles: [ROLE.SUPER_ADMIN],
        },
        {
          id: nanoid(),
          parentId: ticketsId,
          label: 'Agent Tickets',
          path: '/ticket/my_tickets',
          show: true,
          showSubItemsCount: 0,
          hasSubItems: false,
          isSubSidebarItemsOpen: false,
          allowedUserRoles: [ROLE.SUPERVISOR],
        },
        {
          id: nanoid(),
          parentId: ticketsId,
          label: 'Assigned Tickets',
          path: '/ticket/assigned_tickets',
          show: true,
          showSubItemsCount: 0,
          hasSubItems: false,
          isSubSidebarItemsOpen: false,
          allowedUserRoles: [ROLE.AGENT],
        },
        {
          id: nanoid(),
          parentId: ticketsId,
          label: 'My Tickets',
          path: '/ticket/assigned_tickets',
          show: true,
          showSubItemsCount: 0,
          hasSubItems: false,
          isSubSidebarItemsOpen: false,
          allowedUserRoles: [ROLE.SUPERVISOR, ROLE.SUPER_ADMIN],
        },
      ],
    },
    {
      id: usersId,
      label: 'Users',
      path: '/users',
      icon: usersIcon,
      hasSubItems: true,
      showSubItemsCount: 0,
      isSubSidebarItemsOpen: false,
      allowedUserRoles: [ROLE.SUPER_ADMIN, ROLE.SUPERVISOR],
    },
    {
      id: settingsId,
      label: 'Settings',
      path: '/settings',
      icon: settingsIcon,
      hasSubItems: false,
      showSubItemsCount: 0,
      isSubSidebarItemsOpen: false,
      allowedUserRoles: [ROLE.SUPER_ADMIN, ROLE.SUPERVISOR, ROLE.AGENT],
    },
    {
      id: reportsId,
      label: 'Reports',
      path: '/reports',
      icon: reportsIcon,
      hasSubItems: false,
      showSubItemsCount: 0,
      isSubSidebarItemsOpen: false,
      allowedUserRoles: [ROLE.SUPER_ADMIN, ROLE.SUPERVISOR],
    },
  ],
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setIsSidebarOpen: (state, action: PayloadAction<boolean>) => {
      return { ...state, isSidebarOpen: action.payload };
    },
    setIsSubSidebarItemsOpen: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        sidebarItems: state.sidebarItems.map((item) => ({
          ...item,
          isSubSidebarItemsOpen:
            item.id === action.payload && item.hasSubItems ? true : false,
        })),
      };
    },
    setIsSubSidebarItemsClose: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        sidebarItems: state.sidebarItems.map((item) => ({
          ...item,
          isSubSidebarItemsOpen:
            item.id === action.payload && item.hasSubItems
              ? false
              : item.isSubSidebarItemsOpen,
        })),
      };
    },
    setSidebarItems: (state, action: PayloadAction<SidebarItem[]>) => {
      return {
        ...state,
        sidebarItems: action.payload,
      };
    },
  },
});

export const {
  setIsSidebarOpen,
  setIsSubSidebarItemsOpen,
  setIsSubSidebarItemsClose,
  setSidebarItems,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
