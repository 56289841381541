import {
  ColDef,
  ValueGetterParams,
  ICellRendererParams,
} from 'ag-grid-community';
import { IoMdAdd } from 'react-icons/io';
import { FaRegEdit } from 'react-icons/fa';
import { CiFilter } from 'react-icons/ci';
import { MdFilterListAlt } from 'react-icons/md';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import {
  setSearchQuery,
  setCurrentPage,
  setPerPageCount,
} from 'state-management/assignedTicket/assignedTicketSlice';
import { getHighlightedRow } from 'utils/utils';
import { notifyError } from 'utils/ToastMessage';
import { RootState } from 'state-management/store';
import Search from 'components/elements/Search/Search';
import { GRID_COLUMN_DEF } from 'helpers/constants/agGrid';
import { useMainContext } from 'helpers/providers/MainContext';
import NoRowsOverlay from 'components/components/NoRowsOverlay';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import Pagination from 'components/elements/Pagination/Pagination';
import { ASSIGNED_TICKET_LIST_HEADERS } from './AssignedTicketListHeaders';
import { fetchAssignedTickets } from 'actions/assignedTicket/assignedTicket.actions';
import { ROLE } from 'types/enums/sidebarEnums';
import TicketsFilter from 'components/elements/TicketsFilter/TicketsFilter';

const AssignedTicketList: React.FC = () => {
  const [assignedTickets, setAssignedTickets] = useState<
    Omit<ASSIGNED_TICKET_LIST, 'actions'>[]
  >([]);
  const [pagination, setPagination] = useState<Pagination>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const MainContext = useMainContext();
  const { currentPage, perPageCount, searchQuery } = useAppSelector(
    (state: RootState) => state.assignedTicket,
  );
  const currentUserRole = useAppSelector((state: RootState) => state.auth.role);

  const [filterFormData, setFilterFormData] = useState<FilterTicketForm>({
    ticket_id: '',
    subject: '',
    assignee: null,
    assignee_type: null,
    due_date: '',
    status: {
      name: 'All',
      id: '',
    },
  });
  const [isFilterFormEmpty, setIsFilterFormEmpty] = useState(true);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = (): void => {
    setOpenModal(false);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    dispatch(setCurrentPage(selected + 1));
  };

  const defaultColDef: ColDef = useMemo(() => {
    return { filter: false, suppressMovable: true };
  }, []);

  useEffect(() => {
    MainContext.setPageHeaderName(
      currentUserRole === ROLE.AGENT ? 'Assigned Tickets' : 'My Tickets',
    );
    const usersFetch = async () => {
      const assignedTicketParams = {
        page: currentPage,
        per_page: perPageCount,
        q: searchQuery,
        filterFormValues: {
          ...filterFormData,
          assignee_type: filterFormData.assignee_type?.name,
          status: filterFormData.status?.id,
          assignee: filterFormData.assignee?.name.split(' ')[0],
        },
      };
      await dispatch(fetchAssignedTickets(axiosInstance, assignedTicketParams))
        .then((response) => {
          setAssignedTickets(response.tickets);
          setPagination(response.pagination);
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        });
    };
    usersFetch();
  }, [currentPage, perPageCount, searchQuery]);

  const assignedTicketsFetchByFilter = (filterData: FilterTicketForm) => {
    const assignedTicketParams = {
      page: currentPage,
      per_page: perPageCount,
      q: searchQuery,
      filterFormValues: {
        ...filterData,
        assignee_type: filterData.assignee_type?.name,
        status: filterData.status?.id,
        assignee: filterData.assignee?.name.split(' ')[0],
      },
    };
    dispatch(fetchAssignedTickets(axiosInstance, assignedTicketParams))
      .then((response) => {
        setAssignedTickets(response.tickets);
        setPagination(response.pagination);
        setOpenModal(false);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const handleEdit = (id: number) => {
    navigate(`edit/${id}`);
  };

  const handlePerPageChange = (perPage: number) => {
    dispatch(setPerPageCount(perPage));
  };

  const setToCurrentPage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const onSearch = useCallback(
    (value: string) => {
      dispatch(setSearchQuery(value));
      dispatch(setCurrentPage(1));
    },
    [dispatch],
  );

  const AssignedTicketListActionRenderer = (
    assignedTickets: ICellRendererParams,
  ) => {
    return (
      <div className="flex">
        <button
          title="Edit Ticket"
          type="button"
          className="icon-style"
          onClick={() => handleEdit(assignedTickets.data.id)}
        >
          <FaRegEdit className="w-5 h-5 text-gray-iconColor" />
        </button>
      </div>
    );
  };

  return (
    <div className="p-4 md:px-10">
      <div className="flex flex-col items-start lg:flex-row lg:items-center lg:justify-between">
        <div className="flex flex-row items-center w-full">
          <Search onSearch={onSearch} searchQuery={searchQuery} />
          <div
            title="Filter"
            className="filter-icon"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            {isFilterFormEmpty ? (
              <CiFilter fill="black" className="w-7 h-7" />
            ) : (
              <MdFilterListAlt className="w-7 h-7" />
            )}
          </div>
        </div>
        <button
          className="submit-button flex items-center mb-4 lg:mb-0"
          title="Add New Ticket"
          onClick={() => {
            navigate('/ticket/new_ticket');
          }}
        >
          <span className="pr-1">
            <IoMdAdd size={15} />
          </span>
          Add Ticket
        </button>
      </div>
      <div
        className={'ag-theme-quartz'}
        style={{ width: '100%', height: '390px' }}
      >
        <AgGridReact
          rowData={assignedTickets}
          getRowClass={getHighlightedRow}
          noRowsOverlayComponentParams={{
            message: 'No Assigned Tickets available',
          }}
          noRowsOverlayComponent={NoRowsOverlay}
          reactiveCustomComponents={true}
          columnDefs={[
            {
              width: 85,
              headerName: 'S.No',
              valueGetter: (params: ValueGetterParams) =>
                pagination!.start_at + params!.node!.rowIndex!,
              resizable: false,
              sortable: false,
              maxWidth: GRID_COLUMN_DEF.sNo.maxWidth,
            },
            ...ASSIGNED_TICKET_LIST_HEADERS,
          ]}
          defaultColDef={defaultColDef}
          components={{
            actionsListRenderer: AssignedTicketListActionRenderer,
          }}
          suppressCellFocus={true}
        />
      </div>
      {pagination && (
        <Pagination
          pagination={pagination}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          handlePerPageChange={handlePerPageChange}
        />
      )}

      <Modal
        center
        open={openModal}
        onClose={closeModal}
        showCloseIcon={false}
        animationDuration={300}
        closeOnOverlayClick={false}
        classNames={{
          modalAnimationIn: 'customEnterModalAnimation',
          overlayAnimationIn: 'customEnterOverlayAnimation',
        }}
      >
        <TicketsFilter
          closeModal={closeModal}
          ticketsFetchByFilter={assignedTicketsFetchByFilter}
          filterFormData={filterFormData}
          setFilterFormData={setFilterFormData}
          setIsFilterFormEmpty={setIsFilterFormEmpty}
          setCurrentPage={setToCurrentPage}
        />
      </Modal>
    </div>
  );
};

export default AssignedTicketList;
