import moment from 'moment';
import { AxiosInstance } from 'axios';

import { getIdsFromObjects } from 'utils/utils';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const downloadReport = (
  axiosInstance: AxiosInstance,
  reportsPayload: ReportsForm,
) => {
  return async () => {
    const payload = {
      status: getIdsFromObjects(reportsPayload.status),
      to_date: moment(new Date(reportsPayload.to_date).toISOString()).endOf(
        'day',
      ),
      priority_ids: getIdsFromObjects(reportsPayload.priority_ids),
      category_ids: getIdsFromObjects(reportsPayload.category_ids),
      from_date: new Date(reportsPayload.from_date).toISOString(),
      assigned_user_ids: getIdsFromObjects(reportsPayload.assigned_user_ids),
      assigned_group_ids: getIdsFromObjects(reportsPayload.assigned_group_ids),
    };
    if (payload.assigned_group_ids?.length === 0)
      delete payload.assigned_group_ids;
    if (payload.status?.length === 0) delete payload.status;
    if (payload.category_ids?.length === 0) delete payload.category_ids;
    if (payload.priority_ids?.length === 0) delete payload.priority_ids;
    if (payload.assigned_user_ids?.length === 0)
      delete payload.assigned_user_ids;

    const data = async () => {
      return await axiosInstance.post(API_END_POINTS.reports, payload);
    };
    const result = await data();
    return result.data;
  };
};
