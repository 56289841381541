import { useMemo } from 'react';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { RootState } from 'state-management/store';
import { useAppSelector } from 'state-management/hooks/stateHooks';
import { CUSTOMER_TICKETS_LIST_HEADERS } from './CustomerTicketsListHeaders';
import NoRowsOverlay from 'components/components/NoRowsOverlay';
import { IoMdAdd } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const CustomerTicketsList: React.FC<{ closeModal: () => void }> = ({
  closeModal,
}) => {
  const navigate = useNavigate();
  const currentUserId = useAppSelector((state: RootState) => state.auth.id);

  const customerData: CustomerTicketsState = useAppSelector(
    (state: RootState) => state.customerTickets,
  );

  const defaultColDef: ColDef = useMemo(() => {
    return { filter: false };
  }, []);

  const handleRowClick = (event: RowClickedEvent) => {
    const { id, created_by } = event.data;
    created_by === currentUserId
      ? navigate(`/ticket/my_tickets/edit/${id}`)
      : navigate(`/ticket/assigned_tickets/edit/${id}`);
    closeModal();
  };

  return (
    <div className={'ag-theme-quartz mt-10'} style={{ width: '100%' }}>
      <button
        className="submit-button flex items-center ml-auto"
        title="Add New Ticket"
        onClick={() => {
          navigate('/ticket/new_ticket', {
            state: { ...customerData.data.customer_details },
          });
          closeModal();
        }}
      >
        <span className="pr-1">
          <IoMdAdd size={15} />
        </span>
        Add Ticket
      </button>
      <h4 className="font-lato-semibold mb-3">Customer Tickets</h4>
      <AgGridReact
        domLayout="autoHeight"
        rowStyle={{ width: '100%' }}
        suppressCellFocus={true}
        rowData={customerData.data.tickets}
        noRowsOverlayComponentParams={{
          message: 'No Customer Tickets available',
        }}
        noRowsOverlayComponent={NoRowsOverlay}
        reactiveCustomComponents={true}
        defaultColDef={defaultColDef}
        columnDefs={[...CUSTOMER_TICKETS_LIST_HEADERS]}
        onRowClicked={handleRowClick}
      />
    </div>
  );
};

export default CustomerTicketsList;
