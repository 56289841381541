import React, { memo } from 'react';

const Button: React.FC<ButtonProps> = ({
  type = 'button',
  buttonName,
  className,
  isLoading,
  shouldDisable,
  title,
  onClick,
}) => {
  return (
    <button
      type={type}
      className={`${className} flex justify-center ${(shouldDisable || isLoading) && 'opacity-80 cursor-not-allowed'}`}
      disabled={isLoading || shouldDisable}
      onClick={onClick}
      title={title}
    >
      <span>{buttonName}</span>
      {isLoading && (
        <div className="animate-spin rounded-full h-4 w-4 border-t border-b border-white ml-2"></div>
      )}
    </button>
  );
};

export default memo(Button);
