import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Dispatch, SetStateAction, memo } from 'react';

const Priority: React.FC<{
  priority: Priority;
  openDeleteModal: (category: Priority) => void;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setSelectedPriority: Dispatch<SetStateAction<Priority | null>>;
}> = ({ priority, openDeleteModal, setOpenModal, setSelectedPriority }) => {
  return (
    <div className="border py-1.5 px-3 mt-2 rounded-md shadow-md flex justify-between items-center mr-2.5">
      <div>
        <h5 className="font-semibold">{priority.name}</h5>
        <small className="text-xs">
          SLA overdue:{' '}
          <span className="font-semibold text-gray-rolling-stone">
            {priority.overdue_duration}
          </span>
        </small>
      </div>
      <div>
        <button
          type="button"
          title="Edit Priority"
          className="mr-5 outline-none"
          onClick={() => {
            setOpenModal(true);
            setSelectedPriority(priority);
          }}
        >
          <FiEdit size={16} color="navy" />
        </button>
        <button
          type="button"
          title="Delete priority"
          className="outline-none"
          onClick={() => openDeleteModal(priority)}
        >
          <RiDeleteBinLine size={16} color="maroon" />
        </button>
      </div>
    </div>
  );
};

export default memo(Priority);
