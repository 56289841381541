export const OTP_LENGTH = 4;

export const PASSWORD_LENGTH = {
  MINIMUM: 6,
  MAXIMUM: 25,
};

export const TICKET_FIELDS_LENGTH = {
  SUBJECT: 100,
  DESCRIPTION: 250,
  NAME: 30,
  EMAIL: 40,
};

export const USER_FIELDS_LENGTH = {
  FIRST_NAME: 25,
  LAST_NAME: 25,
};
