import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearStorage } from 'state-management/auth';
import { RootState } from 'state-management/store';
import environment from 'environment/environment';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'state-management/hooks/stateHooks';

export const axiosInstance = axios.create({
  baseURL: environment.baseURL,
});

let errorData: ErrorData = {
  errorList: [],
  message: '',
  status: null,
};

const useAxiosInstance = () => {
  const accessToken = useAppSelector(
    (state: RootState) => state.auth.accessToken,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      (config) => {
        if (accessToken && !config.avoidAccessToken) {
          config.headers['Authorization'] = accessToken;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      async (error: AxiosError<ErrorResponse>) => {
        const statusCode = error.response?.status;
        const errorMessages = error.response?.data.errors!;
        errorData.status = statusCode || null;

        if (errorMessages && statusCode) {
          switch (statusCode) {
            case 0:
              errorData.message =
                'Sorry, no Internet connectivity, Please reconnect and try again';
              break;
            case 400:
              errorData.message = errorMessages[0] || 'Bad request';
              break;
            case 401:
              dispatch(clearStorage());
              navigate('/login', { replace: true, state: { from: location } });
              errorData.message =
                errorMessages[0] ||
                'Sorry for the inconvenience, but you are not authorized to look into this page';
              break;
            case 403:
              errorData.message = errorMessages[0] || 'Forbidden';
              break;
            case 404:
              errorData.message = errorMessages[0] || 'Not found';
              break;
            case 422:
              errorData.errorList = errorMessages;
              errorData.message = 'Validation error';
              break;
            case 500:
              errorData.message = errorMessages[0] || 'Internal server error';
              break;
            default:
              errorData.message =
                errorMessages[0] ||
                'Something went wrong. Please contact admin';
          }
        } else {
          errorData.message = error.message;
        }
        return Promise.reject(errorData);
      },
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
      errorData = {
        errorList: [],
        message: '',
        status: null,
      };
    };
  }, [accessToken]);

  return axiosInstance;
};

export default useAxiosInstance;
