import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import GradientButton from 'components/elements/GradientButton/GradientButton';
import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import { resentOtp, verifyOtp } from 'state-management/auth';
import { RootState } from 'state-management/store';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import OtpInput from 'components/elements/OtpInput/OtpInput';
import { IoArrowBack } from 'react-icons/io5';
import Label from 'components/elements/Label/Label';
import { schema } from './schema';
import './VerifyOtp.css';
import Button from 'components/elements/Button/Button';

const VerifyOtp = () => {
  const methods = useForm<VerifyOTPForm>({
    defaultValues: {
      otp: '',
    },
    resolver: zodResolver(schema),
  });
  const {
    formState: { errors },
  } = methods;
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const resetPassToken = useAppSelector(
    (state: RootState) => state.auth.resetPassToken,
  );

  const navigate = useNavigate();
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [isResentOtp, setIsResentOtp] = useState(false);

  const onSubmit = (data: VerifyOTPForm) => {
    setIsOtpSend(true);
    dispatch(verifyOtp(axiosInstance, data, resetPassToken))
      .then((result) => {
        notifySuccess(result.message);
        navigate('/reset_password');
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsOtpSend(false);
      });
  };

  const onClickResentOtp = () => {
    setIsResentOtp(true);
    dispatch(resentOtp(axiosInstance, resetPassToken))
      .then((result) => {
        notifySuccess(result.message);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsResentOtp(false);
      });
  };

  return (
    <div className="login-card">
      <div className="flex items-center justify-center mb-4">
        <IoArrowBack
          size={20}
          onClick={() => {
            navigate('/forgot_password');
          }}
          className="cursor-pointer"
          title="Back"
        />
        <h4 className="login-header pl-3">Verify OTP</h4>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data: VerifyOTPForm) =>
            onSubmit(data),
          )}
        >
          <div className="flex flex-col items-center">
            <div className="flex flex-col">
              <Label name="Enter OTP" label="Enter OTP" className="text-sm" />
              <OtpInput name="otp" error={errors.otp?.message} />
            </div>
            <GradientButton
              type="submit"
              className="verify-otp-button login-button-text rounded-md"
              buttonText="Verify OTP"
              title="Verify OTP"
              isLoading={isOtpSend}
            />
            <Button
              buttonName="Resend OTP"
              className="form-button bg-black-shark-50 text-white"
              type="button"
              onClick={onClickResentOtp}
              shouldDisable={isResentOtp}
              isLoading={isResentOtp}
              title="Resend OTP"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default VerifyOtp;
