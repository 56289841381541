export enum SSE_EVENTS {
  ticket_created = 'ticket_created',
  notifications = 'notifications',
  ticket_updated = 'ticket_updated',
  ticket_reassigned = 'ticket_reassigned',
  notifications_updated = 'notifications_updated',
  comment_created = 'comment_created',
  ticket_deleted = 'ticket_deleted',
  trigger_tickets = 'trigger_tickets',
}
