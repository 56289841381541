import { z } from 'zod';
import { REQUIRED_MESSAGES, ERROR_MESSAGES } from 'helpers/constants/messages';
import { REGEX_PATTERNS } from 'helpers/constants/regexPattern';

export const schema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: REQUIRED_MESSAGES.EMAIL })
    .refine((value) => REGEX_PATTERNS.email.test(value), {
      message: ERROR_MESSAGES.INVALID_EMAIL,
    }),
  password: z.string().trim().min(1, { message: REQUIRED_MESSAGES.PASSWORD }),
});
