import { IoMdAdd } from 'react-icons/io';
import Modal from 'react-responsive-modal';
import React, { useEffect, useState } from 'react';

import Priority from './Priority';
import AddPriority from './AddPriority';
import {
  getPriorities,
  deletePriority,
} from 'actions/SLA/ticketPrioritySLA.actions';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import Pagination from 'components/elements/Pagination/Pagination';
import DeleteModal from 'components/components/DeleteModal';

const TicketPrioritySLA: React.FC = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [priorityList, setPriorityList] = useState<Priorities | null>(null);
  const [isopenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [selectedPriority, setSelectedPriority] = useState<Priority | null>(
    null,
  );

  const closeModal = (): void => {
    setOpenModal(false);
    setSelectedPriority(null);
    setIsOpenDeleteModal(false);
  };

  const fetchPriorities = (perPage?: number, currentPage?: number): void => {
    setIsLoading(true);
    dispatch(getPriorities(axiosInstance, currentPage, perPage))
      .then((response: Priorities) => {
        setPriorityList(response);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => setIsLoading(false));
  };

  const removePriority = (): void => {
    setIsDeleting(true);
    dispatch(deletePriority(axiosInstance, selectedPriority?.id || 0))
      .then((response) => {
        fetchPriorities(
          priorityList?.pagination.per_page,
          priorityList?.pagination.current_page,
        );
        closeModal();
        notifySuccess(response.message);
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => setIsDeleting(false));
  };

  const handlePageChange = ({ selected }: { selected: number }): void => {
    fetchPriorities(priorityList?.pagination?.per_page, selected + 1);
  };

  const handlePerPageChange = (perPage: number): void => {
    fetchPriorities(perPage);
  };

  const openDeleteModal = (priority: Priority) => {
    setSelectedPriority(priority);
    setIsOpenDeleteModal(true);
  };

  const cancelDeletion = () => {
    setSelectedPriority(null);
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    fetchPriorities();
  }, []);

  return (
    <div className="border py-3 rounded-md shadow-md">
      <div className="flex justify-between gap-6 pb-2 pl-3 border-b-2">
        <div>
          <h5 className="font-semibold text-lg">Ticket Priorities</h5>
        </div>
        <button
          title="Add priority"
          className="submit-button flex self-center min-w-28 mr-3 outline-none"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <span className="pr-1">
            <IoMdAdd size={15} />
          </span>
          Add Priority
        </button>
      </div>
      <div className="h-[40vh] overflow-y-auto pl-3">
        {!isLoading ? (
          priorityList?.priorities?.map((priority: Priority) => (
            <Priority
              key={priority.id}
              priority={priority}
              setOpenModal={setOpenModal}
              openDeleteModal={openDeleteModal}
              setSelectedPriority={setSelectedPriority}
            />
          ))
        ) : (
          <div className="h-[40vh] flex items-center justify-center">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-black mt-10"></div>
          </div>
        )}
        {priorityList &&
          (priorityList.pagination.total_count > 10 ||
            priorityList.pagination.total_pages > 1) && (
            <Pagination
              pagination={priorityList.pagination}
              handlePageChange={handlePageChange}
              handlePerPageChange={handlePerPageChange}
              currentPage={priorityList.pagination.current_page}
            />
          )}
        {!isLoading &&
          (priorityList?.priorities?.length === 0 ||
            !priorityList?.priorities) && (
            <div className="h-[15vh] flex justify-center items-center">
              <small className="text-gray-rolling-stone">
                No priorities to show
              </small>
            </div>
          )}
      </div>
      <Modal
        center
        open={openModal}
        onClose={closeModal}
        showCloseIcon={false}
        animationDuration={300}
        closeOnOverlayClick={false}
        classNames={{
          modalAnimationIn: 'customEnterModalAnimation',
          overlayAnimationIn: 'customEnterOverlayAnimation',
        }}
      >
        <AddPriority
          closeModal={closeModal}
          priorities={priorityList}
          fetchPriorities={fetchPriorities}
          selectedPriority={selectedPriority}
        />
      </Modal>
      <DeleteModal
        isLoading={isDeleting}
        openModal={isopenDeleteModal}
        cancelDeletion={cancelDeletion}
        confirmDeletion={removePriority}
        description="Are you sure want to delete the priority?"
      />
    </div>
  );
};

export default TicketPrioritySLA;
