export const REGEX_PATTERNS = {
  email: /^[a-z0-9.]+@[a-z]+\.[a-z]{2,5}$/,
  mobile: /^(?!(\d)\1{9})[6,7,8,9]\d{9}$/,
  name: /^[a-zA-Z\s]+$/,
  password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
  number: '\\d',
  special_character: '^[a-zA-Z0-9]*$',
  upper_case: '[A-Z]',
  lower_case: '[a-z]',
  is_mobile: /^[0-9]$|^Backspace$/,
};

export const PASSWORD_REGEX_PATTERNS = {
  upper_case: /^(?=.*[A-Z])/,
  lower_case: /^(?=.*[a-z])/,
  number: /^(?=.*\d)/,
  special_character: /^(?=.*[!@#$%^&*])/,
};
