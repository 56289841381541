import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  addTickets,
  fetchCategory,
  fetchPrioritiesIdsAndNames,
} from 'actions/tickets/tickets.actions';
import { schema } from './schema';
import {
  fetchGroups,
  fetchUsersIdsAndNames,
} from 'actions/users/users.actions';
import './NewTicket.css';
import { cn, preventPasting } from 'utils/utils';
import Label from 'components/elements/Label/Label';
import Input from 'components/elements/Input/Input';
import Button from 'components/elements/Button/Button';
import { ASSIGNEE_TYPE } from 'types/enums/tickets.enum';
import { TOASTER_MESSAGES } from 'helpers/constants/messages';
import { useMainContext } from 'helpers/providers/MainContext';
import { REGEX_PATTERNS } from 'helpers/constants/regexPattern';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import FileUpload from 'components/elements/FileUpload/FileUpload';
import SelectInput from 'components/elements/SelectInput/SelectInput';
import { TICKET_FIELDS_LENGTH } from 'helpers/constants/fieldLengths';
import {
  ASSIGNEE_TYPE_LIST,
  CREATED_VIA,
  STATUS,
} from 'helpers/constants/tickets';
import MarkdownEditor from 'components/elements/MarkdownEditor/MarkdownEditor';

const NewTicket: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const axiosInstance = useAxiosInstance();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const MainContext = useMainContext();
  const [priorities, setPriorities] = useState<SelectOption[] | null>(null);
  const [categories, setCategories] = useState<SelectOption[] | null>(null);
  const [assigneeList, setAssigneeList] = useState<SelectOption[] | null>(null);
  const location = useLocation();
  const [shouldDisableMobile, setShouldDisableMobile] = useState(false);

  const methods = useForm<Tickets>({
    defaultValues: {
      subject: '',
      description: '',
      status: { name: 'Open', id: 'Open' },
      priority: null,
      due_date: '',
      category: null,
      assignee_type: null,
      attachment: [],
      assignee: null,
      customer: {
        name: '',
        email: '',
        mobile: '',
      },
      is_escalated: false,
    },
    resolver: zodResolver(schema),
  });
  const {
    formState: { errors },
    reset,
    watch,
    handleSubmit,
    setValue,
  } = methods;
  const assigneeType = watch('assignee_type');

  useEffect(() => {
    MainContext.setPageHeaderName('New Ticket');
    fetchCategoryData();
    if (location.state) {
      reset();
      const customerData: CustomerData | null = location.state;
      setValue('customer.mobile', customerData?.mobile?.toString() || '');
      setValue('customer.email', customerData?.email || '');
      setValue('customer.name', customerData?.name || '');
      setShouldDisableMobile(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (assigneeType) {
      assigneeType.id === ASSIGNEE_TYPE.USER
        ? fetchUserData()
        : fetchGroupData();
    }
    setValue('assignee', null);
    fetchPriorities();
  }, [assigneeType]);

  const fetchCategoryData = () => {
    dispatch(fetchCategory(axiosInstance))
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchGroupData = () => {
    dispatch(fetchGroups(axiosInstance))
      .then((response) => {
        setAssigneeList(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchPriorities = () => {
    dispatch(fetchPrioritiesIdsAndNames(axiosInstance))
      .then((response) => {
        setPriorities(response);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchUserData = () => {
    dispatch(fetchUsersIdsAndNames(axiosInstance))
      .then((response) => {
        setAssigneeList(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const onTicketCreation = (data: Tickets) => {
    setIsLoading(true);
    let payloadData: NewTicketDataPayload = {
      subject: data.subject,
      description: data.description,
      priority_id: data.priority?.id as number,
      category_id: data.category?.id as number,
      due_date: new Date(data.due_date).toISOString(),
      assignee_type: data.assignee_type?.id as string,
      customer: {
        name: data.customer.name,
        email: data.customer.email,
        mobile: data.customer.mobile,
      },
      created_via: CREATED_VIA,
      status: data.status?.id as string,
      is_escalated: data.is_escalated,
    };
    if (assigneeType?.id === ASSIGNEE_TYPE.USER) {
      payloadData = {
        ...payloadData,
        assigned_user_id: data.assignee?.id as number,
      };
    } else {
      payloadData = {
        ...payloadData,
        assigned_group_id: data.assignee?.id as number,
      };
    }

    const formData = new FormData();
    formData.append('data', JSON.stringify(payloadData));
    if (data.attachment) {
      data.attachment.forEach((file, index) => {
        formData.append(`file${index + 1}`, file);
      });
    }
    dispatch(addTickets(axiosInstance, formData))
      .then(() => {
        notifySuccess(TOASTER_MESSAGES.TICKET_CREATION_SUCCESS);
        navigate('/ticket/my_tickets');
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleReset = () => {
    reset();
  };

  const handleNameKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isAlphabetic = REGEX_PATTERNS.name.test(event.key);
    if (!isAlphabetic) {
      event.preventDefault();
    }
  };

  const handleMobileKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    const isMobile = REGEX_PATTERNS.is_mobile.test(event.key);
    if (!isMobile) {
      event.preventDefault();
    }
  };

  return (
    <div className="my-[24px] mx-7 sm:mx-12 md:mx-32 xl:mx-72">
      <FormProvider {...methods}>
        <form
          encType="multipart/form-data"
          onSubmit={handleSubmit((data: Tickets) => onTicketCreation(data))}
        >
          <div>
            <h5 className="mt-2 font-lato-bold">Customer Details</h5>
            <div className="flex flex-col mt-3">
              <Label name="customer.name" label="Name *" className="text-xs" />
              <Input
                type="text"
                name="customer.name"
                placeholder="Enter Name"
                className="new-ticket-input"
                onKeyDown={handleNameKeyDown}
                onPaste={preventPasting}
                maxLength={TICKET_FIELDS_LENGTH.NAME}
                error={errors.customer?.name?.message}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-20">
              <div className="flex flex-col mt-4">
                <Label
                  name="customer.email"
                  label="Email *"
                  className="text-xs"
                />
                <Input
                  type="email"
                  name="customer.email"
                  placeholder="Enter email"
                  className="new-ticket-input"
                  error={errors.customer?.email?.message}
                  maxLength={TICKET_FIELDS_LENGTH.EMAIL}
                />
              </div>
              <div className="flex flex-col mt-4">
                <Label
                  name="customer.mobile"
                  label="Mobile *"
                  className="text-xs"
                />
                <Input
                  type="tel"
                  name="customer.mobile"
                  placeholder="Enter mobile"
                  className="new-ticket-input basis-1/2"
                  error={errors.customer?.mobile?.message}
                  maxLength={10}
                  onKeyDown={handleMobileKeyDown}
                  disabled={shouldDisableMobile}
                />
              </div>
            </div>
          </div>
          <h5 className="mt-8 font-lato-bold">Ticket Details</h5>
          <div className="flex flex-col gap-4 mt-3">
            <div className="flex flex-col">
              <Label name="subject" label="Subject *" className="text-xs" />
              <Input
                type="text"
                name="subject"
                placeholder="Enter subject"
                className="new-ticket-input"
                error={errors.subject?.message}
                maxLength={TICKET_FIELDS_LENGTH.SUBJECT}
              />
            </div>
            <div className="flex flex-col">
              <Label
                name="description"
                label="Description *"
                className="text-xs"
              />
              <MarkdownEditor
                name="description"
                placeholder="Enter your description"
                className="new-ticket-input"
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-24 gap-y-4">
              <div className="flex flex-col">
                <Label
                  name="status"
                  label="Status *"
                  className={cn('text-xs')}
                />
                <SelectInput options={STATUS} name="status" isDisabled={true} />
              </div>
              <div className="flex flex-col">
                <Label
                  name="priority"
                  label="Priority *"
                  className={cn('text-xs')}
                />
                <SelectInput options={priorities ?? []} name="priority" />
              </div>
              <div className="flex flex-col">
                <Label
                  name="category"
                  label="Category *"
                  className={cn('text-xs')}
                />
                <SelectInput options={categories ?? []} name="category" />
              </div>
              <div className="flex flex-col">
                <Label
                  name="assignee_type"
                  label="Assignee Type *"
                  className={cn('text-xs')}
                />
                <SelectInput
                  options={ASSIGNEE_TYPE_LIST}
                  name="assignee_type"
                />
              </div>
              <div className="flex flex-col">
                <Label
                  name="assignee"
                  label="Assignee *"
                  className={cn('text-xs')}
                />
                <SelectInput options={assigneeList ?? []} name="assignee" />
              </div>
              <div className="flex flex-col">
                <Label name="due_date" label="Due Date *" className="text-xs" />
                <Input
                  type="date"
                  name="due_date"
                  placeholder="Enter due date"
                  className="new-ticket-input"
                  error={errors.due_date?.message}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div className="flex flex-col z-0">
                <Label
                  name="attachment"
                  label="Attach Files"
                  className="font-lato-bold mb-2 text-xs"
                />
                <FileUpload
                  name="attachment"
                  placeholder="Add attachment"
                  className="file-input"
                  isMultiple={true}
                />
              </div>
              <div className="flex items-center gap-3 mt-3">
                <Label
                  name="is_escalated"
                  label="Escalate"
                  className="font-lato-bold text-sm mb-0"
                />
                <Input type="checkbox" name="is_escalated" />
              </div>
            </div>
            <div className="flex justify-center sm:justify-end gap-4 mt-11">
              <Button
                type="button"
                buttonName="Cancel"
                isLoading={isLoading}
                onClick={handleReset}
                className="cancel-button"
                title="Cancel"
              />
              <Button
                type="submit"
                buttonName="Save"
                isLoading={isLoading}
                className="submit-button"
                title="Submit"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewTicket;
