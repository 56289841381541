import { IoMdAdd } from 'react-icons/io';
import Modal from 'react-responsive-modal';
import React, { useEffect, useState } from 'react';

import Category from './Category';
import AddCategory from './AddCategory';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import Pagination from 'components/elements/Pagination/Pagination';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import { deleteCategory, getCategories } from 'actions/SLA/categories.actions';
import DeleteModal from 'components/components/DeleteModal';

const Categories: React.FC = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<Categories | null>(null);
  const [isopenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<Group | null>(null);

  const closeModal = (): void => {
    setOpenModal(false);
    setSelectedCategory(null);
    setIsOpenDeleteModal(false);
  };

  const fetchCategories = (perPage?: number, currentPage?: number): void => {
    setIsLoading(true);
    dispatch(getCategories(axiosInstance, currentPage, perPage))
      .then((response) => {
        setCategoryList(response);
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => setIsLoading(false));
  };

  const removeCategory = (): void => {
    setIsDeleting(true);
    dispatch(deleteCategory(axiosInstance, selectedCategory?.id || 0))
      .then((response) => {
        fetchCategories(
          categoryList?.pagination.per_page,
          categoryList?.pagination.current_page,
        );
        closeModal();
        notifySuccess(response.message);
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const handlePageChange = ({ selected }: { selected: number }): void => {
    fetchCategories(categoryList?.pagination?.per_page, selected + 1);
  };

  const handlePerPageChange = (perPage: number): void => {
    fetchCategories(perPage);
  };

  const openDeleteModal = (category: Group) => {
    setSelectedCategory(category);
    setIsOpenDeleteModal(true);
  };

  const cancelDeletion = () => {
    setSelectedCategory(null);
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="border py-3 rounded-md shadow-md">
      <div className="flex justify-between gap-6 pb-2 pl-3 border-b-2">
        <div>
          <h5 className="font-semibold text-lg">Categories</h5>
        </div>
        <button
          title="Add category"
          className="submit-button flex self-center min-w-24 mr-3 outline-none"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <span className="pr-1">
            <IoMdAdd size={15} />
          </span>
          Add Category
        </button>
      </div>
      <div className="h-[40vh] overflow-y-auto pl-3">
        {!isLoading ? (
          categoryList?.categories.map((category: Group) => (
            <Category
              key={category.id}
              category={category}
              setOpenModal={setOpenModal}
              openDeleteModal={openDeleteModal}
              setSelectedCategory={setSelectedCategory}
            />
          ))
        ) : (
          <div className="h-[40vh] flex items-center justify-center">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-black mt-10"></div>
          </div>
        )}
        {categoryList &&
          (categoryList.pagination.total_count > 10 ||
            categoryList.pagination.total_pages > 1) && (
            <Pagination
              pagination={categoryList.pagination}
              handlePageChange={handlePageChange}
              currentPage={categoryList.pagination.current_page}
              handlePerPageChange={handlePerPageChange}
            />
          )}
        {!isLoading &&
          (categoryList?.categories.length === 0 || !categoryList) && (
            <div className="h-[15vh] flex justify-center items-center">
              <small className="text-gray-rolling-stone">
                No Categories to show
              </small>
            </div>
          )}
      </div>
      <Modal
        center
        open={openModal}
        onClose={closeModal}
        showCloseIcon={false}
        animationDuration={300}
        closeOnOverlayClick={false}
        classNames={{
          modalAnimationIn: 'customEnterModalAnimation',
          overlayAnimationIn: 'customEnterOverlayAnimation',
        }}
      >
        <AddCategory
          closeModal={closeModal}
          categoryList={categoryList}
          fetchCategories={fetchCategories}
          selectedCategory={selectedCategory}
        />
      </Modal>
      <DeleteModal
        isLoading={isDeleting}
        openModal={isopenDeleteModal}
        cancelDeletion={cancelDeletion}
        confirmDeletion={removeCategory}
        description="Are you sure want to delete the category?"
      />
    </div>
  );
};

export default Categories;
