import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  setIsSidebarOpen,
  setIsSubSidebarItemsClose,
  setIsSubSidebarItemsOpen,
} from 'state-management/sidebar/sidebarSlice';
import { RootState } from 'state-management/store';
import { cn } from 'utils/utils';
import closeIcon from 'assets/icons/close.svg';
import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import './Sidebar.css';

const Sidebar: React.FC = () => {
  const isSidebarOpen = useAppSelector(
    (state: RootState) => state.sidebar.isSidebarOpen,
  );
  const sidebarItems = useAppSelector(
    (state: RootState) => state.sidebar.sidebarItems,
  );
  const dispatch = useAppDispatch();
  return (
    <aside
      className={cn('sidebar-container', {
        'transition-transform sm:translate-x-0 translate-x-0': isSidebarOpen,
        '-translate-x-full sm:translate-x-0': !isSidebarOpen,
      })}
    >
      <div className="h-full">
        <button
          type="button"
          className="sidebar-close-btn"
          onClick={() => dispatch(setIsSidebarOpen(!isSidebarOpen))}
        >
          <img src={closeIcon} className="w-5 h-5" alt="Close" />
        </button>
        <ul className="flex flex-col gap-8">
          {sidebarItems.map((sidebarItem) => (
            <SidebarItem key={sidebarItem.id} sidebarItem={sidebarItem} />
          ))}
        </ul>
      </div>
    </aside>
  );
};

const SidebarItem: React.FC<{
  sidebarItem: SidebarItem;
}> = React.memo(({ sidebarItem }) => {
  const {
    id,
    icon,
    label,
    path,
    isSubSidebarItemsOpen,
    hasSubItems,
    subItems,
  } = sidebarItem;
  const dispatch = useAppDispatch();
  const location = useLocation();

  const sidebarItems = useAppSelector(
    (state: RootState) => state.sidebar.sidebarItems,
  );

  const [isActiveSubItem, setIsActiveSubItem] = useState<
    SidebarItem | undefined
  >(undefined);

  useEffect(() => {
    if (subItems && hasSubItems) {
      const isActiveSubItem = subItems.find((subItem) =>
        location.pathname.includes(subItem.path),
      );
      if (!isActiveSubItem) {
        const isSelectedSidebarItem = sidebarItems.find(
          (sidebarItem) => sidebarItem.isSubSidebarItemsOpen,
        );
        isSelectedSidebarItem &&
          dispatch(setIsSubSidebarItemsClose(isSelectedSidebarItem.id));
      }
      isActiveSubItem &&
        dispatch(setIsSubSidebarItemsOpen(isActiveSubItem.parentId));
      setIsActiveSubItem(isActiveSubItem);
    }
  }, [location]);

  const handleSubSidebarItemsOpen = (id: string) => {
    dispatch(setIsSubSidebarItemsOpen(id));
  };

  return (
    <li>
      <NavLink
        to={path}
        data-id={id}
        data-selected={isSubSidebarItemsOpen}
        data-subitems={hasSubItems}
        onClick={() => handleSubSidebarItemsOpen(id)}
        className="sidebar-item-link"
      >
        {({ isActive }) => (
          <>
            <div
              className={cn('rounded-md p-2', {
                'bg-white-link-water-200': isActive || isActiveSubItem,
              })}
            >
              <img src={icon} className="sidebar-item-img" alt="Sidebar item" />
            </div>
            <span className="text-xs ms-3 sm:ms-0">{label}</span>
          </>
        )}
      </NavLink>
      {sidebarItem.hasSubItems &&
        sidebarItem.subItems &&
        sidebarItem.isSubSidebarItemsOpen &&
        sidebarItem.showSubItemsCount > 0 && (
          <ul className="py-2 sm:space-y-1">
            {sidebarItem.subItems.map((subItem) => (
              <SubSidebarItem key={subItem.id} subSidebarItem={subItem} />
            ))}
          </ul>
        )}
    </li>
  );
});

const SubSidebarItem: React.FC<{
  subSidebarItem: SubItem;
}> = React.memo(({ subSidebarItem }) => {
  const { label, path, show } = subSidebarItem;
  return (
    <>
      {show ? (
        <li>
          <NavLink
            to={path}
            className={({ isActive }) =>
              cn('sidebar-subitem-link', {
                'bg-blue-hawkes': isActive,
              })
            }
          >
            {label}
          </NavLink>
        </li>
      ) : null}
    </>
  );
});

export default Sidebar;
