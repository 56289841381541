import React, { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';

import './style/app.css';
import Auth from 'components/components/Auth';
import Main from 'containers/layout/Main/Main';
import { ROLE } from 'types/enums/sidebarEnums';
import RoleGuard from 'components/components/RoleGuard';
import UnAuthGuard from 'components/components/UnAuthGuard';
import Login from 'components/renderings/public/Login/Login';
import Profile from 'components/renderings/secure/Profile/Profile';
import Reports from 'components/renderings/secure/Reports/Reports';
import Settings from 'components/renderings/secure/Settings/Settings';
import NewUser from 'components/renderings/secure/Users/NewUser/NewUser';
import VerifyOtp from 'components/renderings/public/VerifyOtp/VerifyOtp';
import Dashboard from 'components/renderings/secure/Dashboard/Dashboard';
import EditUser from 'components/renderings/secure/Users/EditUser/EditUser';
import AuthLayout from 'components/renderings/public/AuthLayout/AuthLayout';
import UsersList from 'components/renderings/secure/Users/UsersList/UsersList';
import SetPassword from 'components/renderings/public/SetPassword/SetPassword';
import NewTicket from 'components/renderings/secure/Ticket/NewTicket/NewTicket';
import EditTicket from 'components/renderings/secure/Ticket/EditTicket/EditTicket';
import MyTicketList from 'components/renderings/secure/Ticket/MyTicket/MyTicketList';
import ResetPassword from 'components/renderings/public/ResetPassword/ResetPassword';
import ChangePassword from 'components/renderings/secure/ChangePassword/ChangePassword';
import ForgotPassword from 'components/renderings/public/ForgotPassword/ForgotPassword';
import AssignedTicketList from 'components/renderings/secure/Ticket/AssignedTicket/AssignedTicketList';
import NotificationList from 'components/renderings/secure/Notifications/NotificationList/NotificationList';

const App: React.FC = () => {
  return (
    <Suspense>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route
            path="/login"
            element={
              <UnAuthGuard>
                <Login />
              </UnAuthGuard>
            }
          />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/verify_otp" element={<VerifyOtp />} />
          <Route path="/reset_password" element={<ResetPassword />} />
          <Route path="/set_password" element={<SetPassword />} />
        </Route>
        <Route element={<Auth />}>
          <Route path="/" element={<Main />}>
            <Route index element={<Dashboard />} />
            <Route path="/ticket/new_ticket" element={<NewTicket />} />
            <Route
              path="/ticket/assigned_tickets"
              element={<AssignedTicketList />}
            />
            <Route
              path="/ticket/assigned_tickets/edit/:ticketId"
              element={<EditTicket />}
            />
            <Route path="/ticket/my_tickets" element={<MyTicketList />} />
            <Route
              path="/ticket/my_tickets/edit/:ticketId"
              element={<EditTicket />}
            />
            <Route
              path="/ticket/assigned_tickets/edit/:ticketId"
              element={<EditTicket />}
            />
            <Route
              index
              path="/users"
              element={
                <RoleGuard
                  allowedUserRoles={[ROLE.SUPER_ADMIN, ROLE.SUPERVISOR]}
                >
                  <UsersList />
                </RoleGuard>
              }
            />
            <Route
              path="/users/new_user"
              element={
                <RoleGuard
                  allowedUserRoles={[ROLE.SUPER_ADMIN, ROLE.SUPERVISOR]}
                >
                  <NewUser />
                </RoleGuard>
              }
            />
            <Route
              path="/users/edit/:userId"
              element={
                <RoleGuard
                  allowedUserRoles={[ROLE.SUPER_ADMIN, ROLE.SUPERVISOR]}
                >
                  <EditUser />
                </RoleGuard>
              }
            />
            <Route
              path="/reports"
              element={
                <RoleGuard
                  allowedUserRoles={[ROLE.SUPER_ADMIN, ROLE.SUPERVISOR]}
                >
                  <Reports />
                </RoleGuard>
              }
            />
            <Route path="/notifications" element={<NotificationList />} />
            <Route path="profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="change_password" element={<ChangePassword />} />
            <Route path="/notifications" element={<NotificationList />} />
          </Route>
        </Route>
      </Routes>
      <Toaster />
    </Suspense>
  );
};
export default App;
