import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { OTP_LENGTH } from 'helpers/constants/fieldLengths';
import { matchIsNumber } from 'utils/utils';

const OtpInput: React.FC<OtpInputProps> = ({ name, error }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <>
            <MuiOtpInput
              {...field}
              length={OTP_LENGTH}
              TextFieldsProps={{ placeholder: '-' }}
              validateChar={matchIsNumber}
            />
            {error ? (
              <span className="text-red-carnation text-xs mt-1 font-lato-light">
                {error}
              </span>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default memo(OtpInput);
