import { Controller, useFormContext } from 'react-hook-form';
import SimpleMdeReact, { SimpleMDEReactProps } from 'react-simplemde-editor';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { marked } from 'marked';
import EasyMDE from 'easymde';
import 'easymde/dist/easymde.min.css';

import { cn } from 'utils/utils';
import './MarkdownEditor.css';

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  name,
  className,
  placeholder,
  isDisabled,
  initialPreview,
}) => {
  const { formState, control } = useFormContext();
  const { errors } = formState;
  const [simpleMdeInstance, setMdeInstance] = useState<EasyMDE | null>(null);

  useEffect(() => {
    if (!simpleMdeInstance || !initialPreview) return;
    const instance: any = simpleMdeInstance;
    if (isDisabled) {
      try {
        const previewBtn = instance.toolbarElements
          .preview as HTMLButtonElement;
        previewBtn.setAttribute('disabled', 'disabled');
      } catch (e) {}
    }
    try {
      instance.togglePreview();
    } catch (e) {}
  }, [simpleMdeInstance, initialPreview, isDisabled]);

  const editorOptions: SimpleMDEReactProps['options'] = useMemo(() => {
    return {
      toolbar: [
        'bold',
        'italic',
        'heading',
        '|',
        'quote',
        'unordered-list',
        'ordered-list',
        '|',
        'preview',
      ],
      spellChecker: false,
      maxHeight: '150px',
      renderingConfig: {
        markedOptions: {
          gfm: false,
          breaks: true,
        },
      },
      previewRender: (plainText) => {
        return marked.parse(plainText) as string;
      },
    };
  }, []);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <SimpleMdeReact
            id={name}
            {...field}
            className={cn('focus:outline-none focus:shadow-outline', className)}
            placeholder={placeholder}
            options={editorOptions}
            getMdeInstance={(instance) => {
              setMdeInstance(instance);
            }}
          />
        )}
      />
      {errors && (
        <span className="text-red-carnation text-xs mt-1 font-lato-light">
          {errors[name]?.message as string}
        </span>
      )}
    </>
  );
};

export default memo(MarkdownEditor);
