import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { RootState } from 'state-management/store';
import './Comments.css';
import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoSendSharp } from 'react-icons/io5';
import {
  postComment,
  deleteComment,
  updateComment,
} from 'actions/comments/comments.actions';
import { axiosInstance } from 'helpers/interceptor/useInterceptor';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import { TOASTER_MESSAGES } from 'helpers/constants/messages';
import { ImCancelCircle } from 'react-icons/im';
import { TICKET_STATUS } from 'types/enums/tickets.enum';
import { ROLE } from 'types/enums/sidebarEnums';

const Comments = ({
  commentsList,
  ticketId,
  setTicketDetails,
  ticketStatus,
}: CommentsProps) => {
  const userDetails = useAppSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const [commentToEdit, setCommentToEdit] = useState<Comment | null>(null);
  const commentsListContainerRef = useRef<HTMLDivElement | null>(null);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<CommentInput>();
  const currentCommentValue = watch('comment');

  useEffect(() => {
    scrollCommentsToBottom();
  }, []);

  const postCommentData = async (data: { comment: string }) => {
    const trimmedValue = data.comment.trim();
    if (commentToEdit) {
      await dispatch(
        updateComment(axiosInstance, commentToEdit.id, trimmedValue, ticketId),
      )
        .then((response: Comment) => {
          setTicketDetails((prevState: TicketDetails) => {
            const newCommentsList = prevState.comments;
            const index = prevState.comments.findIndex(
              (comment) => comment.id === commentToEdit.id,
            );
            if (index !== -1) {
              newCommentsList[index] = response;
            }
            return { ...prevState, comments: newCommentsList };
          });
          notifySuccess(TOASTER_MESSAGES.COMMENT_UPDATE_SUCCESS);
          reset({ comment: '' });
          setCommentToEdit(null);
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        });
    } else {
      await dispatch(postComment(axiosInstance, ticketId, trimmedValue))
        .then((response: Comment) => {
          setTicketDetails((prevState: TicketDetails) => {
            return {
              ...prevState,
              comments: [...prevState.comments, response],
            };
          });
          scrollCommentsToBottom();
          notifySuccess(TOASTER_MESSAGES.COMMENT_CREATE_SUCCESS);
          reset({ comment: '' });
        })
        .catch((error: ErrorData) => notifyError(error));
    }
  };

  const removeComment = async (commentId: number) => {
    await dispatch(deleteComment(axiosInstance, commentId, ticketId))
      .then((response: { message: string }) => {
        setTicketDetails((prevState: TicketDetails) => {
          const newCommentsList = prevState.comments.filter(
            (comment: Comment) => {
              return comment.id !== commentId;
            },
          );
          return { ...prevState, comments: newCommentsList };
        });
        notifySuccess(response.message);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const editComment = (comment: Comment) => {
    setCommentToEdit(comment);
    reset({ comment: comment.message });
  };

  const cancelEdit = (): void => {
    setCommentToEdit(null);
    reset({ comment: '' });
  };

  const scrollCommentsToBottom = (): void => {
    setTimeout(() => {
      commentsListContainerRef.current?.scrollTo({
        top: commentsListContainerRef.current.scrollHeight,
      });
    });
  };

  const shouldDisableField = (): boolean => {
    return (
      ticketStatus === TICKET_STATUS.CLOSED && userDetails.role === ROLE.AGENT
    );
  };

  return (
    <>
      <h5 className="font-lato-bold mt-8">Comment History</h5>
      <div className="comment-list-container" ref={commentsListContainerRef}>
        <ul>
          {commentsList.length > 0 ? (
            commentsList.map((comment: Comment, index: number) => {
              const isCurrentUser = comment.created_by === userDetails.id;
              return (
                <li
                  className={`comment ${!comment.message.includes(' ') && 'break-all'}`}
                  key={index}
                >
                  <div className="flex justify-between">
                    <small className="font-lato-bold text-xs">
                      {isCurrentUser ? 'You' : comment.created_user}
                    </small>
                    {isCurrentUser && ticketStatus !== TICKET_STATUS.CLOSED && (
                      <div>
                        <button
                          className="mr-3"
                          type="button"
                          title="Edit Comment"
                          onClick={() => {
                            editComment(comment);
                          }}
                        >
                          <FiEdit size={14} color="navy" />
                        </button>
                        <button
                          type="button"
                          title="Delete Comment"
                          onClick={() => {
                            removeComment(comment.id);
                          }}
                        >
                          <RiDeleteBinLine size={14} color="maroon" />
                        </button>
                      </div>
                    )}
                  </div>
                  <small className="text-xs font-lato-regular mt-1">
                    {comment.message}
                  </small>
                  <div className="flex justify-between mt-1">
                    {comment.is_edited && (
                      <span className="text-xs italic">(Edited)</span>
                    )}
                    <small className="text-[10px] ml-auto font-lato-regular">
                      {moment(comment.created_at).format('lll')}
                    </small>
                  </div>
                </li>
              );
            })
          ) : (
            <small className="text-xs text-gray-rolling-stone">
              No comments to show
            </small>
          )}
        </ul>
      </div>
      <div className="flex flex-col relative mt-4 w-full">
        {commentToEdit && (
          <div className="bg-gray-200 rounded-t-md p-1.5 text-xs flex">
            <small className="text-xs">
              <span className="font-lato-bold">Edit comment: </span>
              {commentToEdit.message.length < 20
                ? commentToEdit.message
                : `${commentToEdit.message.slice(0, 20)}...`}
            </small>
            <button
              className="ml-auto"
              type="button"
              onClick={cancelEdit}
              title="Cancel"
            >
              <ImCancelCircle />
            </button>
          </div>
        )}
        <textarea
          {...register('comment', {
            required: { value: true, message: 'Please type some comment' },
            validate: {
              notWhitespace: (value) => {
                return (
                  value.trim() !== '' ||
                  'Comment cannot be empty or whitespace only.'
                );
              },
            },
          })}
          className="new-ticket-input focus:outline-none focus:shadow-outline comment-input"
          placeholder="Add your comment. Press Ctrl+Enter to add."
          disabled={shouldDisableField()}
          onKeyDown={(event) => {
            if (event.ctrlKey && event.key === 'Enter') {
              handleSubmit(postCommentData)();
            }
          }}
        ></textarea>
        <button
          className="comment-button"
          type="button"
          title="Post Comment"
          onClick={handleSubmit(postCommentData)}
          disabled={
            shouldDisableField() ||
            (commentToEdit !== null &&
              commentToEdit?.message === currentCommentValue?.trim())
          }
        >
          <IoSendSharp size={20} />
        </button>
      </div>
      {errors.comment?.message && (
        <small className="text-xs text-red-carnation">
          {errors.comment.message}
        </small>
      )}
    </>
  );
};

export default React.memo(Comments);
