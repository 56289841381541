import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const INITIAL_USER_STATE: UserState = {
  id: null,
  role: '',
  role_id: null,
  kc_user_id: '',
  accessToken: '',
  refreshToken: '',
  resetPassToken: '',
  email: '',
  last_name: '',
  first_name: '',
  created_at: '',
  updated_at: '',
  notification_settings: {
    email_notification: false,
    in_app_notification: false,
    escalation_notification: false,
    sla_overdue_notification: false,
    customer_email_notification: false,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_USER_STATE,
  reducers: {
    setLoggedInUser(state: UserState, action: PayloadAction<UserState>) {
      return { ...state, ...action.payload };
    },
    addResetPassToken(state: UserState, action: PayloadAction<string>) {
      return { ...state, resetPassToken: action.payload };
    },
    updateNotificationSettingsState(
      state: UserState,
      action: PayloadAction<NotificationSettingsForm>,
    ) {
      return { ...state, notification_settings: action.payload };
    },
    clearStorage() {
      return INITIAL_USER_STATE;
    },
  },
});

export const {
  clearStorage,
  setLoggedInUser,
  addResetPassToken,
  updateNotificationSettingsState,
} = authSlice.actions;
export default authSlice.reducer;
