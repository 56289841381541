import moment from 'moment';
import { ColDef, ValueGetterParams } from 'ag-grid-community';

export const CUSTOMER_TICKETS_LIST_HEADERS: ColDef<CustomerTicket>[] = [
  { headerName: 'Subject', field: 'subject', resizable: false },
  {
    headerName: 'Description',
    field: 'description',
    resizable: false,
    tooltipField: 'description',
    minWidth: 300,
    valueGetter: (args: ValueGetterParams) =>
      args.data.description.length < 30
        ? args.data.description
        : `${args.data.description.slice(0, 30)}...`,
  },
  { headerName: 'Status', field: 'status', resizable: false },
  { headerName: 'Priority', field: 'priority', resizable: false },
  {
    headerName: 'Due Date',
    field: 'due_date',
    resizable: false,
    valueGetter: (args: ValueGetterParams) =>
      moment(args.data.due_date).format('DD/MM/YYYY'),
  },
  {
    headerName: 'Created Date',
    field: 'created_at',
    resizable: false,
    valueGetter: (args: ValueGetterParams) =>
      moment(args.data.created_at).format('lll'),
  },
];
