import {
  ColDef,
  ValueGetterParams,
  ICellRendererParams,
} from 'ag-grid-community';
import { IoMdAdd } from 'react-icons/io';
import { FaRegEdit } from 'react-icons/fa';
import { CiFilter } from 'react-icons/ci';
import { MdFilterListAlt } from 'react-icons/md';
import { AgGridReact } from 'ag-grid-react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import {
  setCurrentPage,
  setSearchQuery,
  setPerPageCount,
} from 'state-management/myTicketsList/myTicketsListSlice';
import '../Tickets.css';
import { getHighlightedRow } from 'utils/utils';
import { ROLE } from 'types/enums/sidebarEnums';
import { RootState } from 'state-management/store';
import Search from 'components/elements/Search/Search';
import { GRID_COLUMN_DEF } from 'helpers/constants/agGrid';
import { MY_TICKET_LIST_HEADERS } from './MyTicketListHeaders';
import { useMainContext } from 'helpers/providers/MainContext';
import NoRowsOverlay from 'components/components/NoRowsOverlay';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import Pagination from 'components/elements/Pagination/Pagination';
import { deleteTicket, fetchMyTickets } from 'actions/tickets/tickets.actions';
import DeleteModal from 'components/components/DeleteModal';
import TicketsFilter from 'components/elements/TicketsFilter/TicketsFilter';

const MyTicketList: React.FC = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const MainContext = useMainContext();
  const [myTickets, setMyTickets] = useState<MY_TICKETS_LIST_RESPONSE[]>();
  const defaultColDef: ColDef = useMemo(() => {
    return { filter: false, suppressMovable: true };
  }, []);
  const [pagination, setPagination] = useState<Pagination>();
  const { currentPage, perPageCount, searchQuery } = useAppSelector(
    (state: RootState) => state.myTicketsList,
  );
  const role = useAppSelector((state: RootState) => state.auth.role);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<number>();
  const [isTicketDeleting, setIsTicketDeleting] = useState(false);
  const [filterFormData, setFilterFormData] = useState<FilterTicketForm>({
    ticket_id: '',
    subject: '',
    assignee: null,
    assignee_type: null,
    due_date: '',
    status: {
      name: 'All',
      id: '',
    },
  });
  const [isFilterFormEmpty, setIsFilterFormEmpty] = useState(true);

  const closeModal = (): void => {
    setOpenModal(false);
  };

  const ticketDelete = () => {
    if (!ticketId) return;
    setIsTicketDeleting(true);
    dispatch(deleteTicket(axiosInstance, ticketId))
      .then((response) => {
        notifySuccess(response.message);
        myTicketsFetch();
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setModalOpen(false);
        setIsTicketDeleting(false);
      });
  };

  const deleteCancel = (): void => {
    setModalOpen(false);
  };

  const openDeleteModal = (id: number) => {
    setTicketId(id);
    setModalOpen(true);
  };

  useEffect(() => {
    MainContext.setPageHeaderName(
      role === ROLE.SUPER_ADMIN
        ? 'All Tickets'
        : role === ROLE.SUPERVISOR
          ? 'Agent Tickets'
          : 'My Tickets',
    );
    myTicketsFetch();
  }, [currentPage, perPageCount, searchQuery]);

  const myTicketsFetch = () => {
    const myTicketParams = {
      page: currentPage,
      per_page: perPageCount,
      q: searchQuery,
      filterFormValues: {
        ...filterFormData,
        assignee_type: filterFormData.assignee_type?.name,
        status: filterFormData.status?.id,
        assignee: filterFormData.assignee?.name.split(' ')[0],
      },
    };
    dispatch(fetchMyTickets(axiosInstance, myTicketParams))
      .then((response) => {
        setMyTickets(response.tickets);
        setPagination(response.pagination);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const myTicketsFetchByFilter = (filterData: FilterTicketForm) => {
    const myTicketParams = {
      page: currentPage,
      per_page: perPageCount,
      q: searchQuery,
      filterFormValues: {
        ...filterData,
        assignee_type: filterData.assignee_type?.name,
        status: filterData.status?.id,
        assignee: filterData.assignee?.name.split(' ')[0],
      },
    };
    dispatch(fetchMyTickets(axiosInstance, myTicketParams))
      .then((response) => {
        setMyTickets(response.tickets);
        setPagination(response.pagination);
        setOpenModal(false);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    dispatch(setCurrentPage(selected + 1));
  };

  const handlePerPageChange = (perPage: number) => {
    dispatch(setPerPageCount(perPage));
  };

  const handleEdit = (id: number): void => {
    navigate(`edit/${id}`);
  };

  const setToCurrentPage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const onSearch = useCallback(
    (value: string) => {
      dispatch(setSearchQuery(value));
      dispatch(setCurrentPage(1));
    },
    [dispatch],
  );

  const MyTicketListActionRenderer = (ticket: ICellRendererParams) => {
    return (
      <div className="flex">
        <button
          title="Edit Ticket"
          type="button"
          className="icon-style pl-0"
          onClick={() => {
            handleEdit(ticket.data.id);
          }}
        >
          <FaRegEdit className="w-5 h-5 text-gray-iconColor" />
        </button>
        {(role === ROLE.SUPER_ADMIN || role === ROLE.SUPERVISOR) && (
          <button
            title="Delete Ticket"
            type="button"
            className="icon-style"
            onClick={() => openDeleteModal(ticket.data.id)}
          >
            <FaRegTrashAlt className="w-5 h-5 text-gray-iconColor" />
          </button>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="p-4 md:px-10">
        <div className="flex flex-col items-start lg:flex-row lg:items-center lg:justify-between">
          <div className="flex flex-row items-center w-full">
            <Search onSearch={onSearch} searchQuery={searchQuery} />
            <div
              title="Filter"
              className="filter-icon"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              {isFilterFormEmpty ? (
                <CiFilter fill="black" className="w-7 h-7" />
              ) : (
                <MdFilterListAlt className="w-7 h-7" />
              )}
            </div>
          </div>
          <button
            className="submit-button flex items-center mb-4 lg:mb-0"
            title="Add New Ticket"
            onClick={() => {
              navigate('/ticket/new_ticket');
            }}
          >
            <span className="pr-1">
              <IoMdAdd size={15} />
            </span>
            Add Ticket
          </button>
        </div>
        <div
          className={'ag-theme-quartz'}
          style={{ width: '100%', height: '390px' }}
        >
          <AgGridReact
            rowData={myTickets}
            getRowClass={getHighlightedRow}
            noRowsOverlayComponentParams={{
              message: 'No Tickets available',
            }}
            noRowsOverlayComponent={NoRowsOverlay}
            reactiveCustomComponents={true}
            columnDefs={[
              {
                width: 85,
                headerName: 'S.No',
                valueGetter: (params: ValueGetterParams) =>
                  pagination!.start_at + params!.node!.rowIndex!,
                resizable: false,
                sortable: false,
                maxWidth: GRID_COLUMN_DEF.sNo.maxWidth,
              },
              ...MY_TICKET_LIST_HEADERS,
            ]}
            defaultColDef={defaultColDef}
            components={{
              actionsListRenderer: MyTicketListActionRenderer,
            }}
            suppressCellFocus={true}
          />
        </div>
        {pagination && (
          <Pagination
            pagination={pagination}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            handlePerPageChange={handlePerPageChange}
          />
        )}
        <DeleteModal
          openModal={modalOpen}
          isLoading={isTicketDeleting}
          cancelDeletion={deleteCancel}
          confirmDeletion={ticketDelete}
          shouldDisable={isTicketDeleting}
          description="Are you sure want to delete the ticket?"
        />

        <Modal
          center
          open={openModal}
          onClose={closeModal}
          showCloseIcon={false}
          animationDuration={300}
          closeOnOverlayClick={false}
          classNames={{
            modalAnimationIn: 'customEnterModalAnimation',
            overlayAnimationIn: 'customEnterOverlayAnimation',
          }}
        >
          <TicketsFilter
            closeModal={closeModal}
            ticketsFetchByFilter={myTicketsFetchByFilter}
            filterFormData={filterFormData}
            setFilterFormData={setFilterFormData}
            setIsFilterFormEmpty={setIsFilterFormEmpty}
            setCurrentPage={setToCurrentPage}
          />
        </Modal>
      </div>
    </>
  );
};
export default MyTicketList;
