import * as ExcelJS from 'exceljs';

export const STATUS = [
  { name: 'Open', id: 'Open' },
  { name: 'In Progress', id: 'In Progress' },
  { name: 'Closed', id: 'Closed' },
];

export const ASSIGNEE_TYPE_LIST = [
  { name: 'Group', id: 'Group' },
  { name: 'User', id: 'User' },
];

export const CREATED_VIA = 'Website';

export const MAX_NOTIFICATION_COUNT = 99;

export const MAX_FILES = 5;

export const UI_DISPLAY_LENGTHS = {
  LIST_SUBJECT: 20,
  DETAILS_SUBJECT: 35,
};

export const TICKET_REPORT_HEADERS = [
  'S.No',
  'Ticket ID',
  'Subject',
  'Status',
  'Due Date',
  'Assignee type',
  'Assignee',
  'Category',
  'Customer',
  'Priority',
  'Created by',
];

export const borderStyle = {
  style: 'thin',
} as ExcelJS.Border;

export const BORDER_STYLE = {
  top: borderStyle,
  bottom: borderStyle,
  left: borderStyle,
  right: borderStyle,
} as ExcelJS.Borders;

export const TICKET_REPORT_COLUMN_WIDTHS = [
  { width: 10 },
  { width: 18 },
  { width: 30 },
  { width: 25 },
  { width: 25 },
  { width: 25 },
  { width: 25 },
  { width: 25 },
  { width: 25 },
  { width: 25 },
  { width: 25 },
];

export const TABLE_HEADERS_STYLE = {
  border: BORDER_STYLE,
  fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffa7c7e7' } },
  font: { bold: true },
  alignment: { wrapText: true, horizontal: 'left' },
} as ExcelJS.Style;

export const PIE_CHART_COLORS = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#ff7300',
  '#ff4500',
  '#87cefa',
  '#da70d6',
  '#ff69b4',
  '#32cd32',
  '#6a5acd',
];

export const PRIORITIES_COLORS = [
  '#003f5c',
  '#ff6361',
  '#82ca9d',
  '#87cefa',
  '#ffa600',
];
