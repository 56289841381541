import { z } from 'zod';
import { ERROR_MESSAGES, REQUIRED_MESSAGES } from 'helpers/constants/messages';

export const schema = z.object({
  email: z
    .string()
    .trim()
    .min(1, { message: REQUIRED_MESSAGES.EMAIL })
    .email({ message: ERROR_MESSAGES.INVALID_EMAIL }),
});
