import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { logInUser } from 'state-management/auth';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import GradientButton from 'components/elements/GradientButton/GradientButton';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import { TOASTER_MESSAGES } from 'helpers/constants/messages';
import { setSidebarItems } from 'state-management/sidebar/sidebarSlice';
import { checkSideNavRoles, handleSpaceKeyDown } from 'utils/utils';
import { RootState } from 'state-management/store';
import Label from 'components/elements/Label/Label';
import Input from 'components/elements/Input/Input';
import { schema } from './schema';
import './Login.css';

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm<LoginForm>({
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const sidebarItems = useAppSelector(
    (state: RootState) => state.sidebar.sidebarItems,
  );

  const onSubmit = (loginFormData: LoginForm) => {
    setIsLoggingIn(true);
    dispatch(logInUser(axiosInstance, loginFormData))
      .then((response) => {
        dispatch(
          setSidebarItems(checkSideNavRoles(sidebarItems, response.data.role)),
        );
        notifySuccess(TOASTER_MESSAGES.LOGIN_SUCCESS);
        navigate('/');
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsLoggingIn(false);
      });
  };

  return (
    <div className="login-card">
      <h3 className="login-header">Sign in</h3>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data: LoginForm) => onSubmit(data))}>
          <div className="flex flex-col">
            <div className="mb-8 mt-4">
              <div className="input-group-wrapper">
                <Label name="email" label="Email" className="input-label" />
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="login-input"
                  error={errors.email?.message}
                  onKeyDown={handleSpaceKeyDown}
                />
              </div>
            </div>
            <div className="input-group-wrapper">
              <Label name="password" label="Password" className="input-label" />
              <Input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                className="login-input"
                error={errors.password?.message}
                contentAfter={
                  <button
                    className="input-show-password-btn"
                    type="button"
                    tabIndex={-1}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    title="Show/Hide Password"
                  >
                    {!showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                }
                onKeyDown={handleSpaceKeyDown}
              />
            </div>
            <div className="ml-auto mt-2">
              <button
                className="login-forgot"
                type="button"
                onClick={() => {
                  navigate('/forgot_password');
                }}
                title="Forgot Password"
              >
                Forgot password ?
              </button>
            </div>
            <GradientButton
              type="submit"
              className="login-button login-button-text"
              buttonText={isLoggingIn ? 'Logging In' : 'Log In'}
              isLoading={isLoggingIn}
              title="Login"
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Login;
