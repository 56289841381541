import { IoMdAdd } from 'react-icons/io';
import Modal from 'react-responsive-modal';
import React, { useEffect, useState } from 'react';

import Group from './Group';
import AddGroup from './AddGroup';
import DeleteModal from 'components/components/DeleteModal';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import Pagination from 'components/elements/Pagination/Pagination';
import { deleteGroup, getGroups } from 'actions/SLA/groups.actions';

const Groups: React.FC = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [groupsList, setGroupsList] = useState<Groups | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [isopenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const closeModal = (): void => {
    setOpenModal(false);
    setSelectedGroup(null);
    setIsOpenDeleteModal(false);
  };

  const fetchGroups = (perPage?: number, currentPage?: number): void => {
    setIsLoading(true);
    dispatch(getGroups(axiosInstance, currentPage, perPage))
      .then((response) => {
        setGroupsList(response);
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => setIsLoading(false));
  };

  const removeGroup = (): void => {
    setIsDeleting(true);
    dispatch(deleteGroup(axiosInstance, selectedGroup?.id || 0))
      .then((response) => {
        fetchGroups(
          groupsList?.pagination.per_page,
          groupsList?.pagination.current_page,
        );
        closeModal();
        notifySuccess(response.message);
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const handlePageChange = ({ selected }: { selected: number }): void => {
    fetchGroups(groupsList?.pagination?.per_page, selected + 1);
  };

  const handlePerPageChange = (perPage: number): void => {
    fetchGroups(perPage);
  };

  const openDeleteModal = (group: Group) => {
    setSelectedGroup(group);
    setIsOpenDeleteModal(true);
  };

  const cancelDeletion = () => {
    setSelectedGroup(null);
    setIsOpenDeleteModal(false);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <div className="border py-3 rounded-md shadow-md">
      <div className="flex justify-between gap-6 pb-2 pl-3 border-b-2">
        <div>
          <h5 className="font-semibold text-lg">Groups</h5>
        </div>
        <button
          title="Add group"
          className="submit-button flex self-center min-w-24 mr-3 outline-none"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          <span className="pr-1">
            <IoMdAdd size={15} />
          </span>
          Add Group
        </button>
      </div>
      <div className="h-[40vh] overflow-y-auto pl-3">
        {!isLoading ? (
          groupsList?.groups.map((group: Group) => (
            <Group
              group={group}
              key={group.id}
              setOpenModal={setOpenModal}
              openDeleteModal={openDeleteModal}
              setSelectedGroup={setSelectedGroup}
            />
          ))
        ) : (
          <div className="h-[40vh] flex items-center justify-center">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-black mt-10"></div>
          </div>
        )}
        {groupsList &&
          (groupsList.pagination.total_count > 10 ||
            groupsList.pagination.total_pages > 1) && (
            <Pagination
              pagination={groupsList.pagination}
              handlePageChange={handlePageChange}
              handlePerPageChange={handlePerPageChange}
              currentPage={groupsList.pagination.current_page}
            />
          )}
        {!isLoading && (groupsList?.groups.length === 0 || !groupsList) && (
          <div className="h-[15vh] flex justify-center items-center">
            <small className="text-gray-rolling-stone">No Groups to show</small>
          </div>
        )}
      </div>
      <Modal
        center
        open={openModal}
        onClose={closeModal}
        showCloseIcon={false}
        animationDuration={300}
        closeOnOverlayClick={false}
        classNames={{
          modalAnimationIn: 'customEnterModalAnimation',
          overlayAnimationIn: 'customEnterOverlayAnimation',
        }}
      >
        <AddGroup
          groupsList={groupsList}
          closeModal={closeModal}
          fetchGroups={fetchGroups}
          selectedGroup={selectedGroup}
        />
      </Modal>
      <DeleteModal
        isLoading={isDeleting}
        openModal={isopenDeleteModal}
        confirmDeletion={removeGroup}
        cancelDeletion={cancelDeletion}
        description="Are you sure want to delete the group?"
      />
    </div>
  );
};

export default Groups;
