export const ERROR_MESSAGES = {
  INVALID_EMAIL: 'Enter a valid email address',
  MAX_FILES: 'Maximum file limit reached. Please select up to 5 files',
  MOBILE: 'Enter a valid mobile number',
  NAME: 'Enter a valid name',
  FIRST_NAME: 'Enter a valid first name',
  LAST_NAME: 'Enter a valid last name',
};

export const TOASTER_MESSAGES = {
  LOGIN_SUCCESS: 'Logged in successfully',
  TICKET_UPDATE_SUCCESS: 'Ticket updated successfully',
  COMMENT_CREATE_SUCCESS: 'Comment added successfully',
  COMMENT_UPDATE_SUCCESS: 'Comment updated successfully',
  USER_UPDATE_SUCCESS: 'User updated successfully',
  TICKET_CREATION_SUCCESS: 'Ticket created successfully',
  MARK_AS_READ_SUCCESS: 'Notification marked as read successfully',
  PASSWORD_CHANGE_SUCCESS: 'Password changed successfully',
};

export const NO_NOTIFICATION_AVAILABLE = 'No notifications are available';

export const PASSWORD_VALIDATION_MESSAGES = {
  UPPER_CASE: 'Password should have at least one upper case letter',
  LOWER_CASE: 'Password should have at least one lower case letter',
  SPECIAL_CHARACTER: 'Password should have at least one special character',
  NUMBER: 'Password should have at lease one number',
  CURRENT_AND_NEW_PASSWORD:
    'Current password and New password should not be the same',
  PASSWORD_MISMATCH: 'New password and password confirmation should match',
  NEW_PASSWORD_MAX_LENGTH: 'Password cannot exceed 25 characters',
  NEW_PASSWORD_MIN_LENGTH: 'Password must be at least 6 characters long',
};

export const REQUIRED_MESSAGES = {
  EMAIL: 'Email is required',
  PASSWORD: 'Password is required',
  CURRENT_PASSWORD: 'Current password is required',
  CONFIRM_PASSWORD: 'Confirm password is required',
  CUSTOMER_NAME: 'Name is required',
  MOBILE: 'Mobile is required',
  SUBJECT: 'Subject is required',
  DESCRIPTION: 'Description is required',
  STATUS: 'Status is required',
  PRIORITY: 'Priority is required',
  CATEGORY: 'Category is required',
  DUE_DATE: 'Due date is required',
  ASSIGNEE_TYPE: 'Assignee type is required',
  ASSIGNEE: 'Assignee is required',
  ATTACHMENT: 'Attachment is required',
  FIRST_NAME: 'First name is required',
  LAST_NAME: 'Last name is required',
  ROLE: 'Role is required',
  OTP: 'OTP is required',
  FROM_DATE: 'From Date is required',
  TO_DATE: 'To Date is required',
  SUPERVISOR: 'Supervisor is required',
};
