import moment from 'moment';
import { IoMdClose } from 'react-icons/io';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import {
  STATUS,
  MAX_FILES,
  ASSIGNEE_TYPE_LIST,
  UI_DISPLAY_LENGTHS,
} from 'helpers/constants/tickets';
import {
  fetchGroups,
  fetchUsersIdsAndNames,
} from 'actions/users/users.actions';
import {
  fetchCategory,
  updateMyTicket,
  updateAssignedTicket,
  fetchMyTicketDetails,
  fetchAssignedTicketDetails,
  fetchPrioritiesIdsAndNames,
} from 'actions/tickets/tickets.actions';
import {
  TICKET_TYPE,
  ASSIGNEE_TYPE,
  TICKET_STATUS,
} from 'types/enums/tickets.enum';
import { TOASTER_MESSAGES } from 'helpers/constants/messages';
import {
  preventPasting,
  handleNameKeyDown,
  handleSpaceKeyDown,
  handleMobileKeyDown,
} from 'utils/utils';
import './EditTicket.css';
import Comments from '../Comments/Comments';
import editIcon from 'assets/icons/edit.svg';
import { getEditTicketSchema } from './schema';
import Input from 'components/elements/Input/Input';
import Label from 'components/elements/Label/Label';
import Button from 'components/elements/Button/Button';
import TicketHistory from '../TicketHistory/TicketHistory';
import { useMainContext } from 'helpers/providers/MainContext';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import FileUpload from 'components/elements/FileUpload/FileUpload';
import { axiosInstance } from 'helpers/interceptor/useInterceptor';
import SelectInput from 'components/elements/SelectInput/SelectInput';
import { TICKET_FIELDS_LENGTH } from 'helpers/constants/fieldLengths';
import MarkdownEditor from 'components/elements/MarkdownEditor/MarkdownEditor';
import { store } from 'state-management/store';
import { ROLE } from 'types/enums/sidebarEnums';

const EditTicket = () => {
  const [maxFilesCount, setMaxFilesCount] = useState<number>(0);
  const [newFilesToBeAdded, setNewFiledToBeAdded] = useState<File[]>([]);

  const schema = getEditTicketSchema(maxFilesCount);

  const methods = useForm<UpdateTicketPayload>({
    defaultValues: {
      name: '',
      email: '',
      mobile: '',
      subject: '',
      status: null,
      due_date: '',
      assignee: null,
      attachment: [],
      category: null,
      priority: null,
      description: '',
      assignee_type: null,
    },
    resolver: zodResolver(schema),
  });
  const {
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const MainContext = useMainContext();
  const { ticketId = '' } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const ticketType = location.pathname.split('/')[2];
  const [ticketDetails, setTicketDetails] = useState<TicketDetails | null>(
    null,
  );
  const [fileIdsToBeRemoved, setFileIdsToBeRemoved] = useState<number[]>([]);
  const [priorities, setPriorities] = useState<SelectOption[] | null>(null);
  const [categoryList, setCategoryList] = useState<SelectOption[] | null>(null);
  const [assigneeList, setAssigneeList] = useState<SelectOption[] | null>(null);
  const [initialAssigneeValue, setInitialAssigneeValue] =
    useState<SelectOption | null>(null);
  const [initialCategoryValue, setInitialCategoryValue] =
    useState<SelectOption | null>(null);
  const [initialPriorityValue, setInitialPriorityValue] =
    useState<SelectOption | null>(null);
  const ticketStatus = ticketDetails?.status;
  const currentUserRole = store.getState().auth.role;
  const assigneeType = methods.watch('assignee_type');
  const fieldsToOmit: string[] = ['assignee', 'category', 'priority'];
  const filteredDirtyFields = Object.entries(methods.formState.dirtyFields)
    .filter(([key]) => !fieldsToOmit.includes(key))
    .reduce<Record<string, any>>((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  const isFormDirty = JSON.stringify(filteredDirtyFields) !== '{}';
  const [fileAttachments, setFileAttachments] = useState<
    FileAttachment[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const getCategory = useCallback(
    (categoryId: number) => {
      return (
        categoryList?.find((category) => category.id === categoryId) ?? null
      );
    },
    [categoryList],
  );

  const getPriority = useCallback(
    (priorityId: number) => {
      return priorities?.find((priority) => priority.id === priorityId) ?? null;
    },
    [priorities],
  );

  const getAssignee = useCallback(
    (assigneeId: number, assigneeType: string) => {
      return (
        assigneeList?.find(
          (assignee) =>
            assignee.id === assigneeId &&
            ticketDetails?.assignee_type === assigneeType,
        ) || null
      );
    },
    [assigneeList, ticketDetails?.assignee_type],
  );

  useEffect(() => {
    MainContext.setPageHeaderName('Update Ticket');
    ticketType === TICKET_TYPE.ASSIGNED_TICKET
      ? fetchAssignedTicketDetail()
      : fetchMyTicketDetail();
    fetchCategoryData();
    fetchPriorities();
  }, [ticketId, ticketType]);

  useEffect(() => {
    if (assigneeType) {
      assigneeType.id === ASSIGNEE_TYPE.USER
        ? fetchUserData()
        : fetchGroupData();
    }
  }, [assigneeType]);

  useEffect(() => {
    if (!ticketDetails) return;
    const category = getCategory(ticketDetails?.category_id);
    const priority = getPriority(ticketDetails?.priority_id);

    setInitialCategoryValue(category);
    setInitialPriorityValue(priority);

    setValue('category', category);
    setValue('priority', priority);
  }, [
    categoryList,
    ticketDetails,
    priorities,
    getCategory,
    getPriority,
    setValue,
  ]);

  useEffect(() => {
    if (!assigneeType?.id || !ticketDetails) return;
    const assigneeValue = getAssignee(
      ticketDetails?.assignee.id as number,
      assigneeType?.id as string,
    );
    setInitialAssigneeValue(assigneeValue);
    setValue('assignee', assigneeValue);
  }, [assigneeList, ticketDetails, getAssignee, setValue]);

  useEffect(() => {
    const totalFilesCount =
      MAX_FILES -
      (ticketDetails?.file_attachments.length ?? 0) +
      fileIdsToBeRemoved.length;
    setMaxFilesCount(totalFilesCount);
  }, [ticketDetails, fileIdsToBeRemoved]);

  const fetchAssignedTicketDetail = () => {
    dispatch(fetchAssignedTicketDetails(axiosInstance, ticketId))
      .then((response: TicketDetails) => {
        setTicketDetails(response);
        setFileAttachments(response.file_attachments);
        patchFormValues(response);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchMyTicketDetail = () => {
    dispatch(fetchMyTicketDetails(axiosInstance, ticketId))
      .then((response: TicketDetails) => {
        setTicketDetails(response);
        setFileAttachments(response.file_attachments);
        patchFormValues(response);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleReset = (): void => {
    methods.reset();
    if (ticketDetails) {
      methods.reset({
        ...methods.formState.defaultValues,
        assignee: getAssignee(
          ticketDetails?.assignee.id as number,
          assigneeType?.id as string,
        ),
        category: getCategory(ticketDetails?.category_id),
        priority: getPriority(ticketDetails?.priority_id),
      });
      setNewFiledToBeAdded([]);
    }
    if (ticketDetails?.file_attachments) {
      setFileAttachments(ticketDetails?.file_attachments);
      setFileIdsToBeRemoved([]);
    }
  };

  const handleSubmit = (data: UpdateTicketPayload) => {
    let fileIDToBeDeleted: number[] = [];
    if (data?.attachment?.length !== 0 && fileAttachments?.length !== 0) {
      fileAttachments?.forEach((fA) => {
        data.attachment?.forEach((f) => {
          if (fA.file_name === f.name) {
            fileIDToBeDeleted.push(fA.id);
          }
        });
      });
    }
    if (!checkIsFormDirty() || newFilesToBeAdded.length !== 0) {
      ticketType === TICKET_TYPE.ASSIGNED_TICKET &&
      currentUserRole === ROLE.AGENT
        ? submitAssignedTicketData(data)
        : submitMyTicketData(data, fileIDToBeDeleted);
    }
  };

  const patchFormValues = (formData: TicketDetails): void => {
    const status =
      STATUS.find((status) => status.id === formData.status) ?? null;
    const assigneeType =
      ASSIGNEE_TYPE_LIST.find(
        (assigneeType) => assigneeType.id === formData.assignee_type,
      ) ?? null;
    const dueDate = new Date(formData.due_date).toISOString().split('T')[0];
    const priority =
      priorities?.find((priority) => priority.id === formData.priority_id) ??
      null;

    methods.reset({
      ...methods.formState.defaultValues,
      status: status,
      assignee_type: assigneeType,
      assignee: null,
      category: null,
      due_date: dueDate,
      priority: priority,
      name: formData.customer.name,
      email: formData.customer.email,
      mobile: formData.customer.mobile,
      description: formData.description,
      subject: formData.subject,
    });
  };

  const submitMyTicketData = (
    updateFormData: UpdateTicketPayload,
    fIdsToBeDeleted: number[],
  ) => {
    let fileIdsToBeDeleted = [...fileIdsToBeRemoved, ...fIdsToBeDeleted];
    setIsSubmitting(true);
    const payload = {
      subject: updateFormData.subject,
      description: updateFormData.description,
      priority_id: updateFormData.priority?.id,
      category_id: updateFormData.category?.id,
      due_date: new Date(updateFormData.due_date).toISOString(),
      assignee_type: updateFormData.assignee_type?.id,
      customer: {
        name: updateFormData.name,
        email: updateFormData.email,
        mobile: updateFormData.mobile,
      },
      status: updateFormData.status?.id,
      customer_id: ticketDetails?.customer_id,
      assigned_user_id: updateFormData.assignee?.id,
      assigned_group_id: updateFormData.assignee?.id,
      remove_file_ids: fileIdsToBeDeleted,
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(payload));
    if (updateFormData.attachment) {
      updateFormData.attachment.forEach((file: File, index: number) => {
        formData.append(`file${index + 1}`, file);
      });
    }
    dispatch(updateMyTicket(axiosInstance, ticketId, formData))
      .then(() => {
        notifySuccess(TOASTER_MESSAGES.TICKET_UPDATE_SUCCESS);
        navigate(`/ticket/${ticketType}`);
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => setIsSubmitting(false));
  };

  const submitAssignedTicketData = (formData: UpdateTicketPayload) => {
    setIsSubmitting(true);
    dispatch(
      updateAssignedTicket(axiosInstance, ticketId, {
        status: formData.status?.id,
      }),
    )
      .then(() => {
        navigate(`/ticket/${ticketType}`);
        notifySuccess(TOASTER_MESSAGES.TICKET_UPDATE_SUCCESS);
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => setIsSubmitting(false));
  };

  const fetchCategoryData = () => {
    dispatch(fetchCategory(axiosInstance))
      .then((response) => {
        setCategoryList(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchGroupData = () => {
    dispatch(fetchGroups(axiosInstance))
      .then((response) => {
        setAssigneeList(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchUserData = async () => {
    dispatch(fetchUsersIdsAndNames(axiosInstance))
      .then((response) => {
        setAssigneeList(response.data);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchPriorities = () => {
    dispatch(fetchPrioritiesIdsAndNames(axiosInstance))
      .then((response) => {
        setPriorities(response);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const downloadFile = (fileUrl: string): void => {
    const link = document.createElement('a');
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const removeFile = (fileId: number) => {
    setFileAttachments((prevState: FileAttachment[] | null) => {
      if (!prevState) return null;
      const filteredFileAttachments = prevState.filter(
        (file: FileAttachment) => {
          return file.id !== fileId;
        },
      );
      return [...filteredFileAttachments];
    });

    setFileIdsToBeRemoved((prevState: number[]) => {
      return [...prevState, fileId];
    });
  };

  const checkIsFormDirty = () => {
    return (
      !isFormDirty &&
      fileIdsToBeRemoved.length === 0 &&
      JSON.stringify(getValues()['category']) ===
        JSON.stringify(initialCategoryValue) &&
      JSON.stringify(getValues()['priority']) ===
        JSON.stringify(initialPriorityValue) &&
      JSON.stringify(getValues()['assignee']) ===
        JSON.stringify(initialAssigneeValue)
    );
  };

  const shouldDisableField = (): boolean => {
    return (
      (ticketStatus === TICKET_STATUS.CLOSED ||
        ticketStatus === TICKET_STATUS.IN_PROGRESS ||
        ticketType === TICKET_TYPE.ASSIGNED_TICKET) &&
      currentUserRole === ROLE.AGENT
    );
  };

  return (
    <div>
      {isLoading && (
        <div className="h-screen">
          <div className="animate-spin rounded-full h-14 w-14 border-t-2 border-b-2 border-black mt-20 m-auto"></div>
        </div>
      )}
      {ticketDetails && !isLoading ? (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit((data: UpdateTicketPayload) =>
              handleSubmit(data),
            )}
          >
            <div className="edit-ticket-container">
              <div className="ticket-details-container">
                <div className="flex justify-between mb-4">
                  <h5 className="font-lato-bold">
                    Ticket #{ticketDetails?.id}
                  </h5>
                  <img src={editIcon} alt="edit-icon" />
                </div>
                <SelectInput
                  name="status"
                  options={STATUS}
                  isDisabled={
                    (ticketType === TICKET_TYPE.MY_TICKET ||
                      ticketStatus === TICKET_STATUS.CLOSED) &&
                    currentUserRole === ROLE.AGENT
                  }
                />
                <hr className="mt-5 mb-6 border-t-2" />
                <div className="avatar-container xl:px-5">
                  <div className="assignee-avatar">
                    {ticketDetails?.assignee?.name.slice(0, 2).toUpperCase()}
                  </div>
                  <div>
                    <h5 className="font-lato-bold">
                      {ticketDetails?.assignee?.name}
                    </h5>
                    {ticketDetails?.assignee?.email && (
                      <small className="text-xs">
                        {ticketDetails?.assignee.email}
                      </small>
                    )}
                  </div>
                </div>
                <div className="flex flex-col">
                  <Label
                    name="assignee_type"
                    label="Assignee Type *"
                    className={'text-xs'}
                  />
                  <SelectInput
                    name="assignee_type"
                    options={ASSIGNEE_TYPE_LIST}
                    isDisabled={shouldDisableField()}
                  />
                </div>
                <div className="flex flex-col">
                  <Label
                    name="assignee"
                    label="Assignee *"
                    className="text-xs mt-5"
                  />
                  <SelectInput
                    name="assignee"
                    options={assigneeList ?? []}
                    isDisabled={shouldDisableField()}
                  />
                </div>
                <div className="flex flex-col">
                  <Label
                    name="category"
                    label="Category *"
                    className="text-xs mt-5"
                  />
                  <SelectInput
                    name="category"
                    options={categoryList ?? []}
                    isDisabled={shouldDisableField()}
                  />
                </div>
                <div className="flex flex-col">
                  <Label
                    name="priority"
                    label="Priority *"
                    className="text-xs mt-5"
                  />
                  <SelectInput
                    name="priority"
                    options={priorities ?? []}
                    isDisabled={shouldDisableField()}
                  />
                </div>
                <div className="flex flex-col">
                  <Label
                    name="due_date"
                    label="Due Date *"
                    className="text-xs mt-5"
                  />
                  <Input
                    type="date"
                    name="due_date"
                    placeholder="Enter due date"
                    className="new-ticket-input"
                    min={new Date().toISOString().split('T')[0]}
                    disabled={shouldDisableField()}
                    error={errors.due_date?.message}
                  />
                </div>
                <TicketHistory
                  ticketHistories={ticketDetails.ticket_histories}
                />
              </div>

              <div className="issue-details-container">
                <div className="avatar-container border-b-2 pb-4">
                  <div className="customer-avatar">
                    {ticketDetails?.customer?.name?.slice(0, 2).toUpperCase()}
                  </div>
                  <div className="flex flex-col">
                    <h5
                      className="font-lato-bold text-lg cursor-pointer"
                      title={ticketDetails?.subject}
                    >
                      {ticketDetails?.subject.length >
                      UI_DISPLAY_LENGTHS.DETAILS_SUBJECT
                        ? `${ticketDetails?.subject.slice(0, UI_DISPLAY_LENGTHS.DETAILS_SUBJECT)}...`
                        : ticketDetails?.subject}
                    </h5>
                    <small className="my-1">
                      {ticketDetails?.customer.email}
                    </small>
                    <small className="text-xs">
                      {moment(ticketDetails?.created_at ?? '').format('lll')}
                    </small>
                  </div>
                </div>
                <div className="lg:pr-[30%]">
                  <h5 className="font-lato-bold">Customer Details</h5>
                  <div className="flex flex-col">
                    <Label
                      name="name"
                      label="Name *"
                      className="text-xs mt-4"
                    />
                    <Input
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      maxLength={TICKET_FIELDS_LENGTH.NAME}
                      className="new-ticket-input"
                      disabled={shouldDisableField()}
                      error={errors.name?.message}
                      onKeyDown={handleNameKeyDown}
                      onPaste={preventPasting}
                    />
                  </div>
                  <div className="name-email-container">
                    <div className="flex flex-col">
                      <Label name="email" label="Email *" className="text-xs" />
                      <Input
                        type="email"
                        name="email"
                        maxLength={TICKET_FIELDS_LENGTH.EMAIL}
                        placeholder="Enter email"
                        className="new-ticket-input mb-5 lg:mb-0"
                        disabled={shouldDisableField()}
                        error={errors.email?.message}
                        onKeyDown={handleSpaceKeyDown}
                      />
                    </div>
                    <div className="flex flex-col">
                      <Label
                        name="mobile"
                        label="Mobile *"
                        className="text-xs"
                      />
                      <Input
                        type="tel"
                        name="mobile"
                        placeholder="Enter mobile number"
                        className="new-ticket-input"
                        maxLength={10}
                        disabled={shouldDisableField()}
                        error={errors.mobile?.message}
                        onKeyDown={handleMobileKeyDown}
                      />
                    </div>
                  </div>
                  <h5 className="font-lato-bold mt-10">Ticket Details</h5>
                  <div className="flex flex-col">
                    <Label
                      name="subject"
                      label="Subject *"
                      className="text-xs mt-4"
                    />
                    <Input
                      type="text"
                      name="subject"
                      placeholder="Enter subject"
                      maxLength={TICKET_FIELDS_LENGTH.SUBJECT}
                      className="new-ticket-input"
                      disabled={shouldDisableField()}
                      error={errors.subject?.message}
                    />
                  </div>
                  <div className="flex flex-col mt-4">
                    <Label
                      name="description"
                      label="Description *"
                      className="text-xs"
                    />
                    <MarkdownEditor
                      name="description"
                      placeholder="Enter your description"
                      className="new-ticket-input"
                      initialPreview={true}
                      isDisabled={shouldDisableField()}
                    />
                  </div>
                  {ticketType === TICKET_TYPE.MY_TICKET &&
                    ticketStatus === TICKET_STATUS.OPEN && (
                      <div className="flex flex-col z-0 mt-4">
                        <Label
                          name="attachment"
                          label="Attach Files"
                          className="font-lato-bold mb-2 text-xs"
                        />
                        <FileUpload
                          name="attachment"
                          placeholder="Add attachment"
                          isMultiple={true}
                          setNewFiledToBeAdded={setNewFiledToBeAdded}
                        />
                      </div>
                    )}
                  <div className="my-4">
                    <small className="font-lato-bold text-xs">
                      Attachments:{' '}
                    </small>
                    {fileAttachments && fileAttachments.length > 0 ? (
                      <ul className="attachments">
                        {fileAttachments.map(
                          (file: FileAttachment, index: number) => {
                            return (
                              <li key={index}>
                                <button
                                  type="button"
                                  title="Click to download file"
                                  onClick={() => {
                                    downloadFile(file.file_path);
                                  }}
                                >
                                  {file.file_name ?? `File ${index}`}
                                </button>
                                {((ticketType === TICKET_TYPE.MY_TICKET &&
                                  ticketStatus === TICKET_STATUS.OPEN) ||
                                  currentUserRole !== ROLE.AGENT) && (
                                  <button
                                    className="ml-1 hover:bg-red-200 rounded-sm"
                                    type="button"
                                    title="Remove File"
                                    onClick={() => {
                                      removeFile(file.id);
                                    }}
                                  >
                                    <IoMdClose size={15} color="maroon" />
                                  </button>
                                )}
                              </li>
                            );
                          },
                        )}
                      </ul>
                    ) : (
                      <small className="text-xs text-gray-rolling-stone">
                        No files found
                      </small>
                    )}
                  </div>
                  <Comments
                    commentsList={ticketDetails.comments}
                    ticketId={+ticketId}
                    setTicketDetails={setTicketDetails}
                    ticketStatus={ticketDetails.status}
                  />
                  <div className="button-container">
                    <Button
                      type="button"
                      buttonName="Cancel"
                      onClick={handleReset}
                      title="Cancel"
                      className="cancel-button"
                      shouldDisable={
                        (checkIsFormDirty() &&
                          newFilesToBeAdded.length === 0) ||
                        isSubmitting
                      }
                    />
                    <Button
                      type="submit"
                      buttonName="Save"
                      title="Submit"
                      isLoading={isLoading || isSubmitting}
                      className="submit-button"
                      shouldDisable={
                        (checkIsFormDirty() &&
                          newFilesToBeAdded.length === 0) ||
                        isSubmitting
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      ) : (
        !isLoading && (
          <div className="h-screen">
            <h4 className="font-lato-bold text-center mt-20">
              Ticket Not Found
            </h4>
          </div>
        )
      )}
    </div>
  );
};

export default EditTicket;
