import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: MenuNotificationListState = {
  event: '',
  data: {
    notifications: [],
    unread_notification_count: 0,
  },
};

export const menuNotificationListSlice = createSlice({
  name: 'menuNotificationList',
  initialState,
  reducers: {
    setUnreadNotificationCount: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        data: {
          ...state.data,
          unread_notification_count: action.payload,
        },
      };
    },
    setNotifications: (
      state,
      action: PayloadAction<MenuNotificationListState>,
    ) => {
      const { event, data } = action.payload;
      return {
        event: event,
        data: {
          notifications: data.notifications,
          unread_notification_count: data.unread_notification_count,
        },
      };
    },
    updateNotifications: (state, action: PayloadAction<SseNotification>) => {
      const { payload } = action;
      const updatedNotificationsData = [payload, ...state.data.notifications];
      return {
        event: state.event,
        data: {
          notifications: updatedNotificationsData,
          unread_notification_count: state.data.unread_notification_count + 1,
        },
      };
    },

    deleteNotification: (state, action: PayloadAction<number>) => {
      const { payload: id } = action;
      const filteredNotifications = state.data.notifications.filter(
        (notification) => notification.id !== id,
      );
      return {
        event: state.event,
        data: {
          notifications: filteredNotifications,
          unread_notification_count: state.data.unread_notification_count,
        },
      };
    },
    setReset: () => {
      return initialState;
    },
  },
});

export const {
  setNotifications,
  setUnreadNotificationCount,
  updateNotifications,
  deleteNotification,
  setReset,
} = menuNotificationListSlice.actions;

export default menuNotificationListSlice.reducer;
