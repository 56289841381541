import { useNavigate } from 'react-router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { IoArrowBack } from 'react-icons/io5';

import GradientButton from 'components/elements/GradientButton/GradientButton';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import { sendResetPasswordLink } from 'state-management/auth';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import Label from 'components/elements/Label/Label';
import Input from 'components/elements/Input/Input';
import { schema } from './schema';
import { handleSpaceKeyDown } from 'utils/utils';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<LoginForm>({
    resolver: zodResolver(schema),
  });
  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  const onSubmit = (forgotPasswordFormData: ForgotPasswordForm) => {
    setIsLoading(true);
    dispatch(sendResetPasswordLink(axiosInstance, forgotPasswordFormData))
      .then((res) => {
        notifySuccess(res.message);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login-card">
      <div className="flex items-center justify-center mb-4">
        <IoArrowBack
          size={20}
          onClick={() => {
            navigate('/login');
          }}
          className="cursor-pointer"
          title="Back"
        />
        <h4 className="login-header pl-3">Forgot Password</h4>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data: LoginForm) => onSubmit(data))}>
          <div className="input-group-wrapper">
            <Label name="email" label="Email" className="input-label" />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              className="login-input"
              error={errors.email?.message}
              onKeyDown={handleSpaceKeyDown}
            />
          </div>
          <GradientButton
            type="submit"
            className="login-button login-button-text"
            buttonText="Submit"
            isLoading={isLoading}
            title="Submit"
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default ForgotPassword;
