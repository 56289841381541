import moment from 'moment';
import * as ExcelJS from 'exceljs';
import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { RowClassParams } from 'ag-grid-community';

import {
  BORDER_STYLE,
  TABLE_HEADERS_STYLE,
  TICKET_REPORT_HEADERS,
  TICKET_REPORT_COLUMN_WIDTHS,
} from 'helpers/constants/tickets';
import { store } from 'state-management/store';
import { REGEX_PATTERNS } from 'helpers/constants/regexPattern';
import { PASSWORD_VALIDATION_MESSAGES } from 'helpers/constants/messages';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function checkSideNavRoles(
  sideBarItems: SidebarItem[],
  currentUserRole: string,
): SidebarItem[] {
  const filteredSidebarItems = sideBarItems.filter(
    (sidebarItem: SidebarItem) => {
      return sidebarItem.allowedUserRoles.includes(currentUserRole);
    },
  );

  return filteredSidebarItems.map((sidebarItem: SidebarItem) => {
    const filteredSubItems =
      sidebarItem.subItems?.filter((subItem: SidebarItem) =>
        subItem.allowedUserRoles.includes(currentUserRole),
      ) || [];

    const visibleSubItems = filteredSubItems.filter((subItem) => subItem.show);

    const result: SidebarItem = {
      ...sidebarItem,
      hasSubItems: filteredSubItems.length > 0,
      showSubItemsCount: visibleSubItems.length,
    };

    if (filteredSubItems.length > 0) {
      if (visibleSubItems.length > 0) {
        result.path = visibleSubItems[0].path;
      }
      result.subItems = filteredSubItems;
    }
    return result;
  });
}

export function validateNewPassword(
  value: string,
  formValues: ChangePassword,
): string | boolean {
  if (!RegExp(REGEX_PATTERNS.upper_case).test(value)) {
    return PASSWORD_VALIDATION_MESSAGES.UPPER_CASE;
  } else if (!RegExp(REGEX_PATTERNS.lower_case).test(value)) {
    return PASSWORD_VALIDATION_MESSAGES.LOWER_CASE;
  } else if (!RegExp(REGEX_PATTERNS.number).test(value)) {
    return PASSWORD_VALIDATION_MESSAGES.NUMBER;
  } else if (RegExp(REGEX_PATTERNS.special_character).test(value)) {
    return PASSWORD_VALIDATION_MESSAGES.SPECIAL_CHARACTER;
  } else if (value === formValues.current_password) {
    return PASSWORD_VALIDATION_MESSAGES.CURRENT_AND_NEW_PASSWORD;
  }
  return true;
}

export function validatePasswordConfirmation(
  value: string,
  formValues: ChangePassword,
): string | boolean {
  if (formValues.password !== value) {
    return PASSWORD_VALIDATION_MESSAGES.PASSWORD_MISMATCH;
  }
  return true;
}

export function debounce<T extends any[]>(
  fn: (...args: T) => void,
  time: number,
): (...args: T) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null;

  return function wrapper(...args: T) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  };
}

export function handleNameKeyDown(
  event: React.KeyboardEvent<HTMLInputElement>,
) {
  const isAlphabetic = REGEX_PATTERNS.name.test(event.key);
  if (!isAlphabetic) {
    event.preventDefault();
  }
}

export function handleMobileKeyDown(
  event: React.KeyboardEvent<HTMLInputElement>,
) {
  const isMobile = REGEX_PATTERNS.is_mobile.test(event.key);
  if (!isMobile) {
    event.preventDefault();
  }
}

export function handleSpaceKeyDown(
  event: React.KeyboardEvent<HTMLInputElement>,
) {
  const isSpace = event.key === ' ';
  if (isSpace) {
    event.preventDefault();
  }
}

export function matchIsNumber(text: string) {
  return !isNaN(Number(text));
}

export function preventPasting(event: React.ClipboardEvent<HTMLInputElement>) {
  event.preventDefault();
}

export function preventEmptySpaceSearch(
  event: React.KeyboardEvent<HTMLInputElement>,
) {
  const target = event.target as HTMLInputElement;
  if (event.code === 'Space' && target.value.trim() === '') {
    event.preventDefault();
  }
}

export function getHighlightedRow(params: RowClassParams) {
  const isSlaOverdueNotificationEnabled: boolean =
    store.getState().auth.notification_settings.sla_overdue_notification;
  if (params.data.overdue_priority_sla_flag && isSlaOverdueNotificationEnabled)
    return '!bg-red-200 animate-pulse';
}

export function generateTicketReport(ticketData: ReportData[]) {
  const workBook = new ExcelJS.Workbook();
  const workSheet = workBook.addWorksheet(`Ticket Report`);

  let headers = workSheet.addRow(TICKET_REPORT_HEADERS);
  headers.eachCell((cell) => {
    cell.style = TABLE_HEADERS_STYLE;
  });
  workSheet.columns = TICKET_REPORT_COLUMN_WIDTHS;
  ticketData.map((ticket, index) => {
    delete ticket.created_at;
    delete ticket.updated_at;
    ticket.due_date = moment(ticket.due_date).format('ll');
    const rowObject = [index + 1, ...Object.values(ticket)];
    let dataRow = workSheet.addRow(rowObject);
    dataRow.alignment = { wrapText: true, horizontal: 'left' };
    return dataRow.eachCell((cell) => (cell.border = BORDER_STYLE));
  });
  let row1 = workSheet.getRow(1);
  row1.eachCell((cell) => (cell.border = BORDER_STYLE));

  workBook.xlsx.writeBuffer().then((buffer) => {
    const data = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = `Ticket Report ${moment().format('lll')}`;
    link.click();
    link.remove();
  });
}

export function getIdsFromObjects(
  selectedOptions: SelectOption[] | null | undefined,
) {
  return (
    selectedOptions && selectedOptions.map((option: SelectOption) => option.id)
  );
}
