import { Controller, useFormContext } from 'react-hook-form';
import { MuiFileInput } from 'mui-file-input';
import { IoMdClose } from 'react-icons/io';
import React, { memo, useState, useEffect } from 'react';
import { cn } from 'utils/utils';
import './FileUpload.css';

interface FileProps {
  name: string;
  className?: string;
  isMultiple?: boolean;
  placeholder?: string;
  setNewFiledToBeAdded?: (files: File[]) => void;
}

const FileUpload: React.FC<FileProps> = ({
  name,
  className,
  isMultiple = false,
  placeholder,
  setNewFiledToBeAdded,
}) => {
  const { formState, control, setValue, watch } = useFormContext();
  const { errors } = formState;

  const watchedFiles = watch(name);

  useEffect(() => {
    if (!watchedFiles || watchedFiles.length === 0) {
      setFiles([]);
    }
  }, [watchedFiles]);

  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (newFiles: File[] | File | null) => {
    if (newFiles) {
      const updatedFiles = Array.isArray(newFiles) ? newFiles : [newFiles];

      let oldFiles = [...files];

      let updatedOldFiles = oldFiles.filter((f) => {
        let isPresent = false;
        updatedFiles.forEach((uf) => {
          if (uf.name === f.name) {
            isPresent = true;
            return;
          }
        });
        if (isPresent) {
          return false;
        }
        return true;
      });
      if (setNewFiledToBeAdded) {
        setNewFiledToBeAdded(
          isMultiple ? [...updatedOldFiles, ...updatedFiles] : updatedFiles,
        );
      }
      setFiles(
        isMultiple ? [...updatedOldFiles, ...updatedFiles] : updatedFiles,
      );
      setValue(
        name,
        isMultiple ? [...updatedOldFiles, ...updatedFiles] : updatedFiles,
      );
    } else {
      setFiles([]);
      if (setNewFiledToBeAdded) setNewFiledToBeAdded([]);
      setValue(name, []);
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    if (setNewFiledToBeAdded) setNewFiledToBeAdded(newFiles);
    setValue(name, newFiles);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiFileInput
            {...field}
            multiple={isMultiple}
            className={cn('focus:outline-none focus:shadow-outline', className)}
            placeholder={placeholder}
            onChange={(newFiles: File[] | File | null) => {
              handleFileChange(newFiles);
            }}
            inputProps={{
              accept:
                '.txt,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.png,.jpg,.jpeg',
            }}
          />
        )}
      />
      <div className="flex flex-wrap mt-2 gap-1">
        {files.map((file, index) => (
          <div
            key={index}
            className="flex items-center bg-gray-200 rounded p-1"
          >
            <span className="font-lato-semibold text-xs text-center mr-1">
              {file.name}
            </span>
            <button
              className="ml-1 hover:bg-red-200 rounded-sm"
              type="button"
              title="Remove"
              onClick={() => handleRemoveFile(index)}
            >
              <IoMdClose size={15} color="maroon" />
            </button>
          </div>
        ))}
      </div>
      {errors[name] && (
        <span className="text-red-carnation text-xs mt-1 font-lato-light">
          {errors[name]?.message as string}
        </span>
      )}
    </>
  );
};

export default memo(FileUpload);
