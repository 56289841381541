//The URL will be change. It's depends on server side

const devEnv = {
  baseURL: 'https://api.ts-dev.yavar.ai/',
};
const prodEnv = {
  baseURL: 'https://api.ts-dev.yavar.ai/',
};

const environment =
  process.env.NODE_ENV === 'production' ? { ...prodEnv } : { ...devEnv };

export default environment;
