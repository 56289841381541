import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: NotificationsListState = {
  currentPage: 1,
  perPageCount: 10,
  searchQuery: '',
};

export const notificationsListSlice = createSlice({
  name: 'notificationsList',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      return { ...state, currentPage: action.payload };
    },
    setPerPageCount: (state, action: PayloadAction<number>) => {
      return { ...state, perPageCount: action.payload };
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      return { ...state, searchQuery: action.payload };
    },
  },
});

export const { setCurrentPage, setPerPageCount, setSearchQuery } =
  notificationsListSlice.actions;

export default notificationsListSlice.reducer;
