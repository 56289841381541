import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import Input from 'components/elements/Input/Input';
import Button from 'components/elements/Button/Button';
import {
  fetchGroups,
  fetchRoles,
  fetchSuperVisors,
  fetchUser,
  updateUser,
} from 'actions/users/users.actions';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { TOASTER_MESSAGES } from 'helpers/constants/messages';
import Label from 'components/elements/Label/Label';
import { cn, handleNameKeyDown, handleSpaceKeyDown } from 'utils/utils';
import SelectInput from 'components/elements/SelectInput/SelectInput';
import { schema } from './schema';
import { useMainContext } from 'helpers/providers/MainContext';
import {
  TICKET_FIELDS_LENGTH,
  USER_FIELDS_LENGTH,
} from 'helpers/constants/fieldLengths';
import { ROLE } from 'types/enums/sidebarEnums';
import '../Users.css';

const EditUser: React.FC = () => {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();

  const navigate = useNavigate();
  const methods = useForm<UPDATE_USER_FORM>({
    defaultValues: {
      first_name: '',
      last_name: '',
      role_id: null,
      email: '',
      group_ids: null,
    },
    resolver: zodResolver(schema),
  });
  const [roles, setRoles] = useState<SelectOption[] | null>(null);
  const [userGroups, setUserGroups] = useState<SelectOption[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    formState: { errors },
    watch,
  } = methods;
  const MainContext = useMainContext();
  const isFormDirty = JSON.stringify(methods.formState.dirtyFields) !== '{}';
  const [supervisors, setSupervisors] = useState<SelectOption[] | null>(null);
  const selectedRole = watch('role_id');

  useEffect(() => {
    MainContext.setPageHeaderName('Update User');
    const rolesFetch = async () => {
      dispatch(fetchRoles(axiosInstance))
        .then((response) => {
          setRoles(response.data);
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        });
    };
    const groupsFetch = () => {
      dispatch(fetchGroups(axiosInstance))
        .then((response) => {
          setUserGroups(response.data);
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        });
    };
    const getSupervisors = () => {
      dispatch(fetchSuperVisors(axiosInstance))
        .then((response) => setSupervisors(response))
        .catch((error: ErrorData) => notifyError(error));
    };
    groupsFetch();
    rolesFetch();
    getSupervisors();
  }, [axiosInstance, dispatch]);

  useEffect(() => {
    if (roles && userGroups && supervisors) {
      fetchUserData();
    }
  }, [roles, userGroups, supervisors]);

  const fetchUserData = () => {
    dispatch(fetchUser(axiosInstance, Number(userId)))
      .then((response) => {
        const roleId = roles?.find(
          (role) => role.id === response.role.id,
        ) as SelectOption;
        const supervisorId = supervisors?.find(
          (supervisor) => supervisor.id === response.supervisor_id,
        ) as SelectOption;
        methods.reset({
          first_name: response.first_name,
          last_name: response.last_name,
          email: response.email,
          role_id: roleId,
          group_ids: response.groups,
          supervisor_id: supervisorId,
        });
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const userUpdate = (data: UPDATE_USER_FORM) => {
    if (isFormDirty) {
      setIsLoading(true);
      const groupIds = data.group_ids?.map((data: SelectOption) => data.id);
      const userData: UPDATE_USER = {
        ...data,
        role_id: data.role_id?.id as number,
        group_ids: groupIds as number[],
        supervisor_id: data.supervisor_id?.id as number,
      };
      if (groupIds?.length === 0) {
        delete userData['group_ids'];
      }
      if (selectedRole?.name !== ROLE.AGENT) {
        delete userData['supervisor_id'];
      }
      dispatch(updateUser(axiosInstance, Number(userId), userData))
        .then(() => {
          notifySuccess(TOASTER_MESSAGES.USER_UPDATE_SUCCESS);
          methods.reset();
          navigate('/users');
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleReset = () => {
    methods.reset();
  };

  useEffect(() => {
    if (selectedRole?.name !== ROLE.AGENT) {
      methods.unregister('supervisor_id');
    }
  }, [selectedRole]);

  return (
    <div className="user-form">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data: UPDATE_USER_FORM) =>
            userUpdate(data),
          )}
        >
          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label
                name="first_name"
                label="Firstname *"
                className={cn('text-xs')}
              />
              <Input
                type="text"
                name="first_name"
                placeholder="Enter first name"
                className="user-input"
                error={errors.first_name?.message}
                onKeyDown={handleNameKeyDown}
                maxLength={USER_FIELDS_LENGTH.FIRST_NAME}
              />
            </div>
            <div className="input-group-wrapper mt-2">
              <Label
                name="last_name"
                label="Lastname *"
                className={cn('text-xs')}
              />
              <Input
                type="text"
                name="last_name"
                placeholder="Enter last name"
                className="user-input"
                error={errors.last_name?.message}
                onKeyDown={handleNameKeyDown}
                maxLength={USER_FIELDS_LENGTH.LAST_NAME}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label name="email" label="Email *" className={cn('text-xs')} />
              <Input
                type="email"
                name="email"
                placeholder="Enter email"
                className="user-input"
                error={errors.email?.message}
                onKeyDown={handleSpaceKeyDown}
                maxLength={TICKET_FIELDS_LENGTH.EMAIL}
              />
            </div>
            <div className="input-group-wrapper mt-2">
              <Label
                name="group_ids"
                label="Groups"
                className={cn('text-xs')}
              />
              <SelectInput
                name="group_ids"
                options={userGroups ?? []}
                isMulti={true}
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label name="role_id" label="Role *" className={cn('text-xs')} />
              <SelectInput
                name="role_id"
                options={roles ?? []}
                isDisabled={true}
              />
            </div>
            {selectedRole?.name === ROLE.AGENT && (
              <div className="input-group-wrapper mt-2">
                <Label
                  name="supervisor_id"
                  label="Supervisor *"
                  className={cn('text-xs')}
                />
                <SelectInput
                  name="supervisor_id"
                  options={supervisors ?? []}
                  isDisabled={true}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-center sm:justify-end gap-4 mt-11">
              <Button
                type="button"
                buttonName="Cancel"
                className="cancel-button"
                onClick={handleReset}
                shouldDisable={!isFormDirty}
                title="Cancel"
              />
              <Button
                type="submit"
                buttonName="Update"
                className="submit-button"
                isLoading={isLoading}
                shouldDisable={isLoading || !isFormDirty}
                title="Submit"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default EditUser;
