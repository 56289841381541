import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  RowClassParams,
  RowClickedEvent,
  ValueGetterParams,
} from 'ag-grid-community';
import {
  fetchNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
} from 'actions/notifications/notifications.actions';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import Pagination from 'components/elements/Pagination/Pagination';
import { NOTIFICATIONS_LIST_HEADERS } from './NotificationListHeaders';
import {
  setCurrentPage,
  setPerPageCount,
  setSearchQuery,
} from 'state-management/notificationsList/notificationsListSlice';
import { RootState } from 'state-management/store';
import { TOASTER_MESSAGES } from 'helpers/constants/messages';
import Button from 'components/elements/Button/Button';
import { GRID_COLUMN_DEF } from 'helpers/constants/agGrid';
import {
  deleteNotification,
  setReset,
  setUnreadNotificationCount,
} from 'state-management/menuNotificationList/menuNotificationListSlice';
import { useMainContext } from 'helpers/providers/MainContext';
import Search from 'components/elements/Search/Search';
import NoRowsOverlay from 'components/components/NoRowsOverlay';

const NotificationList: React.FC = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const MainContext = useMainContext();
  const [notifications, setNotifications] = useState<Notification[]>();
  const [pagination, setPagination] = useState<Pagination>();
  const { currentPage, perPageCount, searchQuery } = useAppSelector(
    (state: RootState) => state.notificationsList,
  );
  const defaultColDef: ColDef = useMemo(() => {
    return { filter: false, suppressMovable: true };
  }, []);

  useEffect(() => {
    MainContext.setPageHeaderName('Notifications');
    getNotifications();
  }, [currentPage, perPageCount, searchQuery]);

  const getNotifications = () => {
    dispatch(
      fetchNotifications(axiosInstance, currentPage, perPageCount, searchQuery),
    )
      .then((response: NotificationListResponse) => {
        setNotifications(response.notifications);
        setPagination(response.pagination);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    dispatch(setCurrentPage(selected + 1));
  };

  const handlePerPageChange = (perPage: number) => {
    dispatch(setPerPageCount(perPage));
  };

  const handleRowClick = (event: RowClickedEvent) => {
    if (!event.data.read) {
      dispatch(markNotificationAsRead(axiosInstance, event.data.id))
        .then((response) => {
          notifySuccess(TOASTER_MESSAGES.MARK_AS_READ_SUCCESS);
          getNotifications();
          dispatch(deleteNotification(event.data.id));
          dispatch(
            setUnreadNotificationCount(response.unread_notification_count),
          );
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        });
    }
  };

  const markAllAsRead = () => {
    dispatch(markAllNotificationsAsRead(axiosInstance))
      .then((response: { message: string }) => {
        notifySuccess(response.message);
        getNotifications();
        dispatch(setReset());
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const getRowClass = (params: RowClassParams) => {
    if (!params.data.read) {
      return 'ag-blue-rows';
    }
  };

  const onSearch = useCallback(
    (value: string) => {
      dispatch(setSearchQuery(value));
      dispatch(setCurrentPage(1));
    },
    [dispatch],
  );

  return (
    <div className="px-4 md:px-10">
      <div className="flex flex-col md:flex-row md:items-center py-3">
        <Search
          onSearch={onSearch}
          searchQuery={searchQuery}
          className="mb-0"
        />
        <Button
          type="button"
          buttonName="Mark all as read"
          className="submit-button ml-auto mt-4 md:mt-0"
          onClick={markAllAsRead}
          title="Mark all as read"
        />
      </div>
      <div
        className={'ag-theme-quartz'}
        style={{ width: '100%', height: '390px' }}
      >
        <AgGridReact
          rowData={notifications}
          noRowsOverlayComponentParams={{
            message: 'No Notifications to show',
          }}
          noRowsOverlayComponent={NoRowsOverlay}
          reactiveCustomComponents={true}
          columnDefs={[
            {
              headerName: 'S.No',
              valueGetter: (params: ValueGetterParams) =>
                pagination!.start_at + params!.node!.rowIndex!,
              resizable: false,
              sortable: false,
              maxWidth: GRID_COLUMN_DEF.sNo.maxWidth,
            },
            ...NOTIFICATIONS_LIST_HEADERS,
          ]}
          defaultColDef={defaultColDef}
          suppressCellFocus={true}
          getRowClass={getRowClass}
          onRowClicked={handleRowClick}
        />
      </div>
      {pagination && (
        <Pagination
          pagination={pagination}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          handlePerPageChange={handlePerPageChange}
        />
      )}
    </div>
  );
};

export default NotificationList;
