import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import React, { memo, useEffect, useState } from 'react';

import { cn } from 'utils/utils';
import Label from 'components/elements/Label/Label';
import { IoClose } from 'react-icons/io5';
import Input from 'components/elements/Input/Input';
import Button from 'components/elements/Button/Button';
import { axiosInstance } from 'helpers/interceptor/useInterceptor';
import { MODAL_DELETE } from 'helpers/constants/modalContent';
import SelectInput from 'components/elements/SelectInput/SelectInput';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import { ASSIGNEE_TYPE } from 'types/enums/tickets.enum';
import { ASSIGNEE_TYPE_LIST } from 'helpers/constants/tickets';
import {
  fetchGroups,
  fetchUsersIdsAndNames,
} from 'actions/users/users.actions';

export const TICKETSTATUS = [
  { name: 'All', id: '' },
  { name: 'Open', id: 'Open' },
  { name: 'In Progress', id: 'In Progress' },
  { name: 'Closed', id: 'Closed' },
];

const TicketsFilter: React.FC<{
  closeModal: () => void;
  ticketsFetchByFilter: (filterData: FilterTicketForm) => void;
  filterFormData: FilterTicketForm;
  setFilterFormData: (filterData: FilterTicketForm) => void;
  setIsFilterFormEmpty: (flag: boolean) => void;
  setCurrentPage: (page: number) => void;
}> = ({
  closeModal,
  ticketsFetchByFilter,
  filterFormData,
  setFilterFormData,
  setIsFilterFormEmpty,
  setCurrentPage,
}) => {
  const methods = useForm<FilterTicketForm>({
    resolver: zodResolver(
      z.object({
        ticket_id: z.string().optional(),
        subject: z.string().optional(),
        assignee: z
          .object({
            name: z.string(),
            id: z.string().or(z.number()),
          })
          .nullable()
          .optional(),
        assignee_type: z
          .object({
            name: z.string(),
            id: z.string(),
          })
          .nullable()
          .optional(),
        due_date: z.string().optional(),
        status: z
          .object({
            name: z.string(),
            id: z.string(),
          })
          .nullable()
          .optional(),
      }),
    ),
  });

  const {
    formState,
    formState: { errors, defaultValues },
    setValue,
    getValues,
  } = methods;
  const dispatch = useAppDispatch();
  const assigneeType = methods.watch('assignee_type');
  const [assigneeList, setAssigneeList] = useState<SelectOption[] | null>(null);
  const isFormDirty = JSON.stringify(formState.dirtyFields) !== '{}';

  const formDefaultValues = {
    ticket_id: '',
    subject: '',
    assignee: null,
    assignee_type: null,
    due_date: '',
    status: { name: 'All', id: '' },
  };

  useEffect(() => {
    if (filterFormData) {
      setValue('ticket_id', filterFormData.ticket_id);
      setValue('subject', filterFormData.subject);
      setValue('assignee', filterFormData.assignee);
      setValue('assignee_type', filterFormData.assignee_type);
      setValue('due_date', filterFormData.due_date);
      setValue('status', filterFormData.status);
    }
  }, []);

  useEffect(() => {
    if (assigneeType) {
      assigneeType.id === ASSIGNEE_TYPE.USER
        ? fetchUserData()
        : fetchGroupData();
    }
  }, [assigneeType]);

  const fetchGroupData = () => {
    dispatch(fetchGroups(axiosInstance))
      .then((response) => {
        setAssigneeList(response.data);
        if (
          JSON.stringify(getValues()['assignee_type']) !==
          JSON.stringify(filterFormData.assignee_type)
        ) {
          setValue('assignee', null);
        }
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const fetchUserData = async () => {
    dispatch(fetchUsersIdsAndNames(axiosInstance))
      .then((response) => {
        setAssigneeList(response.data);
        if (
          JSON.stringify(getValues()['assignee_type']) !==
          JSON.stringify(filterFormData.assignee_type)
        ) {
          setValue('assignee', null);
        }
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const submitHandler = (formData: FilterTicketForm): void => {
    ticketsFetchByFilter(formData);
    if (JSON.stringify(defaultValues) === JSON.stringify(formData)) {
      setIsFilterFormEmpty(true);
    } else {
      setIsFilterFormEmpty(false);
    }
    setFilterFormData(formData);
    setCurrentPage(1);
  };

  const onReset = () => {
    setFilterFormData(formDefaultValues);
    setIsFilterFormEmpty(true);
    closeModal();
    if (JSON.stringify(formDefaultValues) !== JSON.stringify(filterFormData)) {
      ticketsFetchByFilter(formDefaultValues);
      setCurrentPage(1);
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-between  mb-4">
        <h4 className="text-xl font-lato-semibold">Filter Tickets</h4>
        <IoClose className="w-7 h-7 cursor-pointer" onClick={closeModal} />
      </div>
      <FormProvider {...methods}>
        <form
          className="w-[80vw] lg:w-[50vw] xl:w-[37vw]"
          onSubmit={methods.handleSubmit((data) => submitHandler(data))}
        >
          <div className="input-group-wrapper mt-2">
            <Label
              name="ticket_id"
              label="Ticket Id"
              className={cn('text-xs')}
            />
            <Input
              type="text"
              name="ticket_id"
              className="user-input"
              placeholder="Enter ticket id"
              error={errors.ticket_id?.message}
            />
          </div>
          <div className="input-group-wrapper mt-4">
            <Label name="subject" label="Subject" className={cn('text-xs')} />
            <Input
              type="text"
              name="subject"
              className="user-input"
              placeholder="Enter subject"
              error={errors.subject?.message}
            />
          </div>
          <div className="input-group-wrapper mt-4">
            <Label
              name="assignee_type"
              label="Assignee Type"
              className={'text-xs'}
            />
            <SelectInput name="assignee_type" options={ASSIGNEE_TYPE_LIST} />
          </div>
          <div className="input-group-wrapper mt-4">
            <Label name="assignee" label="Assignee" className={cn('text-xs')} />
            <SelectInput name="assignee" options={assigneeList ?? []} />
          </div>
          <div className="input-group-wrapper mt-4">
            <Label name="due_date" label="Due Date" className={cn('text-xs')} />
            <Input
              type="date"
              name="due_date"
              className="user-input"
              placeholder="Enter Due Date"
              error={errors.due_date?.message}
            />
          </div>
          <div className="input-group-wrapper mt-4">
            <Label name="status" label="Ticket Status" className={'text-xs'} />
            <SelectInput name="status" options={TICKETSTATUS} />
          </div>
          <div className="flex justify-center gap-10 mt-6">
            <Button
              type="button"
              title="Reset"
              onClick={onReset}
              className="cancel-button"
              buttonName={MODAL_DELETE.RESET}
            />
            <Button
              title="Filter"
              type="submit"
              buttonName="Filter"
              className="submit-button"
              shouldDisable={!isFormDirty}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(TicketsFilter);
