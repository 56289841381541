import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import './NotificationSettings.css';
import { store } from 'state-management/store';
import Button from 'components/elements/Button/Button';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import { updateNotificationSettingsState } from 'state-management/auth';
import { updateNotificationSettings } from 'actions/SLA/notificationSettings.actions';
import { ROLE } from 'types/enums/sidebarEnums';

const NotificationSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const { notification_settings, role } = store.getState().auth;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      email_notification: false,
      in_app_notification: false,
      escalation_notification: false,
      sla_overdue_notification: false,
      customer_email_notification: false,
    },
  });

  const onSubmit = (formData: NotificationSettingsForm): void => {
    setIsLoading(true);
    dispatch(updateNotificationSettings(axiosInstance, formData))
      .then(() => {
        notifySuccess('Notification settings updated successfully');
        dispatch(updateNotificationSettingsState(formData));
      })
      .catch((error: ErrorData) => notifyError(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    reset({
      customer_email_notification:
        notification_settings.customer_email_notification,
      email_notification: notification_settings.email_notification,
      in_app_notification: notification_settings.in_app_notification,
      escalation_notification: notification_settings.escalation_notification,
      sla_overdue_notification: notification_settings.sla_overdue_notification,
    });
  }, [notification_settings]);

  const isFormDirty = JSON.stringify(formState.dirtyFields) !== '{}';

  return (
    <div className="border p-3 rounded-md shadow-md h-full">
      <h5 className="font-semibold text-lg">Alerts & Notifications</h5>
      <form
        onSubmit={handleSubmit((data: NotificationSettingsForm) =>
          onSubmit(data),
        )}
        className="p-3"
      >
        <div className="checkbox-field">
          <label>Email Notifications</label>
          <label className="switch">
            <input type="checkbox" {...register('email_notification')} />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="checkbox-field">
          <label>In App Notifications</label>
          <label className="switch">
            <input type="checkbox" {...register('in_app_notification')} />
            <span className="slider round"></span>
          </label>
        </div>
        {(role === ROLE.SUPERVISOR || role === ROLE.SUPER_ADMIN) && (
          <div className="checkbox-field">
            <label>Escalation Notifications</label>
            <label className="switch">
              <input type="checkbox" {...register('escalation_notification')} />
              <span className="slider round"></span>
            </label>
          </div>
        )}
        <div className="checkbox-field">
          <label>SLA Overdue Notifications</label>
          <label className="switch">
            <input type="checkbox" {...register('sla_overdue_notification')} />
            <span className="slider round"></span>
          </label>
        </div>
        {role === ROLE.SUPER_ADMIN && (
          <div className="checkbox-field">
            <label>Customer Email Notifications</label>
            <label className="switch">
              <input
                type="checkbox"
                {...register('customer_email_notification')}
              />
              <span className="slider round"></span>
            </label>
          </div>
        )}
        <Button
          title="Save"
          type="submit"
          buttonName="Save"
          isLoading={isLoading}
          shouldDisable={!isFormDirty}
          className="submit-button ml-auto mt-10"
        />
      </form>
    </div>
  );
};

export default NotificationSettings;
