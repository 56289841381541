import { AxiosInstance } from 'axios';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const getCategories = (
  axiosInstance: AxiosInstance,
  page: number = 1,
  per_page: number = 10,
) => {
  return async () => {
    const data = async () => {
      const params: { [key: string]: string | number } = { page, per_page };
      return await axiosInstance.get(API_END_POINTS.categories, { params });
    };
    const result = await data();
    return result.data;
  };
};

export const updateCategory = (
  id: number,
  axiosInstance: AxiosInstance,
  formData: { name: string },
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `${API_END_POINTS.categories}/${id}`,
        formData,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const addCategory = (
  axiosInstance: AxiosInstance,
  formData: { name: string },
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.post(API_END_POINTS.categories, formData);
    };
    const result = await data();
    return result.data;
  };
};

export const deleteCategory = (axiosInstance: AxiosInstance, id: number) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.delete(`${API_END_POINTS.categories}/${id}`);
    };
    const result = await data();
    return result.data;
  };
};
