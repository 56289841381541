export const API_END_POINTS = {
  fetch_roles: '/v1/roles/ids_and_roles',
  fetch_groups: '/v1/groups/ids_and_names',
  invitations: '/v1/invitations',
  update_user: 'v1/users',
  fetch_my_ticket_details: '/v1/tickets/',
  fetch_assigned_ticket_details: '/v1/tickets/assigned/',
  fetch_users: '/v1/users',
  fetch_category: '/v1/categories/ids_and_names',
  fetch_user_ids_names: '/v1/users/ids_and_names',
  updateMyTicket: '/v1/tickets/',
  fetch_assigned_tickets: '/v1/tickets/assigned',
  add_tickets: '/v1/tickets',
  fetch_my_tickets: '/v1/tickets',
  fetch_notifications: '/v1/notifications',
  mark_notification_as_read: '/v1/notifications/mark_as_read/',
  mark_all_notifications_as_read: '/v1/notifications/mark_all_as_read',
  change_password: '/v1/passwords/change',
  send_otp: '/v1/send_otp',
  resend_otp: '/v1/resend_otp',
  verify_otp: '/v1/verify_otp',
  reset_pass: '/v1/passwords/reset',
  fetch_fifteen_days_count: '/v1/dashboards/tickets/fifteen_days_count',
  fetch_categories_count: '/v1/dashboards/tickets/categories_count',
  fetch_status_count: '/v1/dashboards/tickets/status_count',
  sse: 'v1/sse',
  deleteTicket: '/v1/tickets/',
  accept_invitation: 'v1/invitations/accept',
  send_reset_password_link: '/v1/passwords/send-reset-password-link',
  reset_password: 'v1/passwords/reset',
  priority: 'v1/priorities',
  fetch_priorities: 'v1/priorities/ids_and_names',
  groups: 'v1/groups',
  categories: 'v1/categories',
  reports: 'v1/reports/generate',
  supervisors: 'v1/users/ids_and_supervisors',
  priorities_count: 'v1/dashboards/tickets/priorities_count',
  resend_invite: 'v1/invitations/resend',
  overdue_tickets: 'v1/dashboards/tickets/sla_overdue',
};
