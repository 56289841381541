import { AxiosInstance } from 'axios';
import { store } from 'state-management/store';

export const updateNotificationSettings = (
  axiosInstance: AxiosInstance,
  notificationSettingsForm: NotificationSettingsForm,
) => {
  return async () => {
    const data = async () => {
      const currentUserId = store.getState().auth.id;
      return await axiosInstance.put(
        `v1/users/${currentUserId}/notifications/settings`,
        notificationSettingsForm,
      );
    };
    const result = await data();
    return result.data;
  };
};
