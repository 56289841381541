import { AxiosInstance } from 'axios';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const fetchNotifications = (
  axiosInstance: AxiosInstance,
  page: number,
  per_page: number,
  q?: string,
) => {
  return async () => {
    const data = async () => {
      const params: { [key: string]: string | number } = {
        page,
        per_page,
      };
      if (q) params.q = q;
      return await axiosInstance.get(API_END_POINTS.fetch_notifications, {
        params,
      });
    };
    const result = await data();
    return result.data;
  };
};

export const markNotificationAsRead = (
  axiosInstance: AxiosInstance,
  notificationId: number,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `${API_END_POINTS.mark_notification_as_read}${notificationId}`,
      );
    };
    const result = await data();
    return result.data;
  };
};

export const markAllNotificationsAsRead = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `${API_END_POINTS.mark_all_notifications_as_read}`,
      );
    };
    const result = await data();
    return result.data;
  };
};
