import { Dispatch } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { clearStorage, setLoggedInUser, addResetPassToken } from './authSlice';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const logInUser = (axiosInstance: AxiosInstance, params: LoginForm) => {
  return async (dispatch: Dispatch) => {
    const logIn = async () => {
      return await axiosInstance.post('/v1/login', params);
    };
    const result = await logIn();
    const accessToken = result.headers.authorization;
    const refreshToken = result.headers.refresh_token;
    const userDetails = result.data;
    userDetails.accessToken = accessToken;
    userDetails.refreshToken = refreshToken;
    dispatch(setLoggedInUser(userDetails));
    return result;
  };
};

export const logOutUser = (
  axiosInstance: AxiosInstance,
  refreshToken: string,
) => {
  return async (dispatch: Dispatch) => {
    const headers = {
      refresh_token: refreshToken,
    };
    const logOut = async () => {
      return axiosInstance.delete('/v1/logout', { headers });
    };
    const result = await logOut();
    dispatch(clearStorage());
    return result;
  };
};

export const sendOtp = (axiosInstance: AxiosInstance, params: SendOTPForm) => {
  return async (dispatch: Dispatch) => {
    const getOtp = async () => {
      return axiosInstance.post(API_END_POINTS.send_otp, params);
    };
    const result = await getOtp();
    const token = result.headers.authorization;
    dispatch(addResetPassToken(token));
    return result.data;
  };
};

export const resentOtp = (
  axiosInstance: AxiosInstance,
  resetPassToken: string,
) => {
  return async (dispatch: Dispatch) => {
    const getOtp = async () => {
      const headers = {
        Authorization: resetPassToken,
      };
      return axiosInstance.post(API_END_POINTS.resend_otp, {}, { headers });
    };
    const result = await getOtp();
    const token = result.headers.authorization;
    dispatch(addResetPassToken(token));
    return result.data;
  };
};

export const verifyOtp = (
  axiosInstance: AxiosInstance,
  params: VerifyOTPForm,
  resetPassToken: string,
) => {
  return async (dispatch: Dispatch) => {
    const otpVerify = async () => {
      const headers = {
        Authorization: resetPassToken,
      };
      return axiosInstance.post(API_END_POINTS.verify_otp, params, { headers });
    };
    const result = await otpVerify();
    const token = result.headers.authorization;
    dispatch(addResetPassToken(token));
    return result.data;
  };
};

export const resetPassword = (
  axiosInstance: AxiosInstance,
  params: ResetPasswordForm,
  resetPassToken: string,
) => {
  return async (dispatch: Dispatch) => {
    const resetPassword = () => {
      const headers = {
        Authorization: resetPassToken,
      };
      return axiosInstance.put(API_END_POINTS.reset_pass, params, { headers });
    };
    const result = await resetPassword();
    dispatch(clearStorage());
    return result.data;
  };
};

export const setPassword = (
  invitationToken: string,
  params: ResetPasswordForm,
  axiosInstance: AxiosInstance,
) => {
  return async () => {
    const setPassword = () => {
      const headers = {
        Authorization: `Bearer ${invitationToken}`,
      };
      return axiosInstance.post(API_END_POINTS.accept_invitation, params, {
        headers,
        avoidAccessToken: true,
      });
    };
    const result = await setPassword();
    return result.data;
  };
};

export const sendResetPasswordLink = (
  axiosInstance: AxiosInstance,
  params: ForgotPasswordForm,
) => {
  return async () => {
    const getResetPasswordLink = async () => {
      return axiosInstance.post(
        API_END_POINTS.send_reset_password_link,
        params,
      );
    };
    const result = await getResetPasswordLink();
    return result.data;
  };
};

export const setResetPassword = (
  resetToken: string,
  params: ResetPasswordForm,
  axiosInstance: AxiosInstance,
) => {
  return async () => {
    const setResetPassword = () => {
      const headers = {
        Authorization: `Bearer ${resetToken}`,
      };
      return axiosInstance.put(API_END_POINTS.reset_password, params, {
        headers,
        avoidAccessToken: true,
      });
    };
    const result = await setResetPassword();
    return result.data;
  };
};
