import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import React, { Dispatch, SetStateAction } from 'react';

const Group: React.FC<{
  group: Group;
  openDeleteModal: (category: Group) => void;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setSelectedGroup: Dispatch<SetStateAction<Group | null>>;
}> = ({ group, setOpenModal, setSelectedGroup, openDeleteModal }) => {
  return (
    <div className="border py-1.5 px-3 mt-2 rounded-md shadow-md flex justify-between items-center mr-2.5">
      <div>
        <h5 className="font-semibold">{group.name}</h5>
      </div>
      <div>
        <button
          type="button"
          title="Edit group"
          className="mr-5 outline-none"
          onClick={() => {
            setOpenModal(true);
            setSelectedGroup(group);
          }}
        >
          <FiEdit size={16} color="navy" />
        </button>
        <button
          type="button"
          title="Delete group"
          className="outline-none"
          onClick={() => openDeleteModal(group)}
        >
          <RiDeleteBinLine size={16} color="maroon" />
        </button>
      </div>
    </div>
  );
};

export default Group;
