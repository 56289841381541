import {
  PayloadAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import storage from 'redux-persist/lib/storage';
import sidebarReducer from './sidebar/sidebarSlice';
import usersListReducer from './usersList/usersListSlice';
import { persistReducer, persistStore } from 'redux-persist';
import myTicketsListReducer from './myTicketsList/myTicketsListSlice';
import assignedTicketReducer from './assignedTicket/assignedTicketSlice';
import customerTicketsReducer from './customerTickets/customerTicketsSlice';
import notificationsListReducer from './notificationsList/notificationsListSlice';
import menuNotificationListReducer from './menuNotificationList/menuNotificationListSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: sidebarReducer,
  usersList: usersListReducer,
  myTicketsList: myTicketsListReducer,
  assignedTicket: assignedTicketReducer,
  customerTickets: customerTicketsReducer,
  notificationsList: notificationsListReducer,
  menuNotificationList: menuNotificationListReducer,
});

const appReducer = (state: any, action: PayloadAction<any>) => {
  if (action.type === 'auth/clearStorage') {
    Object.keys(state).forEach((key) => {
      storage.removeItem(`persist:${key}`);
    });
    state = undefined;
  }
  return rootReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'sidebar',
    'usersList',
    'myTicketsList',
    'assignedTicket',
    'customerTickets',
    'notificationsList',
    'menuNotificationList',
  ],
  blacklist: [],
};

const persistedReducers = persistReducer(persistConfig, appReducer);

export const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
