import { Outlet } from 'react-router-dom';
import './AuthLayout.css';
import img from 'assets/images/login.png';
import copper from 'assets/images/copper.png';

const AuthLayout = () => {
  return (
    <>
      <div className="auth-layout-container">
        <div className="img-container hidden xl:block">
          <img src={img} alt="Copper login" className="copper-img" />
        </div>
        <div className="right-container">
          <img src={copper} alt="Copper logo" className="copper-logo" />
          <div className="outlet-container">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
