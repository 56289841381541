import React, { memo } from 'react';
import Modal from 'react-responsive-modal';
import Button from 'components/elements/Button/Button';

const DeleteModal: React.FC<{
  openModal: boolean;
  isLoading?: boolean;
  description: string;
  shouldDisable?: boolean;
  cancelDeletion: () => void;
  confirmDeletion: () => void;
}> = ({
  openModal,
  isLoading,
  description,
  shouldDisable,
  cancelDeletion,
  confirmDeletion,
}) => {
  return (
    <Modal
      center
      open={openModal}
      showCloseIcon={false}
      animationDuration={300}
      onClose={cancelDeletion}
      closeOnOverlayClick={false}
      classNames={{
        modalAnimationIn: 'customEnterModalAnimation',
        overlayAnimationIn: 'customEnterOverlayAnimation',
      }}
    >
      <div className="px-7 py-4">
        <p className="font-lato-bold">{description}</p>
        <div className="flex justify-around mt-7">
          <Button
            title="Cancel"
            buttonName="Cancel"
            onClick={cancelDeletion}
            className="cancel-button"
          />
          <Button
            title="Delete"
            buttonName="Delete"
            isLoading={isLoading}
            onClick={confirmDeletion}
            shouldDisable={shouldDisable}
            className="submit-button !bg-red-400 hover:!bg-red-500"
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(DeleteModal);
