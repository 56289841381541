import { z } from 'zod';
import {
  REQUIRED_MESSAGES,
  PASSWORD_VALIDATION_MESSAGES,
} from 'helpers/constants/messages';
import { PASSWORD_REGEX_PATTERNS } from 'helpers/constants/regexPattern';
import { PASSWORD_LENGTH } from 'helpers/constants/fieldLengths';

export const schema = z
  .object({
    current_password: z
      .string()
      .trim()
      .min(1, { message: REQUIRED_MESSAGES.CURRENT_PASSWORD }),
    password: z
      .string()
      .trim()
      .min(PASSWORD_LENGTH.MINIMUM, {
        message: PASSWORD_VALIDATION_MESSAGES.NEW_PASSWORD_MIN_LENGTH,
      })
      .max(PASSWORD_LENGTH.MAXIMUM, {
        message: PASSWORD_VALIDATION_MESSAGES.NEW_PASSWORD_MAX_LENGTH,
      })
      .refine((value) => PASSWORD_REGEX_PATTERNS.upper_case.test(value), {
        message: PASSWORD_VALIDATION_MESSAGES.UPPER_CASE,
      })
      .refine((value) => PASSWORD_REGEX_PATTERNS.lower_case.test(value), {
        message: PASSWORD_VALIDATION_MESSAGES.LOWER_CASE,
      })
      .refine((value) => PASSWORD_REGEX_PATTERNS.number.test(value), {
        message: PASSWORD_VALIDATION_MESSAGES.NUMBER,
      })
      .refine(
        (value) => PASSWORD_REGEX_PATTERNS.special_character.test(value),
        {
          message: PASSWORD_VALIDATION_MESSAGES.SPECIAL_CHARACTER,
        },
      ),
    confirm_password: z
      .string()
      .trim()
      .min(1, { message: REQUIRED_MESSAGES.CONFIRM_PASSWORD }),
  })
  .refine((data) => data.current_password !== data.password, {
    message: PASSWORD_VALIDATION_MESSAGES.CURRENT_AND_NEW_PASSWORD,
    path: ['password'],
  })
  .refine((data) => data.password === data.confirm_password, {
    message: PASSWORD_VALIDATION_MESSAGES.PASSWORD_MISMATCH,
    path: ['confirm_password'],
  });
