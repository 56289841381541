import moment from 'moment';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from 'actions/notifications/notifications.actions';
import { axiosInstance } from 'helpers/interceptor/useInterceptor';
import {
  deleteNotification,
  setReset,
  setUnreadNotificationCount,
} from 'state-management/menuNotificationList/menuNotificationListSlice';
import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import { RootState } from 'state-management/store';
import { notifyError } from 'utils/ToastMessage';
import { NO_NOTIFICATION_AVAILABLE } from 'helpers/constants/messages';
import './MenuNotificationList.css';

const MenuNotificationList: React.FC = () => {
  const dispatch = useAppDispatch();
  const handleNotification = () => {
    dispatch(markAllNotificationsAsRead(axiosInstance))
      .then(() => {
        dispatch(setReset());
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };
  return (
    <div className="menu-notification-list-container">
      <div className="flex justify-between items-center mb-3">
        <span className="mb-1 text-sm font-semibold text-gray-900">
          Notifications
        </span>
        <button
          type="button"
          onClick={handleNotification}
          className="menu-mark-all-read-btn"
          title="Mark all as read"
        >
          Mark all as read
        </button>
      </div>
      <div className="flex flex-col gap-2 items-center">
        <MenuNotifications />
        <Link
          to={'/notifications'}
          className="menu-view-all-btn"
          title="View All Notifications"
        >
          View All
        </Link>
      </div>
    </div>
  );
};

const MenuNotifications: React.FC = () => {
  const { notifications, unread_notification_count } = useAppSelector(
    (state: RootState) => state.menuNotificationList.data,
  );
  const auth = useAppSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleNotification = (
    id: number,
    ticket_id: number,
    created_by: number,
  ) => {
    dispatch(markNotificationAsRead(axiosInstance, id))
      .then((response) => {
        if (created_by === auth.id) {
          navigate(`/ticket/my_tickets/edit/${ticket_id}`);
        } else {
          navigate(`/ticket/assigned_tickets/edit/${ticket_id}`);
        }
        dispatch(deleteNotification(id));
        dispatch(
          setUnreadNotificationCount(response.unread_notification_count),
        );
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };
  return (
    <>
      {unread_notification_count > 0 ? (
        notifications.slice(0, 5).map((notification: SseNotification) => (
          <button
            key={notification.id}
            onClick={() =>
              handleNotification(
                notification.id,
                notification.ticket_id,
                notification.ticket_created_by,
              )
            }
            className="text-sm text-left w-full p-3 hover:bg-gray-50 shadow-sm"
          >
            <div className="text-sm font-lato-regular">
              {notification.description}
            </div>
            <span className="text-xs font-lato-medium text-gray-900">
              {moment(notification.created_at).fromNow()}
            </span>
          </button>
        ))
      ) : (
        <div className="text-sm text-center w-full p-4 shadow-sm">
          <div className="text-sm font-lato-regular">
            {NO_NOTIFICATION_AVAILABLE}
          </div>
        </div>
      )}
    </>
  );
};

export default MenuNotificationList;
