import { z } from 'zod';
import { REQUIRED_MESSAGES, ERROR_MESSAGES } from 'helpers/constants/messages';
import { REGEX_PATTERNS } from 'helpers/constants/regexPattern';
import { MAX_FILES } from 'helpers/constants/tickets';

const customerDetailsSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: REQUIRED_MESSAGES.CUSTOMER_NAME })
    .refine((value) => REGEX_PATTERNS.name.test(value), {
      message: ERROR_MESSAGES.NAME,
    }),
  email: z
    .string()
    .trim()
    .min(1, { message: REQUIRED_MESSAGES.EMAIL })
    .refine((value) => REGEX_PATTERNS.email.test(value), {
      message: ERROR_MESSAGES.INVALID_EMAIL,
    }),
  mobile: z
    .string()
    .trim()
    .min(10, { message: REQUIRED_MESSAGES.MOBILE })
    .max(10)
    .refine((value) => REGEX_PATTERNS.mobile.test(value), {
      message: ERROR_MESSAGES.MOBILE,
    }),
});

export const schema = z.object({
  subject: z.string().trim().min(1, { message: REQUIRED_MESSAGES.SUBJECT }),
  description: z
    .string()
    .trim()
    .min(1, { message: REQUIRED_MESSAGES.DESCRIPTION }),
  status: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .nullable()
    .refine((data) => data !== null, { message: REQUIRED_MESSAGES.STATUS }),
  priority: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .nullable()
    .refine((data) => data !== null, { message: REQUIRED_MESSAGES.PRIORITY }),
  category: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .nullable()
    .refine((data) => data !== null, { message: REQUIRED_MESSAGES.CATEGORY }),
  due_date: z.string().trim().min(1, { message: REQUIRED_MESSAGES.DUE_DATE }),
  assignee_type: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .nullable()
    .refine((data) => data !== null, {
      message: REQUIRED_MESSAGES.ASSIGNEE_TYPE,
    }),
  assignee: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .nullable()
    .refine((data) => data !== null, { message: REQUIRED_MESSAGES.ASSIGNEE }),
  attachment: z
    .array(z.any())
    .max(MAX_FILES, { message: ERROR_MESSAGES.MAX_FILES })
    .optional(),
  customer: customerDetailsSchema,
  is_escalated: z.boolean().optional(),
});
