import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

const SelectInput: React.FC<SelectProps> = ({
  options,
  name,
  isMulti,
  isDisabled,
}) => {
  const {
    control,
    setValue,
    formState: { errors },
    register,
    setError,
  } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Select
              options={options}
              className="basic-single"
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 4,
                color: '#00000',
                colors: {
                  ...theme.colors,
                },
              })}
              {...field}
              {...register(name)}
              onChange={(selectedOption) => {
                setValue(name, selectedOption, { shouldDirty: true });
                setError(name, { message: '' });
              }}
              isMulti={isMulti}
              getOptionLabel={(option: SelectOption) => option.name}
              getOptionValue={(option: SelectOption) => option.id.toString()}
              value={field.value}
              isDisabled={isDisabled}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={{
                option: (provided) => ({
                  ...provided,
                  fontSize: '12px',
                }),
                menuList: (provided) => ({
                  ...provided,
                  padding: '8px',
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  fontSize: '12px',
                }),
              }}
            />
          );
        }}
      />
      {errors[name] && (
        <span className="text-red-carnation text-xs mt-1 font-lato-light">
          {errors[name]?.message as string}
        </span>
      )}
    </>
  );
};

export default memo(SelectInput);
