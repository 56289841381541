import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { handleSpaceKeyDown } from 'utils/utils';
import { schema } from '../ResetPassword/schema';
import { setResetPassword } from 'state-management/auth';
import Label from 'components/elements/Label/Label';
import Input from 'components/elements/Input/Input';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import { PASSWORD_LENGTH } from 'helpers/constants/fieldLengths';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import GradientButton from 'components/elements/GradientButton/GradientButton';

const ResetPassword: React.FC = () => {
  const methods = useForm<ResetPasswordForm>({
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const [showPassword, setShowPassword] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const reset_token = queryParams.get('reset_token') || '';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onSubmit = (data: ResetPasswordForm) => {
    setIsLoading(true);
    dispatch(setResetPassword(reset_token, data, axiosInstance))
      .then((response: { message: string }) => {
        navigate('/login');
        notifySuccess(response.message);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
        if (error.status === 401) {
          navigate('/reset_password');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login-card">
      <div>
        <h4 className="login-header">Reset Password</h4>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit((data: ResetPasswordForm) => onSubmit(data))}
          >
            <div className="input-group-wrapper">
              <Label
                name="password"
                label="New password"
                className="input-label mt-5"
              />
              <Input
                name="password"
                placeholder="New password"
                className="change-pass-input"
                error={errors.password?.message}
                minLength={PASSWORD_LENGTH.MINIMUM}
                maxLength={PASSWORD_LENGTH.MAXIMUM}
                type={showPassword ? 'text' : 'password'}
                contentAfter={
                  <button
                    className="input-show-password-btn"
                    type="button"
                    tabIndex={-1}
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    title="Show/Hide Password"
                  >
                    {!showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                }
                onKeyDown={handleSpaceKeyDown}
              />
            </div>
            <div className="input-group-wrapper">
              <Label
                label="Confirm Password"
                name="confirm_password"
                className="input-label mt-5"
              />
              <Input
                name="confirm_password"
                className="change-pass-input"
                placeholder="Confirm Password"
                minLength={PASSWORD_LENGTH.MINIMUM}
                maxLength={PASSWORD_LENGTH.MAXIMUM}
                error={errors.confirm_password?.message}
                type={showConfirmPassword ? 'text' : 'password'}
                contentAfter={
                  <button
                    className="input-show-password-btn"
                    type="button"
                    tabIndex={-1}
                    onClick={() => {
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                    title="Show/Hide Password"
                  >
                    {!showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                }
                onKeyDown={handleSpaceKeyDown}
              />
            </div>
            <GradientButton
              type="submit"
              title="Submit"
              isLoading={isLoading}
              buttonText="Reset Password"
              className="login-button login-button-text rounded-md"
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ResetPassword;
