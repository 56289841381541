import { AxiosInstance } from 'axios';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const fetchRoles = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.fetch_roles);
    };
    const result = await data();
    return result;
  };
};

export const fetchGroups = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.fetch_groups);
    };
    const result = await data();
    return result;
  };
};

export const addUser = (
  axiosInstance: AxiosInstance,
  userData: ADD_USER_PAYLOAD,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.post(API_END_POINTS.invitations, userData);
    };
    const result = await data();
    return result;
  };
};

export const fetchUsers = (
  axiosInstance: AxiosInstance,
  page: number,
  per_page: number,
  q?: string,
) => {
  return async () => {
    const data = async () => {
      const params: { [key: string]: string | number } = { page, per_page };
      if (q) params.q = q;
      return await axiosInstance.get(API_END_POINTS.fetch_users, { params });
    };
    const result = await data();
    return result.data;
  };
};

export const fetchUsersIdsAndNames = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.fetch_user_ids_names);
    };
    const result = await data();
    return result;
  };
};

export const fetchUser = (axiosInstance: AxiosInstance, id: number) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(`${API_END_POINTS.fetch_users}/${id}`);
    };
    const result = await data();
    return result.data;
  };
};

export const updateUser = (
  axiosInstance: AxiosInstance,
  userId: number,
  userData: UPDATE_USER,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `${API_END_POINTS.update_user}/${userId}`,
        userData,
      );
    };
    const result = await data();
    return result;
  };
};

export const deleteUser = (axiosInstance: AxiosInstance, userId: number) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.delete(
        `${API_END_POINTS.fetch_users}/${userId}`,
      );
    };
    const result = await data();
    return result;
  };
};

export const fetchSuperVisors = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.supervisors);
    };
    const result = await data();
    return result.data;
  };
};

export const resendInvitationLink = (
  axiosInstance: AxiosInstance,
  userId: number,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `${API_END_POINTS.resend_invite}/${userId}`,
      );
    };
    const result = await data();
    return result.data;
  };
};
