import { z } from 'zod';
import React, { memo, useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import { cn } from 'utils/utils';
import Label from 'components/elements/Label/Label';
import Input from 'components/elements/Input/Input';
import Button from 'components/elements/Button/Button';
import { MODAL_DELETE } from 'helpers/constants/modalContent';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import { addGroup, updateGroup } from 'actions/SLA/groups.actions';

const AddGroup: React.FC<{
  groupsList: Groups | null;
  closeModal: () => void;
  fetchGroups: (perPage?: number, currentPage?: number) => void;
  selectedGroup: Group | null;
}> = ({ closeModal, fetchGroups, selectedGroup, groupsList }) => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const methods = useForm<TicketPrioritySLAForm>({
    defaultValues: { name: '' },
    resolver: zodResolver(
      z.object({
        name: z
          .string()
          .trim()
          .min(1, { message: 'Group is required' })
          .max(25, {
            message: 'Group name should be less than 25 characters',
          }),
      }),
    ),
  });

  const submitHandler = (formData: { name: string }): void => {
    setIsLoading(true);
    if (selectedGroup) {
      dispatch(updateGroup(selectedGroup.id, axiosInstance, formData))
        .then(() => {
          notifySuccess('Group updated successfully');
          fetchGroups(
            groupsList?.pagination.per_page,
            groupsList?.pagination.current_page,
          );
          closeModal();
        })
        .catch((error: ErrorData) => notifyError(error))
        .finally(() => setIsLoading(false));
    } else {
      dispatch(addGroup(axiosInstance, formData))
        .then((response) => {
          notifySuccess(response.message);
          fetchGroups(groupsList?.pagination.per_page, 1);
          methods.reset();
          closeModal();
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      methods.reset({
        name: selectedGroup?.name,
      });
    }
  }, []);

  const {
    formState,
    formState: { errors },
  } = methods;
  const isFormDirty = JSON.stringify(formState.dirtyFields) !== '{}';

  return (
    <div>
      <FormProvider {...methods}>
        <form
          className="w-[80vw] lg:w-[50vw] xl:w-[30vw]"
          onSubmit={methods.handleSubmit((data) => submitHandler(data))}
        >
          <div className="input-group-wrapper mt-2">
            <Label name="name" label="Group *" className={cn('text-xs')} />
            <Input
              type="text"
              name="name"
              className="user-input"
              placeholder="Enter group"
              error={errors.name?.message}
            />
          </div>
          <div className="flex justify-center gap-10 mt-6">
            <Button
              type="button"
              title="Cancel"
              onClick={closeModal}
              className="cancel-button"
              buttonName={MODAL_DELETE.CANCEL}
            />
            <Button
              title="Save"
              type="submit"
              buttonName="Save"
              isLoading={isLoading}
              className="submit-button"
              shouldDisable={!isFormDirty}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(AddGroup);
