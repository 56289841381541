import { z } from 'zod';
import { REQUIRED_MESSAGES } from 'helpers/constants/messages';

export const schema = z.object({
  from_date: z.string().trim().min(1, { message: REQUIRED_MESSAGES.FROM_DATE }),
  to_date: z.string().trim().min(1, { message: REQUIRED_MESSAGES.TO_DATE }),
  status: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .array()
    .nullable()
    .optional(),
  priority_ids: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .array()
    .nullable()
    .optional(),
  category_ids: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .array()
    .nullable()
    .optional(),
  assigned_group_ids: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .array()
    .nullable()
    .optional(),
  assigned_user_ids: z
    .object({
      id: z.string().or(z.number()),
      name: z.string(),
    })
    .array()
    .nullable()
    .optional(),
});
