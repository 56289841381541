import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';

const MainContent: React.FC = () => {
  return (
    <main className="bg-white-lilac flex flex-col pt-16 pb-10 min-h-screen w-full sm:pl-24">
      <Header />
      <div className="pt-16">
        <Outlet />
      </div>
    </main>
  );
};

export default MainContent;
