import moment from 'moment';
import React, { useEffect, useState } from 'react';

import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import Pagination from 'components/elements/Pagination/Pagination';
import { fetchOverdueTickets } from 'actions/dashboard/dashboard.actions';

const OverdueTickets: React.FC = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [overdueTickets, setOverdueTickets] = useState<OverdueTickets | null>(
    null,
  );

  const getOverdueTickets = (perPage?: number, currentPage?: number) => {
    setIsLoading(true);
    dispatch(fetchOverdueTickets(axiosInstance, currentPage, perPage))
      .then((response) => {
        setOverdueTickets(response);
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  const handlePageChange = ({ selected }: { selected: number }): void => {
    getOverdueTickets(overdueTickets?.pagination?.per_page, selected + 1);
  };

  const handlePerPageChange = (perPage: number): void => {
    getOverdueTickets(perPage);
  };

  useEffect(() => {
    getOverdueTickets();
  }, []);

  return (
    <div className="border rounded-md shadow-lg mt-10 w-full md:w-[75vw] lg:w-[50vw] xxl:w-[40vw]">
      <div className="p-3 border-b-2">
        <h5 className="font-semibold">Overdue Tickets</h5>
      </div>
      <div className="h-[50vh] overflow-y-auto">
        {!isLoading ? (
          overdueTickets?.tickets.map((ticket: OverdueTicket) => {
            return <OverdueTicketCard ticket={ticket} key={ticket.ticket_id} />;
          })
        ) : (
          <div className="flex items-center justify-center h-full">
            <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-black mt-10"></div>
          </div>
        )}
        {!isLoading &&
          (overdueTickets?.tickets?.length === 0 ||
            !overdueTickets?.tickets) && (
            <div className="flex justify-center items-center h-full">
              <small className="text-gray-rolling-stone">
                No tickets to show
              </small>
            </div>
          )}
      </div>
      {overdueTickets &&
        (overdueTickets.pagination.total_count > 10 ||
          overdueTickets.pagination.total_pages > 1) && (
          <div className="border-t-2">
            <Pagination
              customClass="!mt-3"
              pagination={overdueTickets.pagination}
              handlePageChange={handlePageChange}
              handlePerPageChange={handlePerPageChange}
              currentPage={overdueTickets.pagination.current_page}
            />
          </div>
        )}
    </div>
  );
};

const OverdueTicketCard: React.FC<{ ticket: OverdueTicket }> = ({ ticket }) => {
  const getBackgroundColor = (status: string): string => {
    return status === 'Closed'
      ? 'bg-green-300'
      : status === 'Open'
        ? 'bg-red-300'
        : 'bg-blue-300';
  };

  return (
    <div className="border rounded-md shadow-md p-3 mb-2 m-3">
      <div className="flex justify-between items-center mb-3">
        <h5 className="font-lato-bold">{`#${ticket.ticket_id}`}</h5>
        <small title={ticket.subject} className="cursor-pointer">
          {ticket.subject.length < 50
            ? ticket.subject
            : `${ticket.subject.slice(0, 50)}...`}
        </small>
      </div>
      <div className="flex justify-between items-center">
        <div
          className={`${getBackgroundColor(ticket.status)} w-fit px-1 py-0.5 rounded-md`}
        >
          <small className="font-lato-semibold">{ticket.status}</small>
        </div>
        <h5>
          <span className="font-lato-bold">Last Updated:</span>{' '}
          {moment(ticket.updated_at).format('lll')}
        </h5>
      </div>
    </div>
  );
};

export default OverdueTickets;
