import React from 'react';

import MainContent from 'containers/components/MainContent/MainContent';
import Navbar from 'containers/components/Navbar/Navbar';
import Sidebar from 'containers/components/Sidebar/Sidebar';
import { MainProvider } from 'helpers/providers/MainContext';
import Modal from 'react-responsive-modal';
import useMenuNotificationList from 'helpers/hooks/useMenuNotificationList';
import CustomerTicketsList from 'components/renderings/secure/Ticket/CustomerTickets/CustomerTicketsList';

const Main: React.FC = () => {
  const { openCustomerTicketsModal, setOpenCustomerTicketsModal } =
    useMenuNotificationList();

  const closeModal = (): void => {
    setOpenCustomerTicketsModal(false);
  };

  return (
    <MainProvider>
      <Navbar />
      <Sidebar />
      <MainContent />
      <Modal
        center
        showCloseIcon={true}
        open={openCustomerTicketsModal}
        onClose={closeModal}
        animationDuration={500}
        closeOnOverlayClick={false}
        classNames={{
          overlayAnimationIn: 'customEnterOverlayAnimation',
          modalAnimationIn: 'customEnterModalAnimation',
        }}
        styles={{
          modal: {
            height: '90vh',
            width: '80vw',
            borderRadius: '10px',
          },
          closeIcon: {
            marginRight: '20px',
          },
        }}
      >
        <CustomerTicketsList closeModal={closeModal} />
      </Modal>
    </MainProvider>
  );
};

export default Main;
