import React, { useEffect } from 'react';

import './Settings.css';
import Groups from './Groups/Groups';
import { store } from 'state-management/store';
import { ROLE } from 'types/enums/sidebarEnums';
import Categories from './Categories/Categories';
import { useMainContext } from 'helpers/providers/MainContext';
import TicketPrioritySLA from './TicketPrioritySLA/TicketPrioritySLA';
import NotificationSettings from './NotificationSettings/NotificationSettings';

const Settings: React.FC = () => {
  const MainContext = useMainContext();
  const { role } = store.getState().auth;

  useEffect(() => {
    MainContext.setPageHeaderName('Settings');
  }, []);

  return (
    <div className="settings-container">
      {role === ROLE.SUPER_ADMIN && (
        <>
          <div className="component-card">
            <TicketPrioritySLA />
          </div>
          <div className="component-card">
            <Groups />
          </div>
          <div className="component-card">
            <Categories />
          </div>
        </>
      )}
      <div className="component-card">
        <NotificationSettings />
      </div>
    </div>
  );
};

export default Settings;
