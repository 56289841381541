import {
  ColDef,
  ValueGetterParams,
  ICellRendererParams,
} from 'ag-grid-community';
import { IoMdAdd } from 'react-icons/io';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import { BsSendArrowUpFill } from 'react-icons/bs';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import React, {
  useMemo,
  Dispatch,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
} from 'react';

import '../Users.css';
import {
  deleteUser,
  fetchUsers,
  resendInvitationLink,
} from 'actions/users/users.actions';
import {
  useAppSelector,
  useAppDispatch,
} from 'state-management/hooks/stateHooks';
import {
  setCurrentPage,
  setSearchQuery,
  setPerPageCount,
} from 'state-management/usersList/usersListSlice';
import { RootState } from 'state-management/store';
import { USERS_LIST_HEADERS } from './UserListHeaders';
import Search from 'components/elements/Search/Search';
import { GRID_COLUMN_DEF } from 'helpers/constants/agGrid';
import DeleteModal from 'components/components/DeleteModal';
import { useMainContext } from 'helpers/providers/MainContext';
import NoRowsOverlay from 'components/components/NoRowsOverlay';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import Pagination from 'components/elements/Pagination/Pagination';

const UsersList: React.FC = () => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const MainContext = useMainContext();
  const [users, setUsers] = useState<FETCH_USERS_RESPONSE[]>();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<Pagination>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [isUserDeleting, setIsUserDeleting] = useState(false);
  const { currentPage, perPageCount, searchQuery } = useAppSelector(
    (state: RootState) => state.usersList,
  );
  const defaultColDef: ColDef = useMemo(() => {
    return { filter: false, suppressMovable: true };
  }, []);

  useEffect(() => {
    MainContext.setPageHeaderName('Users');
    usersFetch();
  }, [currentPage, perPageCount, searchQuery]);

  const usersFetch = async () => {
    await dispatch(
      fetchUsers(axiosInstance, currentPage, perPageCount, searchQuery),
    )
      .then((response) => {
        setUsers(response.users);
        setPagination(response.pagination);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    dispatch(setCurrentPage(selected + 1));
  };

  const handlePerPageChange = (perPage: number) => {
    dispatch(setPerPageCount(perPage));
  };

  const editUser = (id: number) => {
    navigate(`/users/edit/${id}`);
  };

  const userDelete = async () => {
    setIsUserDeleting(true);
    await dispatch(deleteUser(axiosInstance, userId))
      .then((response) => {
        notifySuccess(response.data.message);
        usersFetch();
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setModalOpen(false);
        setIsUserDeleting(false);
      });
  };

  const resendInviteLink = (
    userId: number,
    setIsInvitationSending: Dispatch<SetStateAction<boolean>>,
  ): void => {
    setIsInvitationSending(true);
    dispatch(resendInvitationLink(axiosInstance, userId))
      .then((response) => {
        notifySuccess(response.message);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsInvitationSending(false);
      });
  };

  const openDeleteModal = (id: number) => {
    setUserId(id);
    setModalOpen(true);
  };

  const deleteCancel = (): void => {
    setModalOpen(false);
  };

  const onSearch = useCallback(
    (value: string) => {
      dispatch(setSearchQuery(value));
      dispatch(setCurrentPage(1));
    },
    [dispatch],
  );

  const UsersListActionRenderer = (user: ICellRendererParams) => {
    const [isInvitationSending, setIsInvitationSending] =
      useState<boolean>(false);

    return (
      <div className="flex">
        <button
          title="Edit User"
          type="button"
          className="icon-style"
          onClick={() => editUser(user.data.id)}
        >
          <FaRegEdit className="w-5 h-5 text-gray-iconColor" />
        </button>
        <button
          title="Delete User"
          type="button"
          className="icon-style"
          onClick={() => openDeleteModal(user.data.id)}
        >
          <FaRegTrashAlt className="w-5 h-5 text-gray-iconColor" />
        </button>
        {!isInvitationSending ? (
          <button
            type="button"
            className={`icon-style ${user.data.confirmed_at && 'opacity-50 cursor-not-allowed'}`}
            title={`${user.data.confirmed_at ? 'User confirmed already' : 'Resend invite link'}`}
            disabled={user.data.confirmed_at}
            onClick={() =>
              resendInviteLink(user.data.id, setIsInvitationSending)
            }
          >
            <BsSendArrowUpFill className="w-5 h-5 text-gray-iconColor" />
          </button>
        ) : (
          <div className="animate-spin rounded-full h-4 w-4 border-t border-b border-black ml-2 self-center"></div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="p-4 md:px-10">
        <div className="flex flex-col items-start lg:flex-row lg:items-center lg:justify-between">
          <Search onSearch={onSearch} searchQuery={searchQuery} />
          <button
            className="submit-button flex items-center mb-4 lg:mb-0"
            title="Add New User"
            onClick={() => {
              navigate('/users/new_user');
            }}
          >
            <span className="pr-1">
              <IoMdAdd size={15} />
            </span>
            Add User
          </button>
        </div>
        <div
          className={'ag-theme-quartz'}
          style={{ width: '100%', height: '390px' }}
        >
          <AgGridReact
            rowData={users}
            noRowsOverlayComponentParams={{
              message: 'No Users to show',
            }}
            noRowsOverlayComponent={NoRowsOverlay}
            reactiveCustomComponents={true}
            columnDefs={[
              {
                headerName: 'S.No',
                valueGetter: (params: ValueGetterParams) =>
                  pagination!.start_at + params!.node!.rowIndex!,
                resizable: false,
                sortable: false,
                maxWidth: GRID_COLUMN_DEF.sNo.maxWidth,
              },
              ...USERS_LIST_HEADERS,
            ]}
            defaultColDef={defaultColDef}
            components={{
              actionsListRenderer: UsersListActionRenderer,
            }}
            suppressCellFocus={true}
          />
        </div>
        {pagination && (
          <Pagination
            pagination={pagination}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            handlePerPageChange={handlePerPageChange}
          />
        )}
      </div>
      <DeleteModal
        openModal={modalOpen}
        isLoading={isUserDeleting}
        confirmDeletion={userDelete}
        cancelDeletion={deleteCancel}
        description="Are you sure want to delete the user?"
      />
    </>
  );
};

export default UsersList;
