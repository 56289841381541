import { AxiosInstance } from 'axios';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const fetchFifteenDaysTicketCount = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.fetch_fifteen_days_count);
    };
    const result = await data();
    return result.data;
  };
};

export const fetchCategoriesCount = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.fetch_categories_count);
    };
    const result = await data();
    return result.data;
  };
};

export const fetchStatusCount = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.fetch_status_count);
    };
    const result = await data();
    return result.data;
  };
};

export const fetchPrioritiesCount = (axiosInstance: AxiosInstance) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.get(API_END_POINTS.priorities_count);
    };
    const result = await data();
    return result.data;
  };
};

export const fetchOverdueTickets = (
  axiosInstance: AxiosInstance,
  page: number = 1,
  per_page: number = 10,
) => {
  return async () => {
    const data = async () => {
      const params: { [key: string]: string | number } = { page, per_page };
      return await axiosInstance.get(API_END_POINTS.overdue_tickets, {
        params,
      });
    };
    const result = await data();
    return result.data;
  };
};
