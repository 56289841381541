import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { UI_DISPLAY_LENGTHS } from 'helpers/constants/tickets';
import moment from 'moment';

export const ASSIGNED_TICKET_LIST_HEADERS: ColDef<ASSIGNED_TICKET_LIST>[] = [
  {
    width: 160,
    field: 'ticket_id',
    resizable: false,
    headerName: 'Ticket ID',
  },
  {
    field: 'subject',
    resizable: false,
    headerName: 'Subject',
    tooltipField: 'subject',
    valueGetter: (args: ValueGetterParams) => {
      const subject = args.data.subject;
      return subject.length > UI_DISPLAY_LENGTHS.LIST_SUBJECT
        ? `${subject.slice(0, UI_DISPLAY_LENGTHS.LIST_SUBJECT)}...`
        : subject;
    },
  },
  { headerName: 'Status', field: 'status', resizable: false, width: 120 },
  {
    width: 150,
    resizable: false,
    field: 'assignee_type',
    headerName: 'Assignee Type',
  },
  { headerName: 'Assignee', field: 'assignee', resizable: false, width: 200 },
  {
    width: 130,
    resizable: false,
    field: 'due_date',
    headerName: 'Due Date',
    valueGetter: (args: ValueGetterParams) =>
      moment(args.data.due_date).format('DD/MM/YYYY'),
  },
  {
    width: 160,
    resizable: false,
    field: 'updated_at',
    headerName: 'Updated At',
    valueGetter: (args: ValueGetterParams) =>
      moment(args.data.updated_at).format('lll'),
  },
  {
    field: 'actions',
    resizable: false,
    headerName: 'Actions',
    cellStyle: { border: 'none', paddingLeft: 32 },
    cellRenderer: 'actionsListRenderer',
  },
];
