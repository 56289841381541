import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState: CustomerTicketsState = {
  event: '',
  data: {
    tickets: [],
    user_id: null,
    customer_details: {},
  },
};

export const customerTicketsSlice = createSlice({
  name: 'customerTickets',
  initialState,
  reducers: {
    setCustomerTickets: (
      state,
      action: PayloadAction<CustomerTicketsState>,
    ) => ({ ...state, ...action.payload }),
  },
});

export const { setCustomerTickets } = customerTicketsSlice.actions;
export default customerTicketsSlice.reducer;
