import { AxiosInstance } from 'axios';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const changePassword = (
  axiosInstance: AxiosInstance,
  params: ChangePassword,
) => {
  return async () => {
    const data = async () => {
      return await axiosInstance.put(
        `${API_END_POINTS.change_password}`,
        params,
      );
    };
    const result = await data();
    return result.data;
  };
};
