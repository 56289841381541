import moment from 'moment';
import React, { memo } from 'react';

const TicketHistory: React.FC<{ ticketHistories: TicketHistory[] }> = ({
  ticketHistories,
}) => {
  return (
    <div className="border-2 rounded-md mt-10 p-4 pb-2 pr-0">
      <h5 className="font-lato-bold mb-2">Ticket History</h5>
      <div className="overflow-y-auto max-h-56 pr-2">
        {ticketHistories.length === 0 && (
          <p className="text-center my-3 text-gray-rolling-stone">
            No histories to show
          </p>
        )}
        {ticketHistories.map((ticketHistory: TicketHistory, index: number) => {
          return (
            <div key={index} className="bg-gray-200 mb-1.5 p-2 rounded-md">
              <p>{ticketHistory.description}</p>
              <small className="text-xs italic">
                {moment(ticketHistory.created_at).format('lll')}
              </small>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(TicketHistory);
