import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { zodResolver } from '@hookform/resolvers/zod';

import Input from 'components/elements/Input/Input';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import GradientButton from 'components/elements/GradientButton/GradientButton';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { changePassword } from 'actions/profile/profile.actions';
import { clearStorage } from 'state-management/auth';
import { TOASTER_MESSAGES } from 'helpers/constants/messages';
import Label from 'components/elements/Label/Label';
import { schema } from './schema';
import { useMainContext } from 'helpers/providers/MainContext';
import { handleSpaceKeyDown } from 'utils/utils';
import './ChangePassword.css';
import { PASSWORD_LENGTH } from 'helpers/constants/fieldLengths';

const ChangePassword: React.FC = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const methods = useForm<ChangePassword>({
    resolver: zodResolver(schema),
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const MainContext = useMainContext();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    MainContext.setPageHeaderName('Change Password');
  }, [MainContext]);

  const onSubmit = (data: ChangePassword) => {
    setIsLoading(true);
    dispatch(changePassword(axiosInstance, data))
      .then(() => {
        dispatch(clearStorage());
        notifySuccess(TOASTER_MESSAGES.PASSWORD_CHANGE_SUCCESS);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex flex-col mt-10 items-center p-4">
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit((data: ChangePassword) => {
            onSubmit(data);
          })}
          className="w-full sm:w-[90%] md:w-[70%] lg:w-[45%]"
        >
          <div className="input-group-wrapper">
            <Label
              name="current_password"
              label="Current password"
              className="input-label"
            />
            <Input
              type={showCurrentPassword ? 'text' : 'password'}
              name="current_password"
              placeholder="Current Password"
              className="change-pass-input"
              error={errors.current_password?.message}
              contentAfter={
                <button
                  className="input-show-password-btn"
                  type="button"
                  tabIndex={-1}
                  onClick={() => {
                    setShowCurrentPassword(!showCurrentPassword);
                  }}
                  title="Show/Hide Password"
                >
                  {!showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              }
              onKeyDown={handleSpaceKeyDown}
            />
          </div>
          <div className="input-group-wrapper">
            <Label
              name="password"
              label="New password"
              className="input-label mt-5"
            />
            <Input
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="New password"
              className="change-pass-input"
              error={errors.password?.message}
              minLength={PASSWORD_LENGTH.MINIMUM}
              maxLength={PASSWORD_LENGTH.MAXIMUM}
              contentAfter={
                <button
                  className="input-show-password-btn"
                  type="button"
                  tabIndex={-1}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  title="Show/Hide Password"
                >
                  {!showPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              }
              onKeyDown={handleSpaceKeyDown}
            />
          </div>
          <div className="input-group-wrapper">
            <Label
              name="confirm_password"
              label="Confirm Password"
              className="input-label mt-5"
            />
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              name="confirm_password"
              placeholder="Confirm Password"
              className="change-pass-input"
              error={errors.confirm_password?.message}
              minLength={PASSWORD_LENGTH.MINIMUM}
              maxLength={PASSWORD_LENGTH.MAXIMUM}
              contentAfter={
                <button
                  className="input-show-password-btn"
                  type="button"
                  tabIndex={-1}
                  onClick={() => {
                    setShowConfirmPassword(!showConfirmPassword);
                  }}
                  title="Show/Hide Password"
                >
                  {!showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </button>
              }
              onKeyDown={handleSpaceKeyDown}
            />
          </div>
          <GradientButton
            type="submit"
            className="login-button login-button-text w-fit mx-auto rounded-md"
            buttonText="Change Password"
            isLoading={isLoading}
            title="Change Password"
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default ChangePassword;
