import {
  Pie,
  YAxis,
  XAxis,
  Legend,
  Tooltip,
  PieChart,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
} from 'recharts';
import moment from 'moment';
import { PiLightbulbLight } from 'react-icons/pi';
import { memo, useCallback, useEffect, useState } from 'react';

import {
  fetchStatusCount,
  fetchCategoriesCount,
  fetchPrioritiesCount,
  fetchFifteenDaysTicketCount,
} from 'actions/dashboard/dashboard.actions';
import { notifyError } from 'utils/ToastMessage';
import { useMainContext } from 'helpers/providers/MainContext';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import { PIE_CHART_COLORS, PRIORITIES_COLORS } from 'helpers/constants/tickets';
import OverdueTickets from '../Ticket/OverdueTickets/OverdueTickets';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const MainContext = useMainContext();
  const axiosInstance = useAxiosInstance();
  const [prioritiesCount, setPrioritiesCount] =
    useState<PrioritiesCountData[]>();
  const [categoriesCount, setCategoriesCount] =
    useState<CategoriesCountData[]>();
  const [fifteenDaysCount, setFifteenDaysCountData] =
    useState<TicketCountData[]>();
  const [statusCount, setStatusCount] = useState<StatusCountKeyValue>({
    Open: 0,
    Total: 0,
    Closed: 0,
    'In Progress': 0,
  });

  const getFifteenDaysTicketCount = useCallback(() => {
    dispatch(fetchFifteenDaysTicketCount(axiosInstance))
      .then((response: TicketCountData[]) => {
        setFifteenDaysCountData(response);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  }, [axiosInstance, dispatch]);

  const getCategoriesCount = useCallback(() => {
    dispatch(fetchCategoriesCount(axiosInstance))
      .then((response: CategoriesCountResponse) => {
        const modifiedCategoriesCount = Object.entries(response).map(
          ([name, value]: [string, number], index: number) => {
            return { name, value, fill: PIE_CHART_COLORS[index] };
          },
        );
        setCategoriesCount(modifiedCategoriesCount);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  }, [axiosInstance, dispatch]);

  const getStatusCount = useCallback(() => {
    dispatch(fetchStatusCount(axiosInstance))
      .then((response: StatusCountKeyValue) => {
        setStatusCount(response);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  }, [axiosInstance, dispatch]);

  const getPrioritiesCount = useCallback(() => {
    dispatch(fetchPrioritiesCount(axiosInstance))
      .then((response) => {
        const prioritesCountData = Object.keys(response).map((key, index) => ({
          name: key,
          value: response[key],
          fill: PRIORITIES_COLORS[index],
        }));
        setPrioritiesCount(prioritesCountData);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  }, [axiosInstance, dispatch]);

  const formatDate = (dateString: string): string => {
    return moment(dateString).format('MMM DD');
  };

  useEffect(() => {
    MainContext.setPageHeaderName('Dashboard');
    getStatusCount();
    getCategoriesCount();
    getPrioritiesCount();
    getFifteenDaysTicketCount();
  }, [getCategoriesCount, getFifteenDaysTicketCount, getStatusCount]);

  return (
    <div className="p-4 md:p-10">
      <StatusCount statusCount={statusCount} />
      <div className="flex flex-col lg:flex-row lg:flex-wrap mt-10 gap-2">
        <div className="w-full shadow-lg border p-4 rounded-lg">
          <h5 className="font-semibold mb-4">
            Generated tickets (last 15 days)
          </h5>
          {fifteenDaysCount && statusCount.Total !== 0 ? (
            <ResponsiveContainer height={350} width="100%">
              <LineChart data={fifteenDaysCount}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  className="text-xs"
                  allowDecimals={false}
                  tickFormatter={formatDate}
                />
                <YAxis
                  allowDecimals={false}
                  label={{
                    angle: -90,
                    value: 'Tickets',
                    position: 'insideLeft',
                  }}
                />
                <Tooltip />
                <Legend wrapperStyle={{ fontSize: '0.75rem' }} />
                <Line
                  type="monotone"
                  stroke="#8884d8"
                  name="Tickets Generated"
                  dataKey="total_ticket_generated"
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <div className="mt-10 ml-5 text-gray-rolling-stone">
              <h5 className="font-lato-bold mb-3">No data found</h5>
              <PiLightbulbLight size={80} />
            </div>
          )}
        </div>
        <div className="mt-12 flex flex-col lg:flex-row gap-10 lg:gap-28">
          {statusCount.Total > 0 && (
            <div className="shadow-md p-4 border rounded-lg">
              <h5 className="font-semibold mt-7 lg:mt-0">
                Tickets by Categories
              </h5>
              <PieChart width={350} height={270}>
                <Pie
                  label
                  dataKey="value"
                  innerRadius={50}
                  outerRadius={90}
                  animationBegin={0}
                  data={categoriesCount}
                  animationDuration={800}
                  className="outline-none"
                  isAnimationActive={true}
                  animationEasing="ease-in"
                />
                <Legend
                  align="right"
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={{ fontSize: '0.75rem' }}
                />
                <Tooltip wrapperStyle={{ fontSize: '0.75rem' }} />
                {statusCount.Total > 0 && (
                  <text
                    x="39%"
                    y="50%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    className="font-lato-bold text-sm"
                  >
                    {statusCount.Total}
                  </text>
                )}
              </PieChart>
            </div>
          )}
          {prioritiesCount && Object.keys(prioritiesCount).length > 0 && (
            <div className="lg:ml-5 shadow-md p-4 rounded-lg border">
              <h5 className="font-semibold mt-7 lg:mt-0">
                Tickets by Priorities
              </h5>
              <PieChart width={350} height={270}>
                <Pie
                  label
                  dataKey="value"
                  innerRadius={50}
                  outerRadius={90}
                  animationBegin={0}
                  data={prioritiesCount}
                  animationDuration={800}
                  className="outline-none"
                  isAnimationActive={true}
                  animationEasing="ease-in"
                />
                <Legend
                  align="right"
                  layout="vertical"
                  verticalAlign="middle"
                  wrapperStyle={{ fontSize: '0.75rem' }}
                />
                <Tooltip wrapperStyle={{ fontSize: '0.75rem' }} />
              </PieChart>
            </div>
          )}
        </div>
      </div>
      <OverdueTickets />
    </div>
  );
};

const StatusCount: React.FC<{ statusCount: StatusCountKeyValue }> = memo(
  ({ statusCount }) => {
    const labelNames = {
      Total: 'Total Tickets',
      Open: 'Open Tickets',
      'In Progress': 'Tickets In Progress',
      Closed: 'Tickets Resolved',
    };
    return (
      <div className="flex flex-col md:flex-row gap-5 justify-around mb-5">
        {Object.entries(labelNames).map(([key, value], index: number) => {
          return (
            <div
              key={index}
              className="border shadow-md p-4 rounded-md w-full sm:w-3/4 md:w-3/12"
            >
              <h5 className="font-lato-semibold">{value}</h5>
              <h4 className="font-lato-bold">{statusCount[key]}</h4>
            </div>
          );
        })}
      </div>
    );
  },
);

export default memo(Dashboard);
