import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.css';
import { PER_PAGE_COUNTS } from 'helpers/constants/pagination';

const Pagination: React.FC<PaginationProps> = ({
  pagination,
  handlePageChange,
  currentPage,
  handlePerPageChange,
  customClass,
}) => {
  const { end_at, start_at, total_count, total_pages } = pagination;

  const onPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    handlePerPageChange(+event.target.value);
  };

  return (
    <div className={`${customClass} pagination-info-container mt-6 mb-3`}>
      <select
        className="per-page-select"
        onChange={onPerPageChange}
        defaultValue={pagination.per_page}
      >
        {PER_PAGE_COUNTS.map((perPageCount: number) => {
          return (
            <option value={perPageCount} key={perPageCount}>
              {perPageCount}
            </option>
          );
        })}
      </select>
      <span className="text-xs md:text-sm text-gray-700">
        Showing{' '}
        <span className="font-lato-semibold text-gray-900">{start_at}</span> to{' '}
        <span className="font-lato-semibold text-gray-900">{end_at}</span> of{' '}
        <span className="font-lato-semibold text-gray-900">{total_count}</span>{' '}
        Entries
      </span>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageChange}
        pageRangeDisplayed={2}
        forcePage={currentPage - 1}
        pageCount={total_pages}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
        containerClassName={'pagination-container'}
        breakLinkClassName={'pagination-break-link'}
        previousLinkClassName={'pagination-previous-link'}
        nextLinkClassName={'pagination-next-link'}
        pageLinkClassName={'pagination-page-link'}
        activeLinkClassName={'pagination-active-link'}
        disabledClassName={'pagination-disabled-link'}
        disabledLinkClassName={'pagination-disabled-link'}
        marginPagesDisplayed={1}
      />
    </div>
  );
};

export default memo(Pagination);
