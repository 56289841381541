import React, { useEffect, useState } from 'react';

import { setIsSidebarOpen } from 'state-management/sidebar/sidebarSlice';
import { RootState } from 'state-management/store';
import logoIcon from 'assets/icons/logo.svg';
import notificationIcon from 'assets/icons/notification.svg';
import menuIcon from 'assets/icons/menu.svg';
import { RiLockLine } from 'react-icons/ri';
import { FaRegUserCircle } from 'react-icons/fa';
import { logOutUser } from 'state-management/auth';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import { useNavigate } from 'react-router-dom';
import MenuNotificationBadge from 'components/components/MenuNotificationBadge/MenuNotificationBadge';
import MenuNotificationList from 'components/components/MenuNotificationList/MenuNotificationList';
import './Navbar.css';

const Navbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSidebarOpen = useAppSelector(
    (state: RootState) => state.sidebar.isSidebarOpen,
  );
  const { first_name, last_name, refreshToken } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const [showDropDown, setShowDropDown] = useState(false);
  const [showNotificationDropDown, setShowNotificationDropDown] =
    useState(false);
  const axiosInstance = useAxiosInstance();
  const nameInitial = (
    first_name.charAt(0) + last_name.charAt(0)
  ).toUpperCase();

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;
      if (
        showDropDown &&
        targetElement &&
        !targetElement.closest('#sign-out-container')
      ) {
        setShowDropDown(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [showDropDown]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;
      if (
        showNotificationDropDown &&
        targetElement &&
        !targetElement.closest('#notification-container')
      ) {
        setShowNotificationDropDown(false);
      }
    };
    window.addEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [showNotificationDropDown]);

  const signOut = async () => {
    await dispatch(logOutUser(axiosInstance, refreshToken))
      .then((response) => {
        notifySuccess(response.data.message);
      })
      .catch((error: ErrorData) => {
        notifyError(error);
      });
  };

  return (
    <nav className="nav-container">
      <div className="flex gap-2 items-center">
        <button
          type="button"
          onClick={() => dispatch(setIsSidebarOpen(!isSidebarOpen))}
          className="nav-menu-btn"
        >
          <img
            src={menuIcon}
            alt="Menu icon"
            className="w-5 h-5 text-gray-charcoal"
          />
        </button>
        <img
          src={logoIcon}
          alt="logo"
          className="cursor-pointer"
          onClick={() => {
            navigate('/');
          }}
        />
      </div>
      <ul className="flex gap-5 items-center justify-center">
        <li
          className="flex relative"
          id="notification-container"
          onClick={() => {
            setShowNotificationDropDown(!showNotificationDropDown);
          }}
        >
          <button
            type="button"
            className="nav-notification-btn"
            title="Notifications"
          >
            <img
              src={notificationIcon}
              alt="notification"
              className="w-5 h-5 text-gray-charcoal"
            />
            <MenuNotificationBadge />
          </button>
          {showNotificationDropDown && <MenuNotificationList />}
        </li>
        <li
          className="flex relative"
          id="sign-out-container"
          onClick={() => {
            setShowDropDown(!showDropDown);
          }}
        >
          <button type="button" className="flex items-center">
            <div className="nav-avatar-container">
              <span className="text-white text-sm font-lato-semibold">
                {nameInitial}
              </span>
            </div>
            <small className="font-lato-semibold capitalize">
              {first_name}
            </small>
          </button>
          {showDropDown && (
            <ul className="py-2 absolute bg-white top-full mt-2 right-0 shadow-md rounded-md min-w-28">
              <li
                className="nav-dropdown-item"
                onClick={() => {
                  navigate('/profile');
                }}
              >
                <FaRegUserCircle size={18} />
                <span className="ml-1.5">Profile</span>
              </li>
              <li className="nav-dropdown-item" onClick={signOut}>
                <RiLockLine size={18} />
                <span className="ml-1.5">Sign out</span>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
