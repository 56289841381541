import React, { memo } from 'react';
import { cn } from 'utils/utils';

const Label: React.FC<LabelProps> = ({ name, className, label, ...rest }) => {
  return (
    <label
      htmlFor={name}
      className={cn('font-lato-bold mb-2 text-xs', className)}
      {...rest}
    >
      {label}
    </label>
  );
};

export default memo(Label);
