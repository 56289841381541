import React from 'react';

import { useAppSelector } from 'state-management/hooks/stateHooks';
import { RootState } from 'state-management/store';
import { MAX_NOTIFICATION_COUNT } from 'helpers/constants/tickets';
import './MenuNotificationBadge.css';

const MenuNotificationBadge: React.FC = () => {
  const unReadNotificationCount = useAppSelector(
    (state: RootState) =>
      state.menuNotificationList.data.unread_notification_count,
  );
  return (
    <>
      {unReadNotificationCount > 0 && (
        <span className="menu-notification-badge">
          {unReadNotificationCount > MAX_NOTIFICATION_COUNT
            ? `${MAX_NOTIFICATION_COUNT}+`
            : unReadNotificationCount}
        </span>
      )}
    </>
  );
};

export default MenuNotificationBadge;
