import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import React, { memo, useEffect, useState } from 'react';

import { cn } from 'utils/utils';
import {
  addPriority,
  updatePriority,
} from 'actions/SLA/ticketPrioritySLA.actions';
import Label from 'components/elements/Label/Label';
import Input from 'components/elements/Input/Input';
import Button from 'components/elements/Button/Button';
import { MODAL_DELETE } from 'helpers/constants/modalContent';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';

const AddPriority: React.FC<{
  priorities: Priorities | null;
  closeModal: () => void;
  selectedPriority: Priority | null;
  fetchPriorities: (perPage?: number, currentPage?: number) => void;
}> = ({ closeModal, fetchPriorities, selectedPriority, priorities }) => {
  const dispatch = useAppDispatch();
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const methods = useForm<TicketPrioritySLAForm>({
    defaultValues: { name: '', overdue_time: '' },
    resolver: zodResolver(
      z.object({
        name: z
          .string()
          .trim()
          .min(1, { message: 'Priority is required' })
          .max(25, {
            message: 'Priority name should be less than 25 characters',
          }),
        overdue_time: z
          .string()
          .min(1, { message: 'Overdue time is required' })
          .transform((value) => Number(value)),
      }),
    ),
  });

  const submitHandler = (formData: TicketPrioritySLAForm): void => {
    setIsLoading(true);
    if (selectedPriority) {
      dispatch(updatePriority(selectedPriority.id, axiosInstance, formData))
        .then(() => {
          notifySuccess('Priority updated successfully');
          fetchPriorities(
            priorities?.pagination.per_page,
            priorities?.pagination.current_page,
          );
          closeModal();
        })
        .catch((error: ErrorData) => notifyError(error))
        .finally(() => setIsLoading(false));
    } else {
      dispatch(addPriority(axiosInstance, formData))
        .then((response) => {
          notifySuccess(response.message);
          fetchPriorities(priorities?.pagination.per_page, 1);
          methods.reset();
          closeModal();
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (selectedPriority) {
      methods.reset({
        name: selectedPriority?.name,
        overdue_time: selectedPriority?.overdue_time.toString(),
      });
    }
  }, []);

  const {
    formState,
    formState: { errors },
  } = methods;
  const isFormDirty = JSON.stringify(formState.dirtyFields) !== '{}';

  return (
    <div>
      <FormProvider {...methods}>
        <form
          className="w-[80vw] lg:w-[50vw] xl:w-[30vw]"
          onSubmit={methods.handleSubmit((data) => submitHandler(data))}
        >
          <div className="input-group-wrapper mt-2">
            <Label name="name" label="Priority *" className={cn('text-xs')} />
            <Input
              type="text"
              name="name"
              className="user-input"
              placeholder="Enter priority"
              error={errors.name?.message}
            />
          </div>
          <div className="input-group-wrapper mt-4">
            <Label
              name="overdue_time"
              label="Overdue Time *"
              className={cn('text-xs')}
            />
            <Input
              type="number"
              name="overdue_time"
              className="user-input"
              placeholder="Enter overdue time in minutes"
              error={errors.overdue_time?.message}
            />
          </div>
          <div className="flex justify-center gap-10 mt-6">
            <Button
              type="button"
              title="Cancel"
              onClick={closeModal}
              className="cancel-button"
              buttonName={MODAL_DELETE.CANCEL}
            />
            <Button
              title="Save"
              type="submit"
              buttonName="Save"
              isLoading={isLoading}
              className="submit-button"
              shouldDisable={!isFormDirty}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default memo(AddPriority);
