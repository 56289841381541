import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import React, { Dispatch, SetStateAction } from 'react';

const Category: React.FC<{
  category: Group;
  openDeleteModal: (category: Group) => void;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setSelectedCategory: Dispatch<SetStateAction<Group | null>>;
}> = ({ category, setOpenModal, openDeleteModal, setSelectedCategory }) => {
  return (
    <div className="border py-1.5 px-3 mt-2 rounded-md shadow-md flex justify-between items-center mr-2.5">
      <div>
        <h5 className="font-semibold">{category.name}</h5>
      </div>
      <div>
        <button
          type="button"
          title="Edit category"
          className="mr-5 outline-none"
          onClick={() => {
            setOpenModal(true);
            setSelectedCategory(category);
          }}
        >
          <FiEdit size={16} color="navy" />
        </button>
        <button
          type="button"
          title="Delete category"
          className="outline-none"
          onClick={() => openDeleteModal(category)}
        >
          <RiDeleteBinLine size={16} color="maroon" />
        </button>
      </div>
    </div>
  );
};

export default Category;
