import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoKeyOutline } from 'react-icons/io5';
import { useAppSelector } from 'state-management/hooks/stateHooks';
import { RootState } from 'state-management/store';
import { useMainContext } from 'helpers/providers/MainContext';
import './Profile.css';

const Profile: React.FC = () => {
  const currentUser = useAppSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const MainContext = useMainContext();

  useEffect(() => {
    MainContext.setPageHeaderName('Profile');
  }, [MainContext]);

  const getAvatarName = (): string => {
    if (currentUser.last_name) {
      return `${currentUser.first_name[0]}${currentUser.last_name[0]}`;
    }
    return currentUser.first_name.slice(0, 2);
  };

  return (
    <div className="profile-container">
      <div className="profile-card">
        <div className="profile-avatar">
          <h2 className="font-lato-semibold">{getAvatarName()}</h2>
        </div>
        <div className="text-center">
          <h5 className="font-semibold mb-1">{currentUser.first_name}</h5>
          <p>{currentUser.email}</p>
          <small className="mt-1">({currentUser.role})</small>
        </div>
      </div>
      <div className="flex flex-col justify-between h-full mt-7 md:mt-0">
        <h3>
          Welcome, {currentUser.first_name} {currentUser.last_name}
        </h3>
        <p className="mt-3">
          Manage your info and security to make Copper Ticketing System work for
          you. Only you can see your settings.
        </p>
        <p>Copper keeps your data private, safe and secure.</p>
        <button
          className="change-pwd-button"
          title="Change Password"
          onClick={() => {
            navigate('/change_password');
          }}
        >
          <div className="profile-avatar w-20 h-20 mb-2">
            <IoKeyOutline size={28} />
          </div>
          <span>Change password</span>
        </button>
      </div>
    </div>
  );
};

export default Profile;
