import { ReactNode, memo } from 'react';
import { Navigate } from 'react-router-dom';
import { RootState } from 'state-management/store';
import { useAppSelector } from 'state-management/hooks/stateHooks';

const UnAuthGuard = ({ children }: { children: ReactNode }) => {
  const accessToken = useAppSelector(
    (state: RootState) => state.auth.accessToken,
  );
  return accessToken ? <Navigate to={'/'} /> : <>{children}</>;
};

export default memo(UnAuthGuard);
