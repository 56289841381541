import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMainContext } from 'helpers/providers/MainContext';
import { IoArrowBack } from 'react-icons/io5';

const Header: React.FC = () => {
  const MainContext = useMainContext();
  const navigate = useNavigate();
  const { pageHeaderName } = MainContext;

  return (
    <header className="py-4 pl-4 shadow-card-sm bg-white-link-water-100 rounded-t-xl fixed w-full z-10 flex items-center">
      {pageHeaderName !== 'Dashboard' && (
        <button
          type="button"
          title="Back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack size={20} />
        </button>
      )}
      <h3 className="text-xl font-lato-semibold text-gray-big-stone-100 pl-3">
        {pageHeaderName}
      </h3>
    </header>
  );
};

export default Header;
