import { AxiosInstance } from 'axios';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';

export const fetchAssignedTickets = (
  axiosInstance: AxiosInstance,
  assignedTicketParams: TicketParams,
) => {
  return async () => {
    const data = async () => {
      const params: { [key: string]: string | number } = {
        page: assignedTicketParams.page,
        per_page: assignedTicketParams.per_page,
      };
      if (assignedTicketParams.q) params.q = assignedTicketParams.q;
      if (assignedTicketParams.filterFormValues) {
        if (assignedTicketParams.filterFormValues.ticket_id)
          params.ticket_id = assignedTicketParams.filterFormValues.ticket_id;
        if (assignedTicketParams.filterFormValues.subject)
          params.subject = assignedTicketParams.filterFormValues.subject;
        if (assignedTicketParams.filterFormValues.assignee)
          params.assignee = assignedTicketParams.filterFormValues.assignee;
        if (assignedTicketParams.filterFormValues.due_date)
          params.due_date = assignedTicketParams.filterFormValues.due_date;
        if (assignedTicketParams.filterFormValues.status)
          params.status = assignedTicketParams.filterFormValues.status;
        if (assignedTicketParams.filterFormValues.assignee_type)
          params.assignee_type =
            assignedTicketParams.filterFormValues.assignee_type;
      }
      return await axiosInstance.get(API_END_POINTS.fetch_assigned_tickets, {
        params,
      });
    };
    const result = await data();
    return result.data;
  };
};
