import { useEffect, useState } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';

import {
  useAppDispatch,
  useAppSelector,
} from 'state-management/hooks/stateHooks';
import { RootState } from 'state-management/store';
import environment from 'environment/environment';
import { API_END_POINTS } from 'helpers/constants/apiEndPoints';
import {
  setNotifications,
  updateNotifications,
} from 'state-management/menuNotificationList/menuNotificationListSlice';
import { SSE_EVENTS } from 'types/enums/menuNotificationList.enum';
import { setCustomerTickets } from 'state-management/customerTickets/customerTicketsSlice';

const useMenuNotificationList = () => {
  const token = useAppSelector((state: RootState) => state.auth.accessToken);
  const sseEndPoint = environment.baseURL + API_END_POINTS.sse;
  const dispatch = useAppDispatch();
  const [openCustomerTicketsModal, setOpenCustomerTicketsModal] =
    useState<boolean>(false);

  useEffect(() => {
    const es = new EventSourcePolyfill(sseEndPoint, {
      headers: {
        Authorization: token,
      },
    });
    es.onmessage = function (event) {
      const { data } = JSON.parse(JSON.stringify(event));
      const eventData = JSON.parse(data);
      const eventName = eventData.event as string;
      if (eventData.event === SSE_EVENTS.notifications) {
        const notificationData = {
          notifications: eventData.data.notifications,
          unread_notification_count: eventData.data.unread_notification_count,
        };
        dispatch(
          setNotifications({ event: eventName, data: notificationData }),
        );
      } else if (eventData.event === SSE_EVENTS.trigger_tickets) {
        dispatch(setCustomerTickets(eventData));
        setOpenCustomerTicketsModal(true);
      } else {
        dispatch(updateNotifications(eventData.data));
      }
    };
    return () => {
      es.close();
    };
  }, []);

  return { openCustomerTicketsModal, setOpenCustomerTicketsModal };
};

export default useMenuNotificationList;
