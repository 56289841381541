import { ColDef, ValueGetterParams } from 'ag-grid-community';

export const NOTIFICATIONS_LIST_HEADERS: ColDef<NotificationList>[] = [
  {
    width: 150,
    resizable: false,
    headerName: 'Status',
    field: 'ticket_details.status',
  },
  {
    minWidth: 300,
    resizable: false,
    field: 'description',
    headerName: 'Description',
    tooltipField: 'description',
    valueGetter: (args: ValueGetterParams) =>
      args.data.description.length < 40
        ? args.data.description
        : `${args.data.description.slice(0, 40)}...`,
  },

  {
    resizable: false,
    headerName: 'Priority',
    field: 'ticket_details.priority.name',
  },
  {
    width: 280,
    resizable: false,
    headerName: 'Subject',
    field: 'ticket_details.subject',
    tooltipField: 'ticket_details.subject',
    valueGetter: (args: ValueGetterParams) =>
      args.data.ticket_details.subject.length < 30
        ? args.data.ticket_details.subject
        : `${args.data.ticket_details.subject.slice(0, 30)}...`,
  },
  {
    field: 'read',
    resizable: false,
    headerName: 'Notification status',
    valueGetter: (args: ValueGetterParams) =>
      args.data.read ? 'Read' : 'Unread',
  },
];
