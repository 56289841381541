import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import {
  addUser,
  fetchRoles,
  fetchGroups,
  fetchSuperVisors,
} from 'actions/users/users.actions';
import '../Users.css';
import { schema } from './schema';
import {
  USER_FIELDS_LENGTH,
  TICKET_FIELDS_LENGTH,
} from 'helpers/constants/fieldLengths';
import Input from 'components/elements/Input/Input';
import Label from 'components/elements/Label/Label';
import Button from 'components/elements/Button/Button';
import { useMainContext } from 'helpers/providers/MainContext';
import { notifyError, notifySuccess } from 'utils/ToastMessage';
import useAxiosInstance from 'helpers/interceptor/useInterceptor';
import { useAppDispatch } from 'state-management/hooks/stateHooks';
import SelectInput from 'components/elements/SelectInput/SelectInput';
import { cn, handleNameKeyDown, handleSpaceKeyDown } from 'utils/utils';
import { ROLE } from 'types/enums/sidebarEnums';
import { store } from 'state-management/store';

const NewUser: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const MainContext = useMainContext();
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState(false);
  const currentUserDetails = store.getState().auth;
  const addUserFormDefaultValues = {
    email: '',
    last_name: '',
    role_id: null,
    first_name: '',
    group_ids: null,
  };
  const methods = useForm<ADD_USER>({
    defaultValues: addUserFormDefaultValues,
    resolver: zodResolver(schema),
  });
  const [roles, setRoles] = useState<SelectOption[] | null>(null);
  const [groups, setGroups] = useState<SelectOption[] | null>(null);
  const [supervisors, setSupervisors] = useState<SelectOption[] | null>(null);
  const {
    formState: { errors },
    watch,
  } = methods;
  const selectedRole = watch('role_id');

  useEffect(() => {
    MainContext.setPageHeaderName('New User');
    const rolesFetch = () => {
      dispatch(fetchRoles(axiosInstance))
        .then((response) => {
          setRoles(response.data);
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        });
    };
    const groupsFetch = () => {
      dispatch(fetchGroups(axiosInstance))
        .then((response) => {
          setGroups(response.data);
        })
        .catch((error: ErrorData) => {
          notifyError(error);
        });
    };
    const getSupervisors = () => {
      dispatch(fetchSuperVisors(axiosInstance))
        .then((response) => setSupervisors(response))
        .catch((error: ErrorData) => notifyError(error));
    };
    rolesFetch();
    groupsFetch();
    getSupervisors();
  }, [axiosInstance, dispatch]);

  const createUser = (user: ADD_USER) => {
    setIsLoading(true);
    const getGroupIds = user.group_ids
      ? user.group_ids.map((user) => user.id)
      : [];
    const payload: ADD_USER_PAYLOAD = {
      ...user,
      role_id: user.role_id?.id as string,
      group_ids: getGroupIds as number[],
      supervisor_id: user.supervisor_id?.id as string,
    };
    if (getGroupIds.length === 0) {
      delete payload['group_ids'];
    }

    if (selectedRole?.name !== ROLE.AGENT) {
      delete payload['supervisor_id'];
    }
    dispatch(addUser(axiosInstance, payload))
      .then((response) => {
        notifySuccess(response.data.message);
        methods.reset();
        navigate('/users');
      })
      .catch((error: ErrorData) => {
        methods.reset();
        setIsLoading(false);
        notifyError(error);
      });
  };

  const handleReset = () => {
    methods.reset();
  };

  const patchValuesForSupervisor = () => {
    if (currentUserDetails.role === ROLE.SUPERVISOR && roles && supervisors) {
      const supervisor = supervisors?.find(
        (supervisor) => supervisor.id === currentUserDetails.id,
      ) as SelectOption;
      methods.reset({
        role_id: roles[0],
        supervisor_id: supervisor,
      });
    }
  };

  useEffect(() => {
    if (
      selectedRole?.name === ROLE.AGENT &&
      currentUserDetails.role !== ROLE.SUPERVISOR
    ) {
      methods.setValue('supervisor_id', null);
    } else {
      methods.unregister('supervisor_id');
    }
  }, [selectedRole]);

  useEffect(() => {
    if (roles && supervisors) {
      patchValuesForSupervisor();
    }
  }, [roles, supervisors]);

  return (
    <div className="user-form">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit((data: ADD_USER) => createUser(data))}
        >
          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label
                name="first_name"
                label="First Name *"
                className={cn('text-xs')}
              />
              <Input
                type="text"
                name="first_name"
                placeholder="Enter first name"
                className="user-input"
                error={errors.first_name?.message}
                onKeyDown={handleNameKeyDown}
                maxLength={USER_FIELDS_LENGTH.FIRST_NAME}
              />
            </div>
            <div className="input-group-wrapper mt-2">
              <Label
                name="last_name"
                label="Last Name *"
                className={cn('text-xs')}
              />
              <Input
                type="text"
                name="last_name"
                placeholder="Enter last name"
                className="user-input"
                error={errors.last_name?.message}
                onKeyDown={handleNameKeyDown}
                maxLength={USER_FIELDS_LENGTH.LAST_NAME}
              />
            </div>
          </div>

          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label name="email" label="Email *" className={cn('text-xs')} />
              <Input
                type="email"
                name="email"
                placeholder="Enter email"
                className="user-input"
                error={errors.email?.message}
                onKeyDown={handleSpaceKeyDown}
                maxLength={TICKET_FIELDS_LENGTH.EMAIL}
              />
            </div>
            <div className="input-group-wrapper mt-2">
              <Label
                name="group_ids"
                label="Groups"
                className={cn('text-xs')}
              />
              <SelectInput
                name="group_ids"
                options={groups ?? []}
                isMulti={true}
              />
            </div>
          </div>

          <div className="input-wrapper">
            <div className="input-group-wrapper mt-2">
              <Label name="role_id" label="Role *" className={cn('text-xs')} />
              <SelectInput
                name="role_id"
                options={roles ?? []}
                isDisabled={currentUserDetails.role === ROLE.SUPERVISOR}
              />
            </div>
            {selectedRole?.name === ROLE.AGENT && (
              <div className="input-group-wrapper mt-2">
                <Label
                  name="supervisor_id"
                  label="Supervisor *"
                  className={cn('text-xs')}
                />
                <SelectInput
                  name="supervisor_id"
                  options={supervisors ?? []}
                  isDisabled={currentUserDetails.role === ROLE.SUPERVISOR}
                />
              </div>
            )}
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-center gap-4 sm:justify-end mt-11">
              <Button
                type="reset"
                buttonName="Cancel"
                className="cancel-button"
                onClick={handleReset}
                title="Cancel"
              />
              <Button
                type="submit"
                buttonName="Save"
                className="submit-button"
                isLoading={isLoading}
                title="Submit"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewUser;
